import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';

import { COLOR_BORDER_PRIMARY, COLOR_WHITE } from '../../constants/colors';
import { Paths } from '../../constants/routes';

import HomePageTabs from './components/HomePageTabs';
import HomePageCollapsedMenu from './components/HomePageCollapsedMenu';
import ConversationPage from '../ConversationPage/ConversationPage';
import { useStore } from '../../hooks/useStore';
import ConversationFiles from '../ConversationPage/ConversationFiles';
import { Conversation } from '../../models/Conversation';
import { uploadDriveFile } from '../../utils/uploadFile';
import { LocalStorageKeys } from '../../constants/localStorageKeys';
import { useToasts } from 'react-toast-notifications';
import CancelUploadModal from './Tabs/HistoryTab/components/CancelUploadModal';
import CustomModal from '../../components/modal/CustomModal';

const ScreenContainer = styled(Box)`
  width: 100%;
  height: calc(100vh - 56px);

  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px;
`;

const LeftContainer = styled(Box)`
  height: 100%;

  background-color: ${COLOR_WHITE};
  border-radius: 8px;
  border: 1px solid ${COLOR_BORDER_PRIMARY};
`;

export const HomePage = () => {
  const {
    localizationStore: { i18next: i18n },
    conversationStore: {
      showFilePreview,
      conversations,
      setIsUploadInProgress,
      cancelFileUpload,
      setIsLoading,
      setUploadingConversationId,
      setUploadingFileId,
      setUploadProgress,
    },
    uiStore: { setIsCancelUploadModalOpen, isCancelUploadModalOpen },
    appState: { googleDriveApi, fileApi },
  } = useStore();
  const [areTabsExpanded, setAreTabsExpanded] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [allowRedirect, setAllowRedirect] = useState(false);
  const [googleDriveAccessToken, setGoogleDriveAccessToken] = useState('');
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const changeTab = (newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (showFilePreview) {
      setAreTabsExpanded(!showFilePreview);
    }
  }, [showFilePreview]);

  useEffect(() => {
    (async () => {
      if (window.location.search && window.location.search.slice(1).split('&')[1].includes('code=')) {
        const resp = await googleDriveApi.getOauth2WithToken(
          window.location.search.slice(1).split('&')[1].split('code=')[1],
          `${location.origin}/chat`
        );

        if (resp) {
          setGoogleDriveAccessToken(resp.credentials.access_token);
          setAllowRedirect(true);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (allowRedirect && conversations.length && googleDriveAccessToken) {
        setAllowRedirect(false);
        setIsLoading(true);
        const lastConversation = conversations[0];
        await generateDataAndUploadFile({ lastConversation });
        setIsLoading(false);
        return navigate(`${Paths.CHAT}/${lastConversation.id}`);
      }
    })();
  }, [allowRedirect, conversations, googleDriveAccessToken]);

  useEffect(() => {
    const gDriveToken = localStorage.getItem(LocalStorageKeys.googleDriveAccessToken);

    if (googleDriveAccessToken && googleDriveAccessToken !== gDriveToken) {
      localStorage.setItem(LocalStorageKeys.googleDriveAccessToken, googleDriveAccessToken);
    }
  }, [googleDriveAccessToken]);

  const generateDataAndUploadFile = async ({ lastConversation }: { lastConversation: Conversation }) => {
    const lastFile = lastConversation?.files && lastConversation?.files[0];
    const lastFileUrl = lastFile?.name.slice(lastFile.name.indexOf('-https') + 1);

    const regex = /[-\w]{25,}/;
    const fileData = lastFileUrl?.match(regex);
    const fileId = fileData?.length ? fileData[0] : '';

    if (lastFile && fileId) {
      setIsUploadInProgress(true);
      setUploadingConversationId(lastConversation.id);
      setUploadingFileId(lastFile.id);
      setUploadProgress(0);
      const objToUpload = {
        documentIds: [{ id: fileId }],
        accessToken: googleDriveAccessToken,
        fileKeys: [lastFile.key],
      };
      await uploadDriveFile({
        googleDriveObject: objToUpload,
        conversationId: lastConversation.id,
        createdFiles: [lastFile.id],
        googleDriveApi,
        fileApi,
        setIsUploadInProgress,
        cancelFileUpload,
        addToast,
        i18n,
      });
    }
  };

  return (
    <ScreenContainer>
      <LeftContainer
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: !areTabsExpanded ? '84px' : '40%',
          transition: theme =>
            theme.transitions.create(['width'], {
              easing: areTabsExpanded ? theme.transitions.easing.sharp : theme.transitions.easing.easeOut,
              duration: areTabsExpanded
                ? theme.transitions.duration.leavingScreen
                : theme.transitions.duration.enteringScreen,
            }),
        }}
      >
        {areTabsExpanded ? (
          <HomePageTabs
            closeTabs={() => {
              setAreTabsExpanded(false);
            }}
            activeTab={activeTab}
            changeTab={changeTab}
          />
        ) : (
          <HomePageCollapsedMenu
            openTabs={() => {
              setAreTabsExpanded(true);
            }}
            activeTab={activeTab}
            changeTab={changeTab}
          />
        )}
      </LeftContainer>
      <ConversationPage />
      <Box
        sx={{
          maxWidth: 'calc(100vw * 0.4)',
          width: showFilePreview ? '100%' : 0,
          transition: theme =>
            theme.transitions.create(['width'], {
              easing: showFilePreview ? theme.transitions.easing.sharp : theme.transitions.easing.easeOut,
              duration: showFilePreview
                ? theme.transitions.duration.leavingScreen
                : theme.transitions.duration.enteringScreen,
            }),
        }}
      >
        <ConversationFiles />
      </Box>
      <CustomModal
        isOpen={isCancelUploadModalOpen}
        onClose={() => setIsCancelUploadModalOpen(false)}
        sx={{ width: '450px' }}
      >
        <CancelUploadModal closeModal={() => setIsCancelUploadModalOpen(false)} />
      </CustomModal>
    </ScreenContainer>
  );
};

export default observer(HomePage);
