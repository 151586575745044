import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { COLOR_WHITE } from '../../constants/colors';
import { SIZES_SMALL } from '../../constants/sizes';

const FullHeightScreenWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 56px - 48px);
  padding: 40px;
  background: ${COLOR_WHITE};
  position: relative;
  overflow: auto;

  @media screen and (max-width: ${SIZES_SMALL}) {
    padding: 40px 12px;
    overflow-x: hidden;
  }
`;

export default FullHeightScreenWrapper;
