import { sortArrayByInput } from '../pages/PromptsGalleryPage/components/utils';

export const extractVariablesFromPromptContent = (inputString: string) => {
  const mentionRegex = /\[([^\]]+)\]/g;
  const dropdownRegex = /\{([^\}]+)\}/g;
  const mentionsArray = inputString.match(mentionRegex) || [];
  const dropdownsArray = inputString.match(dropdownRegex);
  // @ts-ignore
  const matches = dropdownsArray?.length ? mentionsArray.concat(dropdownsArray) : mentionsArray;

  if (!matches) {
    return {};
  }
  const sortedMatches = sortArrayByInput(matches, inputString);

  const result: any = {};

  sortedMatches?.forEach((match: string) => {
    const variable = match?.replace(/['`]/g, '"').substring(1, match?.length - 1);
    result[variable] = '';
  });

  return result;
};
