import React, { useState } from 'react';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';

import { useStore } from '../../hooks/useStore';
import Button from '../buttons/Button';
import CreateKnowledgeBaseModal from './CreateKnowledgeBaseModal';

const CreateKnowledgeBase = () => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();
  const [showModal, setShowModal] = useState(false);

  return (
    <Box>
      <Button
        onClick={() => setShowModal(true)}
        startIcon={<AddIcon />}
        sx={{
          padding: '9px 16px !important',
          height: '40px !important',
          width: 'fit-content',
          fontWeight: 400,
        }}
      >
        {i18n.t('knowledgeBase.button.createKnowledgeBase')}
      </Button>

      {showModal && <CreateKnowledgeBaseModal isOpen={showModal} onClose={() => setShowModal(false)} />}
    </Box>
  );
};

export default CreateKnowledgeBase;
