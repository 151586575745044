import { useEffect } from 'react';
import { Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import Stack from '@mui/material/Stack';

import { COLOR_CHART_WARNING, COLOR_SUCCESS, GRAY_COLORS } from '../../../../../constants/colors';

import CodeAnalysisIcon from '../../../../../assets/icons/data-analysis.svg';

import Flex from '../../../../../components/utils/flex/Flex';
import { PromptItem } from '../../ConversationEmptyState';
import { useStore } from '../../../../../hooks/useStore';
import { getTextOverflowEllipsisStyles } from '../../../../../components/typography/utils';
import { File } from '../../../../../models/File';
import { getFileExtensionFromName } from '../../../../../utils/getFileExtensionFromName';
import { SupportedExtensions } from '../../../../../models/Conversation';
import { SupportedAIProvidersEnum } from '../../../../../constants/supportedAIProvidersEnum';

const DEFAULT_DOCUMENT_PROMPTS = ['summaryKeyFindings', 'trendsAndPatterns', 'relevantQuestions'];

interface FileDoneProcessingContentProps {
  file?: File;
}

const FileDoneProcessingContent = ({ file }: FileDoneProcessingContentProps) => {
  const {
    conversationStore: {
      setMessageInput,
      updateConversation,
      currentConversation,
      addDataAnalysisBotChat,
      setDataAnalysisEnablingPending,
      dataAnalysisEnablingPending,
    },
    localizationStore: { i18next: i18n },
  } = useStore();

  const isDataAnalysisEnabled = process.env.REACT_APP_USE_DATA_ANALYSIS === 'true';

  const fileExtension = getFileExtensionFromName(file?.name || '');
  const isSpreadsheet = ['xls', 'xlsx', 'csv'].includes(fileExtension);

  const noOtherFilesInConversation =
    currentConversation.files?.filter(conversationFile => conversationFile.id !== file?.id).length === 0;

  useEffect(() => {
    // set data analysis to pending
    if (!dataAnalysisEnablingPending && isSpreadsheet && noOtherFilesInConversation) {
      setDataAnalysisEnablingPending(true);
    }
  }, [file, currentConversation.files]);

  const handleChooseDataAnalysis = async (enable: boolean) => {
    const botChatMessage = enable
      ? i18n.t('conversation.dataAnalysis.enabled')
      : i18n.t('conversation.dataAnalysis.notEnabled');

    if (enable) {
      await updateConversation({
        extension: SupportedExtensions.DataAnalysis,
      });
    }

    await addDataAnalysisBotChat(botChatMessage);
    setDataAnalysisEnablingPending(false);
  };

  return (
    <Stack>
      <Flex sx={{ width: '100%', gap: '8px', marginTop: '24px', alignItems: 'center' }}>
        {isSpreadsheet ? (
          <Stack>
            <Flex sx={{ gap: '8px', alignItems: 'center' }}>
              <WarningIcon sx={{ fill: COLOR_CHART_WARNING }} />
              <Typography variant={'body2'}>
                {isDataAnalysisEnabled && noOtherFilesInConversation
                  ? i18n.t('conversation.spreadsheetDataAnalysis.doneAnalyzing')
                  : i18n.t('conversation.spreadsheetFile.doneAnalyzing')}
              </Typography>
            </Flex>
            {isDataAnalysisEnabled &&
              noOtherFilesInConversation &&
              currentConversation.providerName === SupportedAIProvidersEnum.open_ai && (
                <>
                  <PromptItem
                    onClick={() => handleChooseDataAnalysis(true)}
                    key={`enable-data-analysis-yes`}
                    sx={{
                      marginTop: '16px',
                      width: '20%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '4px',
                    }}
                  >
                    <img src={CodeAnalysisIcon} alt={'CodeAnalysisIcon'} style={{ width: '20px' }} />
                    <Typography
                      variant={'body2'}
                      sx={{ color: GRAY_COLORS.GRAY_700, ...getTextOverflowEllipsisStyles(2) }}
                    >
                      {i18n.t('common.yes')}
                    </Typography>
                  </PromptItem>
                  <PromptItem
                    onClick={() => handleChooseDataAnalysis(false)}
                    key={`enable-data-analysis-no`}
                    sx={{ marginTop: '16px', width: '20%' }}
                  >
                    <Typography
                      variant={'body2'}
                      sx={{ color: GRAY_COLORS.GRAY_700, ...getTextOverflowEllipsisStyles(2) }}
                    >
                      {i18n.t('common.no')}
                    </Typography>
                  </PromptItem>
                </>
              )}
          </Stack>
        ) : (
          <>
            <CheckCircleIcon sx={{ fill: COLOR_SUCCESS }} />
            <Typography variant={'body2'}>{i18n.t('conversation.file.doneAnalyzing')}</Typography>
          </>
        )}
      </Flex>
      {!isSpreadsheet &&
        DEFAULT_DOCUMENT_PROMPTS.map((prompt, index) => (
          <PromptItem
            onClick={() => setMessageInput(i18n.t(`conversation.filePrompt.${prompt}`), true)}
            key={`empty-state-prompt-${index}`}
            sx={{ marginTop: '16px' }}
          >
            <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_700, ...getTextOverflowEllipsisStyles(2) }}>
              {`"${i18n.t(`conversation.filePrompt.${prompt}`)}"`}
            </Typography>
          </PromptItem>
        ))}
    </Stack>
  );
};

export default FileDoneProcessingContent;
