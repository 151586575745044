import React, { SyntheticEvent, useState } from 'react';
import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddIcon from '@mui/icons-material/Add';

import { COLOR_PRIMARY, GRAY_COLORS } from '../../../constants/colors';

import FlexRowSpaceBetween from '../../../components/utils/flex/FlexRowSpaceBetween';
import { Headline1 } from '../../../components/typography/Headlines';
import Button from '../../../components/buttons/Button';
import { useStore } from '../../../hooks/useStore';
import { PromptsGalleryContentContainer } from '../styles';
import TabPanel from '../../../components/tabs/TabPanel';
import PromptsTableTab from '../tabs/PromptsTableTab';

interface PromptsGalleryTableProps {
  containerStyles: SxProps;
  setIsAddPromptModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeletePromptModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isTableDisplayedInDrawerMode?: boolean;
}

const PromptsGalleryTable = ({
  containerStyles,
  setIsAddPromptModalOpen,
  setIsEditMode,
  setIsDeletePromptModalOpen,
  isTableDisplayedInDrawerMode,
}: PromptsGalleryTableProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const {
    localizationStore: { i18next: i18n },
    promptsStore: { userPrompts, userTeamsPrompts },
  } = useStore();
  const changeTab = (event: SyntheticEvent<Element, Event>, newValue: number) => {
    setActiveTab(newValue);
  };

  const tabs = [
    {
      title: 'My Prompts',
      component: (
        <PromptsTableTab
          isDisplayedInDrawerMode={isTableDisplayedInDrawerMode}
          setIsEditMode={setIsEditMode}
          setIsDeletePromptModalOpen={setIsDeletePromptModalOpen}
          setIsAddPromptModalOpen={setIsAddPromptModalOpen}
          prompts={userPrompts}
        />
      ),
    },
    {
      title: 'All Prompts',
      component: (
        <PromptsTableTab
          isDisplayedInDrawerMode={isTableDisplayedInDrawerMode}
          setIsEditMode={setIsEditMode}
          setIsDeletePromptModalOpen={setIsDeletePromptModalOpen}
          setIsAddPromptModalOpen={setIsAddPromptModalOpen}
          prompts={userTeamsPrompts}
        />
      ),
    },
  ];

  return (
    <PromptsGalleryContentContainer sx={containerStyles}>
      <FlexRowSpaceBetween sx={{ marginBottom: '28px' }}>
        <Headline1>{i18n.t('promptGallery.header')}</Headline1>
        <Box>
          <Button
            variant={'outlined'}
            startIcon={<AddIcon />}
            sx={{
              marginRight: '8px',
              width: 'fit-content',
              borderColor: `${GRAY_COLORS.GRAY_2} !important`,
              color: `${COLOR_PRIMARY} !important`,
            }}
            onClick={() => {
              setIsEditMode(false);
              setIsAddPromptModalOpen(true);
            }}
          >
            {i18n.t('promptGallery.button.addNewPrompt')}
          </Button>
        </Box>
      </FlexRowSpaceBetween>

      <Tabs value={activeTab} onChange={changeTab}>
        {tabs.map((tab, index: number) => (
          <Tab
            key={`admin-portal-tab-${index}`}
            label={tab.title}
            id={`admin-portal-tab-${index}`}
            aria-controls={`admin-portal-tab-panel-${index}`}
          />
        ))}
      </Tabs>

      {tabs.map((tab, index: number) => (
        <TabPanel
          key={`tab_${index}`}
          value={activeTab}
          index={index}
          sx={{
            paddingTop: '20px',
            height: isTableDisplayedInDrawerMode ? 'calc(100% - 80px)' : 'calc(100% - 98px)',
            overflow: 'hidden',
          }}
        >
          {tab.component}
        </TabPanel>
      ))}
    </PromptsGalleryContentContainer>
  );
};

export default observer(PromptsGalleryTable);
