import { i18n } from 'i18next';

import {
  COLOR_MANDATORY,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_TEXT_PRIMARY,
  COLOR_UTILS_PRIMARY,
  COLOR_WHITE,
  GRAY_COLORS,
  TOAST_COLORS,
} from '../constants/colors';

import Error from '../assets/icons/error-white.svg';
import Warning from '../assets/icons/toasts/warning.svg';
import Change from '../assets/icons/change-white.svg';

import { Chat } from '../models/Chat';

export const getBackgroundColorForChat = (chat: Chat) => {
  const { isUserChat, wasBlocked, wasWarned, wasAnonymized, files } = chat;

  if (isUserChat) {
    if (files?.length && files[0]?.id) {
      return COLOR_SECONDARY;
    }

    if (wasBlocked) {
      return COLOR_UTILS_PRIMARY;
    }

    return COLOR_PRIMARY;
  }

  if (wasBlocked) {
    return COLOR_MANDATORY;
  }

  if (wasWarned || wasAnonymized) {
    return TOAST_COLORS.WARNING;
  }

  return GRAY_COLORS.GRAY_100;
};

export const getTextColorForChat = (chat: Chat) => {
  const { isUserChat, wasWarned, wasBlocked, wasAnonymized } = chat;

  if (!isUserChat && !wasBlocked && !wasWarned && !wasAnonymized) {
    return COLOR_TEXT_PRIMARY;
  }

  return COLOR_WHITE;
};

const getEntitiesString = (entitiesArray: (string | undefined)[], i18n: i18n) =>
  entitiesArray.length > 0
    ? Array.from(new Set(entitiesArray))
        .map(entity => i18n.t(`compliance.${entity}`))
        .join(', ')
    : '';

export const getIconAndTextForBotChatInfo = (
  chat: Chat,
  i18n: i18n
): { iconSrc: string; text: string; entitiesString: string } => {
  const { wasWarned, blockedTextIntervals, anonymizedTextIntervals, warnedTextIntervals, wasBlocked } = chat;

  if (wasBlocked) {
    const entitiesArray = Array.isArray(blockedTextIntervals)
      ? // @ts-ignore
        blockedTextIntervals.map(entity => `${entity?.Category || 'PII'}.${entity.Type}`)
      : [];

    return {
      iconSrc: Error,
      text: 'conversation.botInfoMessage.blocked',
      entitiesString: getEntitiesString(entitiesArray, i18n) || '',
    };
  }

  if (wasWarned) {
    const entitiesArray = Array.isArray(warnedTextIntervals)
      ? // @ts-ignore
        warnedTextIntervals.map(entity => `${entity?.Category || 'PII'}.${entity.Type}`)
      : [];

    return {
      iconSrc: Warning,
      text: 'conversation.botInfoMessage.warned',
      entitiesString: getEntitiesString(entitiesArray, i18n) || '',
    };
  }

  const entitiesArray = Array.isArray(anonymizedTextIntervals)
    ? // @ts-ignore
      anonymizedTextIntervals.map(entity => `${entity?.Category || 'PII'}.${entity.Type}`)
    : [];
  return {
    iconSrc: Change,
    text: 'conversation.botInfoMessage.anonymized',
    entitiesString: getEntitiesString(entitiesArray, i18n) || '',
  };
};
