import React from 'react';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';

import { useStore } from '../../hooks/useStore';
import GeneralModal from '../modal/GeneralModal';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}
const DeleteModal = ({ isOpen, onClose, onSave }: ModalProps) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      onSave={() => {
        onSave();
        onClose();
      }}
      title={i18n.t('knowledgeBase.delete.title')}
      type={'delete'}
    >
      <Typography variant={'body2'}>{i18n.t('knowledgeBase.delete.message')}</Typography>
    </GeneralModal>
  );
};

export default observer(DeleteModal);
