export interface ComplianceRule {
  id: string;
  name: string;
  description: string;
  code: string;
  createdAt?: Date;
  isActive: boolean;
  restrictionLevel?: string | 'blocked' | 'warning' | 'anonymize';
  anonymizeText?: string;
}
export interface ComplianceModel {
  id: string;
  organizationId: string;
  name: string;
  description: string;
  isDefault: boolean;
  createdAt: Date;
  rules: Array<ComplianceRule>;
}

export interface UpdateComplianceRuleDto {
  restrictionLevel: string | 'blocked' | 'warning' | 'anonymize' | undefined;
  id: string;
  isActive: boolean;
  anonymizeText?: string;
}

export const INITIAL_COMPLIANCE: ComplianceModel = {
  createdAt: new Date(),
  id: '',
  organizationId: '',
  description: '',
  name: '',
  isDefault: false,
  rules: [],
};
