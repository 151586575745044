import { IconButton, IconButtonProps } from '@mui/material';
import { COLOR_PRIMARY, GRAY_COLORS } from '../../constants/colors';

interface PrimaryIconButtonProps extends IconButtonProps {}

const PrimaryIconButton = (props: PrimaryIconButtonProps) => {
  const { children } = props;
  return (
    <IconButton
      sx={{
        backgroundColor: COLOR_PRIMARY,
        borderRadius: '6px',
        padding: '8px 16px 8px 16px',

        '&:hover': {
          backgroundColor: COLOR_PRIMARY,
          opacity: 0.8,
        },

        '&:disabled': {
          backgroundColor: GRAY_COLORS.GRAY_300,
        },
      }}
      {...props}
    >
      {children}
    </IconButton>
  );
};

export default PrimaryIconButton;
