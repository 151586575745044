import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import CubeIcon from '../../../../assets/icons/openAiDetails/cube-white.svg';
import CheckCircle from '../../../../assets/icons/check_circle.svg';
import { COLOR_PRIMARY, COLOR_WHITE, GRAY_COLORS } from '../../../../constants/colors';

import { ConversationSettings } from '../../../../models/Conversation';
import { useStore } from '../../../../hooks/useStore';
import { Headline5 } from '../../../typography/Headlines';
import { SettingsItem } from '../../../cards/newConversationCard/styledComponents';
import Flex from '../../../utils/flex/Flex';
import Divider from '../../../Divider/Divider';
import { Chat, EMPTY_CHAT } from '../../../../models/Chat';
import { BeatLoader } from 'react-spinners';
import ChatMessage from '../../../../pages/ConversationPage/components/ConversationChat/Components/ChatMessage';
import { getBackgroundColorForCompletionChat, getTextColorForChat } from './utils';

interface CompareAnswerFromModelCardProps {
  completionSettings: ConversationSettings;
  isSelected: boolean;
  onClick: (chats: Chat[]) => void;
}

const CompareAnswerFromModelCard = ({ completionSettings, isSelected, onClick }: CompareAnswerFromModelCardProps) => {
  const [loading, setLoading] = useState(false);

  const {
    conversationStore: { completionMessage, createCompletion },
    userStore: { userData },
    uiStore: { toggleOpenAiCompareAnswersModalOpen },
  } = useStore();

  const [completionResult, setCompletionResult] = useState<Chat[]>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const chats = await createCompletion({
        message: completionMessage || '',
        settings: {
          temperature: Number(completionSettings.accuracy),
          maxTokens: completionSettings.length,
          providerName: completionSettings.providerName,
          modelVersion: completionSettings.model,
        },
      });

      setCompletionResult(
        chats.map((chat: Partial<Chat>) => {
          return {
            ...EMPTY_CHAT,
            ...chat,
          };
        })
      );

      setLoading(false);
    })();
  }, []);

  return (
    <Container
      isSelected={isSelected}
      onClick={() => {
        onClick(completionResult || []);
      }}
      sx={
        loading
          ? {
              pointerEvents: 'none',
            }
          : {}
      }
    >
      <Header>
        <Flex>
          <Headline5>{`${completionSettings.providerName} - ${completionSettings.model}`}</Headline5>
          <SettingsItem
            sx={{ borderRadius: '4px', marginLeft: '16px', backgroundColor: GRAY_COLORS.GRAY_500, color: COLOR_WHITE }}
          >
            <img src={CubeIcon} alt={'cube-icon'} />
            <Typography variant={'body2'} sx={{ fontSize: '12px', color: COLOR_WHITE }}>
              {completionSettings.model}
            </Typography>
          </SettingsItem>
        </Flex>
        {isSelected && <img src={CheckCircle} alt={'check-circle-icon'} />}
      </Header>
      <Divider sx={{ backgroundColor: GRAY_COLORS.GRAY_2, opacity: 0.5 }} />
      <ConversationContainer>
        {loading ? (
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <BeatLoader />
          </Box>
        ) : (
          completionResult &&
          completionResult.map(completionChat => {
            return (
              <ChatMessage
                chat={completionChat}
                chatUser={userData}
                messageContentStyles={{ color: getTextColorForChat(completionChat) }}
                messageContainerStyles={{
                  borderRadius: '16px',
                  backgroundColor: getBackgroundColorForCompletionChat(completionChat),
                }}
                handleEditPromptExtraAction={toggleOpenAiCompareAnswersModalOpen}
                hideActions
              />
            );
          })
        )}
      </ConversationContainer>
    </Container>
  );
};

const ConversationContainer = styled(Box)`
  padding: 10px;
  height: 350px;
  overflow: scroll;
`;

const Container = styled(Box)<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 6px;
  cursor: pointer;
  border: ${props => (props.isSelected ? `2px solid ${COLOR_PRIMARY}` : `1px solid ${GRAY_COLORS.GRAY_2}`)};
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  background: #eff1f7;
  min-height: 50px;
  border-radius: 6px 6px 0 0;
  padding: 12px 16px 12px 16px;
`;

export default CompareAnswerFromModelCard;
