import { Box, Typography } from '@mui/material';
import { useToasts } from 'react-toast-notifications';

import { COLOR_BORDER_PRIMARY, COLOR_WHITE } from '../../../constants/colors';

import { StepInterfaceProps, SubmitStepInterface } from '../../../models/Steps/StepInterfaces';
import { renderField } from '../utils/renderFields';
import StepperButtonGroup from '../components/StepperButtonGroup';
import { useStore } from '../../../hooks/useStore';
import { JsonRenderer } from '../components/JsonRenderer';
import { CreateWorkflowRunDto } from '../../../models/Workflows';
import { useNavigate } from 'react-router';
import { Paths } from '../../../constants/routes';
import { WorkflowRunStatuses } from '../../../constants/workflows';

interface SubmitStepProps extends StepInterfaceProps {
  step: SubmitStepInterface;
}

const SubmitStep = ({ step, currentStepIndex, setCurrentStepIndex }: SubmitStepProps) => {
  const {
    localizationStore: { i18next: i18n },
    createWorkflowRunStore: { workflowRunData, createWorkflowRun },
    workFlowStore: { selectedWorkflow, getWorkflowRunsByUserId },
    userStore: { userData },
  } = useStore();

  const navigate = useNavigate();
  const { addToast } = useToasts();

  const handleSubmit = () => {
    const createWorkflowRunDto: CreateWorkflowRunDto = {
      workflowId: selectedWorkflow.id,
      userId: userData.id,
      status: WorkflowRunStatuses.IN_PROGRESS,
      variables: {
        api: step.stepConfiguration.api,
        ...workflowRunData,
        ...step.stepConfiguration.requestBodyParams,
      },
    };

    createWorkflowRun(createWorkflowRunDto);
    getWorkflowRunsByUserId(userData.id);
    addToast(i18n.t('workFlows.created.successfully'), { appearance: 'success' }); // Showing toast message when workflow run is created
    navigate(Paths.WORKFLOWS);
  };

  return (
    <Box sx={{ width: '100%', marginBottom: '1rem' }}>
      <Box
        sx={{
          border: `2px solid ${COLOR_BORDER_PRIMARY}`,
          borderRadius: '8px',
          marginBottom: '1rem',
          padding: '1.5rem',
          background: COLOR_WHITE,
        }}
      >
        {step?.stepConfiguration?.jsonRenderer && (
          <JsonRenderer json={workflowRunData} config={step.stepConfiguration.jsonRenderer.renderFields} />
        )}

        <Typography variant="h5" sx={{ fontWeight: 600, margin: '0.5rem' }}>
          {step?.stepConfiguration?.displayText}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 500, margin: '0.5rem' }}>
          {step?.stepConfiguration?.stepInfo}
        </Typography>
        {step.stepConfiguration?.fields?.map(renderField)}
        <StepperButtonGroup
          currentStep={currentStepIndex}
          setCurrentStep={setCurrentStepIndex}
          onSubmit={handleSubmit}
        />
      </Box>
    </Box>
  );
};

export default SubmitStep;
