import { useEffect } from 'react';
import { Avatar } from '@mui/material';

import { useStore } from '../../hooks/useStore';
import SupportedAiProviderLogo from '../SupportedProviderLogo/SupportedAiProviderLogo';
import { OrganizationModel } from '../../models/OrganizationModel';

interface ModelLogoPictureProps {
  model: OrganizationModel;
  size?: number;
}

const ModelLogoPicture = ({ model, size }: ModelLogoPictureProps) => {
  const sizeStyles = { width: `${size || 32}px`, height: `${size || 32}px` };

  const {
    modelStore: { updateOrganizationModel },
    appState: { s3DocumentsApi },
  } = useStore();

  useEffect(() => {
    (async () => {
      if (
        model?.id &&
        model?.logoPictureFileKey &&
        model?.logoPictureUrlExpiration &&
        new Date(model?.logoPictureUrlExpiration).getTime() < new Date().getTime()
      ) {
        const readUrl = await s3DocumentsApi.generateAssetsReadUrl(model?.logoPictureFileKey);

        await updateOrganizationModel({
          ...model,
          logoPicture: readUrl.signedUrl,
          logoPictureUrlExpiration: readUrl.expirationDate,
        });
      }
    })();
  }, [model]);

  return model.logoPicture ? (
    <Avatar src={model.logoPicture} sx={[sizeStyles, { marginRight: '8px' }]} />
  ) : (
    <SupportedAiProviderLogo provider={model.providerName} size={size || 30} style={{ marginRight: '8px' }} />
  );
};

export default ModelLogoPicture;
