import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { useStore } from '../../../hooks/useStore';

export interface DynamicJsonObject {
  [key: string]: string[];
}
const FieldsExtractionResultPage = () => {
  const [value, setValue] = useState('1');
  const [workflowResult, setWorkflowResult] = useState<DynamicJsonObject>({});

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const {
    localizationStore: { i18next: i18n },
    workFlowStore: { selectedWorkflowRun },
  } = useStore();

  useEffect(() => {
    setWorkflowResult(selectedWorkflowRun.result);
  }, [selectedWorkflowRun.id]);

  return (
    <Box sx={{ width: '100%', typography: 'body1', overflowY: 'auto' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '12px' }}>
          <TabList onChange={handleChange} aria-label="evaluation result tabs">
            {selectedWorkflowRun?.variables?.files
              ?.map((file: any) => file.name)
              ?.map((file: any, index: number) => (
                <Tab label={file} value={`${index + 1}`} />
              ))}
          </TabList>
        </Box>
        {selectedWorkflowRun?.variables?.files
          ?.map((file: any) => file.name)
          ?.map((row: any, index: number) => (
            <TabPanel value={`${index + 1}`}>
              {/* addede resultElement id to get result  */}
              <TableContainer id="resultElement" component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Information Type</TableCell>
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(workflowResult).map((key: string, innnerKey) => (
                      <TableRow key={innnerKey}>
                        <TableCell>{key}</TableCell>
                        <TableCell>{workflowResult[key][index]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          ))}
      </TabContext>
    </Box>
  );
};

export default observer(FieldsExtractionResultPage);
