import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import CloudSync from '@mui/icons-material/CloudSync';
import { Autocomplete, Modal, TextField } from '@mui/material';

import { SocketEvents } from '../../../constants/events/SocketEvents';
import { fileStatus } from '../../../constants/fileStatus';
import { SupportedAIProvidersEnum } from '../../../constants/supportedAIProvidersEnum';
import { COLOR_WHITE } from '../../../constants/colors';
import { SIZES_SMALL } from '../../../constants/sizes';
import { Paths } from '../../../constants/routes';

import FullHeightScreenWrapper from '../../../components/FullHeightComponentWrapper/FullHeightScreenWrapper';
import { useStore } from '../../../hooks/useStore';
import FlexRowSpaceBetween from '../../../components/utils/flex/FlexRowSpaceBetween';
import KnowledgeBasesListView from '../../../components/knowledgeBase/KnowledgeBasesListView';
import UploadFile from '../../../components/knowledgeBase/UploadFile';
import { LoadingSpinner } from '../../../components/spinner/LoadingSpinner';
import { EMPTY_KNOWLEDGE_MODEL } from '../../../models/KnowledgeBaseModel';
import FilesListView from '../../../components/knowledgeBase/components/FilesListView';
import KnowledgeBaseHeader from '../../../components/knowledgeBase/components/KnowledgeBaseHeader';
import CreateKnowledgeBase from '../../../components/knowledgeBase/CreateKnowledgeBase';
import { Conversation } from '../../../models/Conversation';
import Button from '../../../components/buttons/Button';
import Flex from '../../../components/utils/flex/Flex';
import Subtitle from '../../../components/typography/Subtitle';
import { BedrockKnowledgeBaseModel } from '../../../components/knowledgeBase/CreateKnowledgeBaseModal';
import SupportedAiProviderLogo from '../../../components/SupportedProviderLogo/SupportedAiProviderLogo';
import FlexColumnStart from '../../../components/utils/flex/FlexColumnStart';

const PageContainer = styled(Box)`
  align-items: center;
  height: 100%;
  margin: 0;
  gap: 16px;
  background: ${COLOR_WHITE};
  border-radius: 8px;
  overflow: hidden;
  padding: 24px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

const KnowledgeBasePage = () => {
  const {
    localizationStore: { i18next: i18n },
    knowledgeBaseStore: {
      setCurrentKnowledgeBase,
      showFilePreview,
      getAllKnowledgeBasesByOrganization,
      uploadInProgress,
      currentKnowledgeBase,
      setCurrentKnowledgeBaseStatus,
      knowledgeBaseFiles,
      startIngestionJob,
      getKnowledgeBaseAvailableTextModels,
    },
    conversationStore: { createConversation },
    modelStore: { findDefaultFileModel },
    socketStore: { joinRoom, subscribe, unSubscribeAllListenersFromSingleEvent, leaveRoom },
  } = useStore();
  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState<BedrockKnowledgeBaseModel>();
  const [availableModels, setAvailableModels] = useState<BedrockKnowledgeBaseModel[]>([]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const navigate = useNavigate();

  const onStartNewChat = async () => {
    setLoading(true);

    const defaultFileModel = await findDefaultFileModel();

    const conversation = await createConversation(
      {
        title: currentKnowledgeBase.name,
        model:
          currentKnowledgeBase.isBedrockKnowledgeBase && selectedModel
            ? selectedModel.arn
            : defaultFileModel.modelVersion,
        providerName:
          currentKnowledgeBase.isBedrockKnowledgeBase && selectedModel
            ? SupportedAIProvidersEnum.bedrock
            : defaultFileModel.providerName,
        maxTokens: 1024,
        temperature: 0.7,
        isStartedFromKnowledgeBase: true,
        type: 'chat',
        knowledgeBaseId: currentKnowledgeBase.id,
      },
      false,
      true
    );

    setLoading(false);
    navigate(`${Paths.CHAT}/${(conversation as Conversation).id || ''}`);
    localStorage.setItem('selectedItem', `${i18n.t('home.newConversation.chat')}`);
  };

  const handleStartNewChat = async () => {
    if (selectedModel) {
      await onStartNewChat();
    }
  };

  useEffect(() => {
    const knowledgeBaseId = searchParams.get('knowledgeBase');
    const room = `knowledgeBase-${knowledgeBaseId}`;

    joinRoom(room);
    subscribe(SocketEvents.knowledgeBaseStatusChanged, ({ status }) => {
      setCurrentKnowledgeBaseStatus(status);
    });

    (async () => {
      const knowledgeBases = await getAllKnowledgeBasesByOrganization();

      if (knowledgeBaseId) {
        const knowledgeBaseFromSearchParams = knowledgeBases.find(
          knowledgeBase => knowledgeBase.id === knowledgeBaseId
        );

        if (knowledgeBaseFromSearchParams) {
          setCurrentKnowledgeBase(knowledgeBaseFromSearchParams);
        }
      } else {
        setCurrentKnowledgeBase(EMPTY_KNOWLEDGE_MODEL);
      }

      setLoading(false);
    })();

    return () => {
      leaveRoom(room);
      unSubscribeAllListenersFromSingleEvent(SocketEvents.knowledgeBaseStatusChanged);
    };
  }, [searchParams]);

  useEffect(() => {
    if (!uploadInProgress) {
      (async () => {
        await getAllKnowledgeBasesByOrganization();
        setLoading(false);
      })();
    }
  }, [id, uploadInProgress, loading]);

  useEffect(() => {
    (async () => {
      const availableModels = await getKnowledgeBaseAvailableTextModels();

      const defaultModel = availableModels.find(
        (model: BedrockKnowledgeBaseModel) =>
          model.arn === 'arn:aws:bedrock:us-east-1::foundation-model/anthropic.claude-3-sonnet-20240229-v1:0'
      );

      if (defaultModel) {
        setSelectedModel(defaultModel);
      }

      setAvailableModels(availableModels);
    })();
  }, []);

  return (
    <FullHeightScreenWrapper
      sx={{ backgroundColor: 'transparent', height: 'calc(100vh - 56px)', flexDirection: 'row' }}
    >
      <PageContainer sx={{ width: showFilePreview ? '60%' : '100%' }}>
        <FlexRowSpaceBetween>
          <KnowledgeBaseHeader />
          {currentKnowledgeBase.id && (
            <Flex>
              <Button
                startIcon={<AddIcon />}
                sx={{
                  padding: '9px 16px !important',
                  height: '40px !important',
                  width: 'fit-content',
                  fontWeight: 400,
                  marginRight: '8px',
                }}
                onClick={currentKnowledgeBase.isBedrockKnowledgeBase ? handleOpenModal : onStartNewChat}
                disabled={knowledgeBaseFiles.length === 0 || currentKnowledgeBase.status !== fileStatus.DONE}
              >
                New Chat
              </Button>
              {currentKnowledgeBase.isBedrockKnowledgeBase && (
                <Button
                  startIcon={<CloudSync />}
                  sx={{
                    padding: '9px 16px !important',
                    height: '40px !important',
                    width: 'fit-content',
                    fontWeight: 400,
                    marginRight: '8px',
                  }}
                  onClick={startIngestionJob}
                >
                  Start Sync
                </Button>
              )}
              <UploadFile />
            </Flex>
          )}
          {!currentKnowledgeBase.id && <CreateKnowledgeBase />}
        </FlexRowSpaceBetween>

        {loading ? (
          <Box>
            <LoadingSpinner />
          </Box>
        ) : currentKnowledgeBase.id ? (
          <FilesListView />
        ) : (
          <KnowledgeBasesListView />
        )}

        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Subtitle sx={{ marginBottom: '16px' }}>Select model to use</Subtitle>
            <Autocomplete
              value={selectedModel}
              options={availableModels}
              getOptionLabel={option => option.name}
              onChange={(event, newValue) => setSelectedModel(newValue as any)}
              renderInput={params => <TextField {...params} label="Select Option" variant="outlined" />}
              renderOption={(props, option) => {
                return (
                  <li style={{ alignItems: 'center', gap: '12px', padding: '8px' }} {...props}>
                    {/* Render the icon here (Replace with your icon logic) */}
                    <SupportedAiProviderLogo provider={option.providerName.toLowerCase()} size={32} />

                    {/* Title and subtitle */}
                    <FlexColumnStart sx={{ whiteSpace: 'break-spaces' }}>
                      <Typography variant="body2" fontWeight="600">
                        {option.name}
                      </Typography>
                      <Typography variant="caption" sx={{ color: 'gray' }}>
                        {option.arn || 'Description of the model'}
                      </Typography>
                    </FlexColumnStart>
                  </li>
                );
              }}
            />
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant={'outlined'} onClick={handleCloseModal} sx={{ marginRight: 1 }}>
                Cancel
              </Button>
              <Button onClick={handleStartNewChat} disabled={!selectedModel}>
                Start Chat
              </Button>
            </Box>
          </Box>
        </Modal>
      </PageContainer>
    </FullHeightScreenWrapper>
  );
};

export default observer(KnowledgeBasePage);
