import { makeAutoObservable, runInAction } from 'mobx';
import i18next, { i18n } from 'i18next';
import autoBind from 'auto-bind';

import { LocalStorageKeys } from '../constants/localStorageKeys';

import { en_US } from '../localization';

export class LocalizationStore {
  public locale: string;
  public i18next: i18n;
  language: string;

  constructor() {
    const language = localStorage.getItem(LocalStorageKeys.Language);
    const [lang, country] = language ? language.split('-') : ['en', 'US'];

    this.locale = `${lang ? lang.toLowerCase() : 'en'}-${country ? country.toUpperCase() : 'US'}`;
    this.i18next = i18next;
    this.i18next.init(
      {
        debug: false,
        lng: lang ? lang.toLowerCase() : 'en',
        fallbackLng: {
          default: ['en_US'],
        },
        resources: {
          en_US: { translation: en_US },
        },
      },
      () => {}
    );
    this.language = 'en-US';

    makeAutoObservable(this);
    autoBind(this);
  }

  setLocation(lang: string, callback?: () => void) {
    this.locale = lang;

    runInAction(() => {
      this.language = lang;
      const newItem = this.i18next.cloneInstance();
      newItem.changeLanguage(lang).then(() => {
        this.i18next = newItem;
        callback?.();
      });
    });
  }
}
