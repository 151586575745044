// Function to convert "doc" or "docx" files to pdf
export const convertDocxFileToPdf = async (file: File, convertToDocx: any) => {
  const docxToPdf = await convertToDocx(file);
  var binaryString = atob(docxToPdf);
  var len = binaryString.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  var blob = new Blob([bytes], { type: 'application/pdf' });

  file = new File([blob], `${file.name.split('.')[0]}.pdf`, {
    type: 'application/pdf',
  });
  return file;
};
