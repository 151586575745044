import React, { useState } from 'react';
import convertHeicToPng from '../utils/convertHeicToPng';

const useUploadAndCropPicture = () => {
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imageToCrop, setImageToCrop] = useState<string | null>(null);
  const [isCropImageModalOpen, setIsCropImageModalOpen] = useState(false);
  const [uploadedImgFile, setUploadedImgFile] = useState<object | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const onUploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const photo = files && files.length > 0 && files[0];
    setUploadLoading(true);

    if (photo) {
      const src = URL.createObjectURL(photo);

      if (photo.type === 'image/heic') {
        const image = await convertHeicToPng(src);
        const imageBlobUrl = URL.createObjectURL(image);
        setImageToCrop(imageBlobUrl);
      } else {
        setImageToCrop(src);
      }
      setUploadLoading(false);
      setIsCropImageModalOpen(true);
    }
  };

  const onCropDone = (file: any) => {
    if (file) {
      setUploadedImgFile(file);
      const src = URL.createObjectURL(file);
      setPreviewImage(src);
    }
  };

  const closeCropModal = () => {
    setIsCropImageModalOpen(false);
  };

  return {
    uploadLoading,
    imageToCrop,
    isCropImageModalOpen,
    uploadedImgFile,
    previewImage,
    onUploadImage,
    onCropDone,
    closeCropModal,
  };
};

export default useUploadAndCropPicture;
