import React, { ChangeEvent, createRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Close, Search } from '@mui/icons-material';
import { SxProps } from '@mui/material';

interface TextInputProps {
  testId?: string;
  id: string;
  value: string | number | null;
  onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  label?: string;
  error?: string;
  disabled?: boolean;
  hasClearButton?: boolean;
  rows?: number;
  onFocus?: (event: React.FocusEvent) => void;
  onBlur?: (event: React.FocusEvent) => void;
  onKeyDown?: (event: any) => void;
  helperText?: string;
  name: string;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  variant: 'standard' | 'filled' | 'outlined';
  type?: 'password' | 'email' | 'repeatPassword' | string;
  isSearchField?: boolean;
  placeholder?: string;
  sx?: SxProps;
  errorBackground?: string;
  extraSearchButton?: boolean;
  required?: boolean;
  toggleSearch?: () => void;
  clearText?: () => void;
  customEndAdornment?: React.ReactNode;
}

const TextInput = ({
  testId,
  value,
  onChange,
  label,
  error,
  disabled = false,
  rows,
  onFocus,
  onBlur,
  onKeyDown,
  helperText,
  name,
  onKeyUp,
  variant,
  id,
  type,
  placeholder,
  isSearchField,
  sx = {},
  errorBackground,
  extraSearchButton,
  toggleSearch,
  hasClearButton,
  clearText,
  customEndAdornment,
  required = false,
}: TextInputProps) => {
  const inputRef = createRef<HTMLTextAreaElement | HTMLInputElement>();
  const multiline = !!rows && rows > 1;

  const isPasswordField = type === 'password' || type === 'repeatPassword';

  const [showPassword, setShowPassword] = useState<boolean>(isPasswordField);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const typeUsed = isPasswordField && showPassword ? 'password' : 'text';

  return (
    <TextField
      id={id}
      name={name}
      inputRef={inputRef}
      value={value}
      onChange={onChange}
      label={label}
      variant={variant}
      inputProps={{
        'data-testid': testId,
      }}
      sx={{
        '& .MuiFormHelperText-root': {
          background: errorBackground || 'initial',
          margin: 0,
        },
        height: '40px',
        width: '100%',
        'input:-webkit-autofill': {
          transition: 'background-color 9999s ease-in-out 0s',
        },
        ...sx,
      }}
      disabled={disabled}
      error={!!error}
      helperText={error || helperText}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      multiline={multiline}
      rows={rows}
      onKeyUp={onKeyUp}
      placeholder={placeholder}
      type={typeUsed}
      required={required}
      InputProps={{
        startAdornment: isSearchField && (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: isPasswordField ? (
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ) : extraSearchButton ? (
          <InputAdornment position="end">
            <IconButton onClick={() => toggleSearch?.()}>
              <Search />
            </IconButton>
          </InputAdornment>
        ) : hasClearButton ? (
          value && (
            <InputAdornment position="end">
              <IconButton onClick={clearText}>
                <Close />
              </IconButton>
            </InputAdornment>
          )
        ) : (
          customEndAdornment || null
        ),
      }}
    />
  );
};

export default TextInput;
