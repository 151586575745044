import { makeAutoObservable, runInAction, toJS } from 'mobx';
import autoBind from 'auto-bind';

import { S3DocumentsApi } from '../api/S3DocumentsApi';
import { File as FileModel } from '../models/File';

interface S3Object {
  name: string;
  key: string;
  lastModified: Date;
  size: number;
  isSelected?: boolean;
  documentKey?: boolean;
}

interface S3Folder {
  name: string;
  path: string;
}

export interface s3DataDto {
  folders: S3Folder[];
  objects: S3Object[];
}

export class S3FileSystemStore {
  bucketName: string = '';
  s3FilesToAddToKnowledgeBase: FileModel[] = [];
  isUploadInProgress: boolean = false;
  filesLoading: boolean = false;
  prefix: string = '';
  s3Data: s3DataDto | undefined = undefined;
  s3DocumentsApi: S3DocumentsApi;

  constructor(s3DocumentsApi: S3DocumentsApi) {
    this.s3DocumentsApi = s3DocumentsApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  clearData() {
    runInAction(() => {
      this.bucketName = '';
      this.s3FilesToAddToKnowledgeBase = [];
      this.isUploadInProgress = false;
      this.filesLoading = false;
      this.prefix = '';
      this.s3Data = undefined;
    });
  }

  setS3FilesToAddToKnowledgeBase(value: FileModel[]) {
    runInAction(() => {
      this.s3FilesToAddToKnowledgeBase = value;
    });
  }

  setIsUploadInProgress(value: boolean) {
    runInAction(() => {
      this.isUploadInProgress = value;
    });
  }

  setFilesLoading(value: boolean) {
    runInAction(() => {
      this.filesLoading = value;
    });
  }

  setBucketName(value: string) {
    runInAction(() => {
      this.bucketName = value;
    });
  }

  setPrefix(value: string) {
    runInAction(() => {
      this.prefix = value;
    });
  }

  setS3Data(value: any) {
    runInAction(() => {
      this.s3Data = value;
    });
  }

  overrideObjectInS3Data(newObjectValue: S3Object) {
    if (!this.s3Data?.objects) {
      return;
    }

    const indexOfObjectInsideS3Data = this.s3Data.objects.findIndex(object => object.key === newObjectValue.key);
    const newObjectArray = [...this.s3Data.objects];
    newObjectArray[indexOfObjectInsideS3Data] = newObjectValue;

    runInAction(() => {
      this.s3Data = {
        folders: this.s3Data?.folders || [],
        objects: newObjectArray,
      };
    });
  }

  async getS3Data(bucketName: string, prefix: string) {
    this.setFilesLoading(true);
    const data = await this.s3DocumentsApi.listContents(bucketName, prefix);

    if (!data) {
      return false;
    }

    runInAction(() => {
      this.s3Data = {
        folders: data.folders,
        objects: data.objects.map(object => {
          if (
            this.s3FilesToAddToKnowledgeBase.findIndex(file => {
              return file.key === object.key;
            }) > -1
          ) {
            return {
              ...object,
              isSelected: true,
            };
          }

          return object;
        }),
      };
      this.setFilesLoading(false);
    });

    return data;
  }
}
