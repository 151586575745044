import React from 'react';
import { ButtonProps, default as MUIButton } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { COLOR_PRIMARY } from '../../constants/colors';

interface CustomButtonProps extends ButtonProps {
  children: React.ReactNode;
  onClick?: (event?: React.MouseEvent<HTMLElement> | undefined) => void;
  color?: 'primary' | 'secondary' | 'error' | 'success';
  variant?: 'contained' | 'text' | 'outlined';
  disabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  sx?: any;
  id?: string;
  loading?: boolean;
  size?: 'small' | 'medium' | 'large';
}

const Button = ({
  children,
  id,
  variant = 'contained',
  color = 'primary',
  disabled = false,
  startIcon,
  endIcon,
  onClick,
  sx,
  loading,
  size = 'large',
}: CustomButtonProps) => {
  const isOutlined = variant === 'outlined';

  const outlinedStyles = {
    borderColor: `${COLOR_PRIMARY} !important`,
    color: `${COLOR_PRIMARY} !important`,
  };

  return (
    <MUIButton
      disableRipple
      variant={variant}
      color={color}
      disabled={loading || disabled}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      id={id}
      size={size}
      sx={[
        isOutlined ? outlinedStyles : {},
        {
          fontFamily: 'Poppins !important',
          lineHeight: '22px',
          fontSize: '14px',
          fontWeight: '600',
          width: '100%',
          height: '40px !important',
          ...sx,
        },
      ]}
    >
      {loading ? <CircularProgress style={{ width: '18px', height: '18px' }} /> : children}
    </MUIButton>
  );
};

export default Button;
