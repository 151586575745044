import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import ApartmentIcon from '@mui/icons-material/ApartmentOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Stack from '@mui/material/Stack';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { observer } from 'mobx-react';

import { COLOR_WHITE, GRAY_COLORS } from '../constants/colors';
import { SIZES_SMALL } from '../constants/sizes';

import { useStore } from '../hooks/useStore';
import CopyRight from '../components/copyRight/CopyRight';
import RoleLabel from '../components/role/RoleLabel';
import Flex from '../components/utils/flex/Flex';
import EditProfileModal from '../components/profile/EditProfileModal';
import { DropdownOption } from '../models/DropdownOption';
import UploadProfilePictureComponent from '../components/uploadProfilePicture/UploadProfilePictureComponent';
import Button from '../components/buttons/Button';
import TeamUsersPictures from '../components/adminPortal/teams/teamCards/TeamUsersPictures';

const PageContainer = styled(Box)`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 40px 104px;
  gap: 16px;
  width: 100%;

  @media screen and (max-width: ${SIZES_SMALL}) {
    margin: 16px;
    width: 100%;
  }
`;

const CardsContainer = styled(Box)`
  margin-top: 16px;
  display: flex;
  gap: 24px;
  width: 100%;

  @media screen and (max-width: ${SIZES_SMALL}) {
    flex-direction: column;
  }
`;

const Card = styled(Box)`
  background: ${COLOR_WHITE};
  padding: 24px 40px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${GRAY_COLORS.GRAY_2};
  margin-top: 9px;
  margin-bottom: 8px;
`;

const UserPersonalDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: ${SIZES_SMALL}) {
    flex-direction: column !important;
  }
`;

const ProfilePage = () => {
  const {
    localizationStore: { i18next: i18n },
    userStore: { userData, getUserInformation },
    teamStore: { teams, getTeamsByUserOrganization },
  } = useStore();

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [teamOptions, setTeamOptions] = useState<DropdownOption[]>([]);
  const [currentTeams, setTeams] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      await getUserInformation();
      await getTeamsByUserOrganization();
    })();
  }, []);

  useEffect(() => {
    const teamsOptions: DropdownOption[] = teams?.map(team => {
      return { name: team.name, value: team.name, id: team.id };
    });

    setTeamOptions(teamsOptions);
  }, [teams]);

  useEffect(() => {
    const userTeams: string[] =
      userData?.teams?.map((item, index) => {
        return item.name;
      }) || [];
    setTeams(userTeams);
  }, [userData]);

  const onCloseModal = () => {
    setEditModalOpened(false);
  };

  const onOpenModal = () => {
    setEditModalOpened(true);
  };

  return (
    <PageContainer>
      <CardsContainer>
        <Card sx={{ height: 'fit-content', flex: 2, maxWidth: '500px' }}>
          <Flex>
            <UploadProfilePictureComponent user={userData} size={80} sx={{ marginRight: '20px' }} />
            <Stack sx={{ width: '100%' }}>
              <UserPersonalDetails>
                <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: '2px', marginRight: '12px' }}>
                  {userData?.fullName}
                </Typography>
                <RoleLabel type={userData.role} />
              </UserPersonalDetails>

              <Typography variant="subtitle2" sx={{ fontWeight: 400, color: GRAY_COLORS.GRAY_8 }}>
                {userData?.position}
              </Typography>
              <HorizontalDivider />
              <Typography variant="subtitle2" sx={{ fontWeight: 400, color: GRAY_COLORS.GRAY_8 }}>
                {userData?.email}
              </Typography>

              <Flex sx={{ marginTop: '40px', gap: '8px' }}>
                <Button
                  variant={'outlined'}
                  startIcon={<EditOutlinedIcon sx={{ width: '16px', height: '16px' }} />}
                  sx={{
                    width: 'fit-content',
                    borderColor: `${GRAY_COLORS.GRAY_2} !important`,
                    color: `${GRAY_COLORS.GRAY_9} !important`,
                    padding: '7px 12px !important',
                    fontWeight: 500,
                  }}
                  onClick={onOpenModal}
                >
                  {i18n.t('profile.editProfile.action')}
                </Button>
              </Flex>
            </Stack>
          </Flex>
        </Card>

        <Card sx={{ padding: '24px 40px', display: 'flex', flexDirection: 'column', flex: 3, maxWidth: '700px' }}>
          <Typography variant={'h6'} sx={{ fontWeight: 700, marginBottom: '2px', marginRight: '12px' }}>
            {i18n.t('profile.details.message')}
          </Typography>
          <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_8, marginTop: '4px' }}>
            {i18n.t('profile.specifics.message')}
          </Typography>

          <Flex sx={{ marginTop: '24px', alignItems: 'center', width: '100%', gap: '4px' }}>
            <ApartmentIcon fontSize={'small'} sx={{ color: GRAY_COLORS.GRAY_5 }} />
            <Typography variant={'body1'} sx={{ fontWeight: 600 }}>
              {`${i18n.t('profile.organization.message')}`}
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 400, marginLeft: 'auto' }}>
              {userData?.organization?.name}
            </Typography>
          </Flex>

          <Flex sx={{ marginTop: '24px', alignItems: 'center', width: '100%', gap: '4px' }}>
            <PeopleOutlineIcon fontSize={'small'} sx={{ color: GRAY_COLORS.GRAY_5 }} />
            <Box sx={{ width: '100%' }}>
              <Typography variant={'body1'} sx={{ fontWeight: 600 }}>
                {`${i18n.t('profile.teams.message')}`}
              </Typography>
            </Box>
          </Flex>
          <Stack sx={{ gap: '16px', marginTop: '16px', width: '100%' }}>
            {userData.teams?.map((item, index) => (
              <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }} key={`user-team-${index}`}>
                <Typography key={item?.id} variant="subtitle2" sx={{ fontWeight: 400 }}>
                  {item?.name}
                </Typography>
                <TeamUsersPictures users={item.users} />
              </Flex>
            ))}
          </Stack>
        </Card>
      </CardsContainer>

      <EditProfileModal
        isOpen={editModalOpened}
        onClose={onCloseModal}
        teamOptions={teamOptions}
        teams={currentTeams}
        setTeams={setTeams}
      />

      <CopyRight />
    </PageContainer>
  );
};

export default observer(ProfilePage);
