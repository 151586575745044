import { useState } from 'react';
import { useNavigate } from 'react-router';
import { BeatLoader } from 'react-spinners';
import { observer } from 'mobx-react';
import { Box, ClickAwayListener, IconButton, Typography } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import styled from 'styled-components';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

import { COLOR_PRIMARY, COLOR_WHITE, GRAY_COLORS } from '../../../../../constants/colors';
import { Paths } from '../../../../../constants/routes';

import Flex from '../../../../../components/utils/flex/Flex';
import { Conversation } from '../../../../../models/Conversation';
import { getTextOverflowEllipsisStyles } from '../../../../../components/typography/utils';
import { useStore } from '../../../../../hooks/useStore';
import FlexColumnStart from '../../../../../components/utils/flex/FlexColumnStart';
import EditIcon from '@mui/icons-material/Edit';

const NotificationBullet = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${COLOR_PRIMARY};
  margin-left: auto;
  margin-right: 0;
`;

interface HistoryItemProps {
  conversation: Conversation;
}

const HistoryItem = ({ conversation }: HistoryItemProps) => {
  const {
    conversationStore: {
      removeConversation,
      currentConversation,
      uploadInProgress,
      uploadProgress,
      uploadingConversationId,
      setCurrentConversationAndConversationsArray,
      setShowEditConversationTitleModal,
    },
    uiStore: { setIsCancelUploadModalOpen },
  } = useStore();

  const isSelected = currentConversation.id === conversation.id;

  const [isHovered, setIsHovered] = useState(false);
  const [isDeleteConfirmationNeeded, setIsDeleteConfirmationNeeded] = useState(false);

  const handleEdit = () => {
    setCurrentConversationAndConversationsArray(conversation);
    setShowEditConversationTitleModal(true);
  };

  const navigate = useNavigate();

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsDeleteConfirmationNeeded(false);
      }}
    >
      <Box
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        sx={{ cursor: 'pointer', maxWidth: '100%', overflow: 'hidden' }}
        onClick={() => navigate(`${Paths.CHAT}/${conversation.id}`)}
      >
        <Flex
          sx={{
            height: conversation.sharedByName ? '70px' : '50px',
            alignItems: 'center',
            marginBottom: '12px',
            padding: '0 12px',
            borderRadius: '4px',
            backgroundColor: `${isDeleteConfirmationNeeded || isSelected ? 'rgba(56, 113, 224, 0.1)' : COLOR_WHITE}`,
            '&:hover': {
              backgroundColor: 'rgba(56, 113, 224, 0.1)',
            },
          }}
        >
          {conversation.files?.length ? (
            <AttachFileIcon sx={{ width: '17px', height: '17px', marginRight: '10px', color: GRAY_COLORS.GRAY_5 }} />
          ) : conversation.isStartedFromKnowledgeBase ? (
            <FolderOutlinedIcon
              sx={{ width: '17px', height: '17px', marginRight: '10px', color: GRAY_COLORS.GRAY_5 }}
            />
          ) : (
            <QuestionAnswerIcon
              sx={{ width: '17px', height: '17px', marginRight: '10px', color: GRAY_COLORS.GRAY_5 }}
            />
          )}

          <FlexColumnStart sx={{ overflow: 'hidden' }}>
            <Typography
              variant={'body1'}
              sx={{ ...getTextOverflowEllipsisStyles(1), color: GRAY_COLORS.GRAY_8, maxWidth: '320px' }}
            >
              {conversation.title || 'Untitled Conversation'}
            </Typography>
            {conversation.sharedByName && (
              <Typography
                variant={'body2'}
                sx={{
                  ...getTextOverflowEllipsisStyles(1),
                  maxWidth: '320px',
                  color: GRAY_COLORS.GRAY_500,
                  fontSize: '12px',
                  marginTop: '4px',
                }}
              >
                Shared by {conversation.sharedByName}
              </Typography>
            )}
          </FlexColumnStart>

          {conversation.isStreaming ? (
            <BeatLoader size={8} />
          ) : isHovered && !isDeleteConfirmationNeeded ? (
            uploadingConversationId === conversation.id && uploadInProgress ? (
              <Flex sx={{ marginLeft: 'auto', alignItems: 'center' }}>
                <Typography variant={'body1'} sx={{ color: COLOR_PRIMARY, fontWeight: 500 }}>
                  {uploadProgress}%
                </Typography>
                <IconButton onClick={() => setIsCancelUploadModalOpen(true)}>
                  <CloseIcon />
                </IconButton>
              </Flex>
            ) : (
              <Flex sx={{ marginLeft: 'auto', alignItems: 'center' }}>
                <IconButton
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleEdit();
                  }}
                >
                  <EditIcon sx={{ width: '17px', height: '17px', color: GRAY_COLORS.GRAY_5 }} />
                </IconButton>
                <IconButton
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    setIsDeleteConfirmationNeeded(true);
                  }}
                >
                  <DeleteIcon
                    sx={{ width: '17px', height: '17px', color: GRAY_COLORS.GRAY_5 }}
                    className="Delete-Button"
                  />
                </IconButton>
              </Flex>
            )
          ) : (
            <>{conversation.isNewConversation ? <NotificationBullet /> : null}</>
          )}
          {isDeleteConfirmationNeeded ? (
            <Flex sx={{ marginLeft: 'auto', alignItems: 'center' }}>
              <IconButton
                onClick={async event => {
                  event.preventDefault();
                  event.stopPropagation();

                  await removeConversation(conversation.id);
                  setIsDeleteConfirmationNeeded(false);

                  if (conversation.id === currentConversation.id) {
                    navigate(Paths.CHAT);
                  }
                }}
              >
                <CheckIcon sx={{ width: '17px', height: '17px', color: GRAY_COLORS.GRAY_5 }} className="CheckIcon" />
              </IconButton>

              <IconButton
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  setIsDeleteConfirmationNeeded(false);
                }}
              >
                <CloseIcon sx={{ width: '17px', height: '17px', color: GRAY_COLORS.GRAY_5 }} />
              </IconButton>
            </Flex>
          ) : null}
        </Flex>
      </Box>
    </ClickAwayListener>
  );
};

export default observer(HistoryItem);
