import { Container, Content } from '../../../components/login/LoginComponents';
import { AppBar } from '@mui/material';
import Flex from '../../../components/utils/flex/Flex';
import Logo from '../../../components/logo/Logo';
import CopyRight from '../../../components/copyRight/CopyRight';
import React from 'react';
import SignUpCard from '../../../components/login/SignUpCard';

const MemberSignUpPage = () => {
  return (
    <Container>
      <AppBar position={'fixed'} sx={{ borderBottom: 'none' }}>
        <Flex sx={{ height: '100%', marginLeft: '32px', alignItems: 'center' }}>
          <Logo />
        </Flex>
      </AppBar>

      <Content>
        <div />
        <SignUpCard />
        <CopyRight />
      </Content>
    </Container>
  );
};

export default MemberSignUpPage;
