import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';

import { GRAY_COLORS } from '../../constants/colors';

import Flex from '../utils/flex/Flex';
import Button from '../buttons/Button';
import CustomModal from './CustomModal';

interface RedactDataModalProps {
  isOpen: boolean;
  onClose: () => void;
  filesToUpload?: File[];
  handleUploadFiles: (filesToUpload: File[] | undefined, skipRedaction: boolean) => void;
}

const RedactDataModal = ({ isOpen, onClose, filesToUpload, handleUploadFiles }: RedactDataModalProps) => {
  const handleClick = async (skipRedaction: boolean) => {
    onClose();

    handleUploadFiles(filesToUpload, skipRedaction);
  };

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} sx={{ maxWidth: '600px' }}>
      <Stack sx={{ gap: '16px' }}>
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Typography variant={'body1'} sx={{ fontWeight: 700 }}>
            Redact Sensitive Data?
          </Typography>
          <IconButton size={'small'} onClick={onClose}>
            <Close fontSize={'small'} />
          </IconButton>
        </Flex>
        <Typography variant={'body2'}>
          You selected "
          <Typography
            component="span"
            sx={{
              display: 'inline-block',
              wordBreak: 'break-word',
            }}
          >
            <b>{filesToUpload ? filesToUpload.map(file => file.name).join(', ') : ''}</b>
          </Typography>
          ", would you like us to redact any sensitive data?
        </Typography>
        <Flex sx={{ justifyContent: 'flex-end', gap: '16px' }}>
          <Button
            variant={'outlined'}
            sx={{
              width: 'fit-content',
              borderColor: `${GRAY_COLORS.GRAY_2} !important`,
              color: `${GRAY_COLORS.GRAY_9} !important`,
            }}
            onClick={() => handleClick(true)}
          >
            No, continue without redacting
          </Button>
          <Button sx={{ width: 'fit-content' }} onClick={() => handleClick(false)}>
            Yes, redact
          </Button>
        </Flex>
      </Stack>
    </CustomModal>
  );
};

export default RedactDataModal;
