import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import { CreateWorkflowRunDto, EMPTY_WORKFLOW_RUN, WorkflowRun } from '../models/Workflows';
import { WorkflowStore } from './WorkflowStore';

export class CreateWorkflowRunStore {
  selectedWorkflowRun: WorkflowRun = EMPTY_WORKFLOW_RUN;
  workflowRunData: any;
  workflowsStore: WorkflowStore;

  constructor(workflowsStore: WorkflowStore) {
    this.workflowsStore = workflowsStore;
    makeAutoObservable(this);
    autoBind(this);
  }

  createWorkflowRun(createWorkflowRunDto: CreateWorkflowRunDto) {
    return this.workflowsStore.workflowsApi.createWorkflowRun(createWorkflowRunDto);
  }

  setWorkflowRunData(data: any) {
    const result = {};

    Object.keys(data).forEach(keyString => {
      const value = data[keyString];
      const keys = keyString.split('.');

      keys.reduce((acc: any, key, index) => {
        if (index === keys.length - 1) {
          acc[key] = value;
        } else {
          if (!acc[key] || typeof acc[key] !== 'object') {
            acc[key] = {};
          }
        }
        return acc[key];
      }, result);
    });

    runInAction(() => {
      this.workflowRunData = result;
    });
  }

  setWorkflowRunDataField(field: string, value: any) {
    runInAction(() => {
      const keys = field.split('.');
      const res = keys.reduce((acc, key, index) => {
        if (index === keys.length - 1) {
          acc[key] = value;
        } else {
          if (!acc[key] || typeof acc[key] !== 'object') {
            acc[key] = {};
          }
        }

        return acc[key];
      }, this.workflowRunData);
      return res;
    });
  }

  setWorkflowRunFormField(field: string, arrName: string) {
    let result: any;
    const fieldArr = this.workflowRunData[arrName] || [];
    const fields = this.workflowRunData[field];
    result = [...fieldArr, fields];

    runInAction(() => {
      this.workflowRunData[arrName] = result;
    });
  }

  removeKeyFromWorkflowRunData(key: string) {
    runInAction(() => {
      delete this.workflowRunData[key];
    });
  }

  removeElementFromWorkflowRunData(key: string, index: number) {
    runInAction(() => {
      const field = this.workflowRunData[key];
      if (field?.length && index <= field?.length - 1) {
        field.splice(index, 1);
      }
    });
  }
}
