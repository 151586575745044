import {
  SIZES_NUMBER_BIG,
  SIZES_NUMBER_MEDIUM,
  SIZES_NUMBER_SMALL,
  SIZES_NUMBER_SMALL_MEDIUM,
  SIZES_NUMBER_TINY_SMALL,
} from '../constants/sizes';

export const getDimensions = () => {
  const screenWidth = window.innerWidth;

  const big = screenWidth >= SIZES_NUMBER_BIG;
  const medium = screenWidth >= SIZES_NUMBER_MEDIUM && screenWidth < SIZES_NUMBER_BIG;
  const smallMedium = screenWidth >= SIZES_NUMBER_SMALL_MEDIUM && screenWidth < SIZES_NUMBER_MEDIUM;
  const small = screenWidth >= SIZES_NUMBER_SMALL && screenWidth < SIZES_NUMBER_SMALL_MEDIUM;
  const tinySmall = screenWidth >= SIZES_NUMBER_TINY_SMALL && screenWidth < SIZES_NUMBER_SMALL;
  const tiny = screenWidth < SIZES_NUMBER_TINY_SMALL;

  return {
    big,
    medium,
    smallMedium,
    small,
    tinySmall,
    tiny,
  };
};
