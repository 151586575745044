export const hashStringToColor = (string: string) => {
  // Create a hash code from the string
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash; // Convert to 32-bit integer
  }

  // Convert the hash code to a hexadecimal color code
  const hexColor = (hash & 0x00ffffff).toString(16).padStart(6, '0');
  return `#${hexColor}`;
};
