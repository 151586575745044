import React, { useState } from 'react';
import { observer } from 'mobx-react';

import FullHeightScreenWrapper from '../../components/FullHeightComponentWrapper/FullHeightScreenWrapper';
import HistoryPageHeader from './components/HistoryPageHeader';
import ConversationsHistoryContent from './components/ConversationsHistoryContent';

const HistoryPage = () => {
  const [searchValue, setSearchValue] = useState('');

  return (
    <FullHeightScreenWrapper>
      <HistoryPageHeader setSearchValue={setSearchValue} />
      <ConversationsHistoryContent searchValue={searchValue} />
    </FullHeightScreenWrapper>
  );
};

export default observer(HistoryPage);
