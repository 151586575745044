import axios, { AxiosRequestConfig } from 'axios';

import { ApiConfig } from './ApiConfig';
import { GoogleDriveExportBody } from '../models/GoogleDrive';

export class GoogleDriveApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: any) {
    this.apiConfig = apiConfig;
  }

  uploadGoogleDriveFile(googleDriveObject: GoogleDriveExportBody) {
    return this.apiConfig.sendRequest('POST', `/upload/google-drive`, { googleDriveObject });
  }

  getConnectionUrl(redirectUrl: string) {
    return this.apiConfig.sendRequest('POST', `/upload/google-drive/get-auth-url`, { redirectUrl });
  }

  getOauth2WithToken(code: string, redirectUrl: string) {
    return this.apiConfig.sendRequest('POST', `/upload/google-drive/get-oauth2`, { code, redirectUrl });
  }

  async getTokenInfo(token: string) {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${token}`,
    };

    const response = await axios(config);
    return response;
  }
}
