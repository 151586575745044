import React, { ChangeEvent, useState, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import Joi from 'joi';

import { COLOR_PRIMARY } from '../../constants/colors';

import Button from '../buttons/Button';
import CustomModal from '../modal/CustomModal';
import { useStore } from '../../hooks/useStore';
import TextInput from '../inputs/TextInput';
import Select from '../select/Select';
import { DropdownOption } from '../../models/DropdownOption';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  setTeams: (arg: string[]) => void;
  teamOptions: Array<DropdownOption>;
  teams: string[];
}

interface FormProps {
  fullName: string;
  position: string;
}
const EditProfileModal = ({ isOpen, onClose, teams, teamOptions, setTeams }: ModalProps) => {
  const {
    localizationStore: { i18next: i18n },
    userStore: { userData, updateUserInformation },
  } = useStore();

  const [formData, setFormData] = useState({
    fullName: userData.fullName,
    position: userData.position,
    email: userData.email,
    teams: teams,
  });
  const [formErrors, setFormErrors] = useState({
    fullName: '',
    position: '',
    email: '',
    teams: '',
  });

  useEffect(() => {
    setFormData({
      fullName: userData.fullName,
      position: userData.position,
      email: userData.email,
      teams: teams,
    });
  }, [userData, isOpen]);

  useEffect(() => {
    setFormErrors({
      fullName: '',
      position: '',
      email: '',
      teams: '',
    });
  }, [isOpen]);

  const getSchema = (i18n: any) => {
    return Joi.object().keys({
      fullName: Joi.string().required().label(i18n.t('labels.fullName')),
      position: Joi.string().required().label(i18n.t('labels.position')),
      teams: Joi.array().min(1).required().label(i18n.t('labels.teams')),
    });
  };

  const generalValidation = (input: FormProps, name?: string): string | undefined | null | { [key: string]: any } => {
    const results = getSchema(i18n).validate(input, { abortEarly: false });

    if (results.error && name) {
      const error = results.error.details.find(obj => obj.path[0] === name);
      return error?.message;
    }

    if (results.error && !name) {
      let errorsObj: { [key: string]: any } | null = null;
      results.error.details.forEach(detail => {
        errorsObj = {
          ...(errorsObj || {}),
          [detail.path[0]]: detail.message,
        };
      });

      return errorsObj;
    }

    return null;
  };

  const onSave = async () => {
    const userTeams: Array<{ id?: string }> = teams?.map((item: string) => {
      const teabByName = teamOptions?.find(({ name }) => name === item);
      return { id: teabByName?.id };
    });

    const updateData = { fullName: formData.fullName, position: formData.position, teams: userTeams };
    const errors = generalValidation(updateData);
    if (errors && typeof errors === 'object') {
      setFormErrors({
        ...formErrors,
        ...errors,
      });
      return;
    }

    await updateUserInformation(updateData);
    onClose();
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      sx={{ width: '328px', alignItems: 'flex-start', flexDirection: 'column', padding: '32px' }}
    >
      <Stack width={'100%'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
        <Typography variant={'h5'}>{i18n.t('profileModal.edit.title')}</Typography>
      </Stack>

      <TextInput
        id="fullName"
        name={'fullName'}
        label={i18n.t('labels.fullName')}
        value={formData.fullName}
        error={i18n.t(formErrors.fullName)}
        onChange={onChange}
        variant={'outlined'}
        type={'fullName'}
        testId={'edit-profile-name'}
        sx={{ marginTop: '24px' }}
      />

      <TextInput
        id="position"
        name={'position'}
        label={i18n.t('labels.position')}
        value={formData.position}
        error={i18n.t(formErrors.position)}
        onChange={onChange}
        variant={'outlined'}
        type={'position'}
        testId={'edit-profile-position'}
        sx={{ marginTop: '26px' }}
      />

      <TextInput
        id="email"
        name={'email'}
        label={i18n.t('labels.email')}
        value={formData.email}
        error={i18n.t(formErrors.email)}
        onChange={onChange}
        variant={'outlined'}
        type={'position'}
        testId={'edit-profile-email'}
        disabled
        sx={{ marginTop: '26px' }}
      />

      <Select
        label={i18n.t('promptGallery.search.label.teams')}
        value={teams}
        placeholder={''}
        multiple={true}
        onChange={(event: any) => setTeams(event.target.value)}
        options={teamOptions}
        error={formErrors.teams}
        sx={{
          flex: 4,
          marginTop: '26px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '200px',
        }}
      />

      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} marginTop={'24px'}>
        <Button
          onClick={onClose}
          variant={'outlined'}
          sx={{
            padding: '9px 16px',
            height: '40px',
            marginRight: '12px',
            color: COLOR_PRIMARY,
            borderColor: COLOR_PRIMARY,
          }}
        >
          Cancel
        </Button>
        <Button onClick={onSave} sx={{ padding: '9px 16px', height: '40px' }}>
          Save
        </Button>
      </Stack>
    </CustomModal>
  );
};

export default observer(EditProfileModal);
