import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import { COLOR_BUTTON_OUTLINED_DISABLED, COLOR_PRIMARY, COLOR_WHITE } from '../../constants/colors';

export const PrimarySwitch = styled(Switch)`
  width: 65px !important;
  .app1-Mui-checked:hover {
    background: none !important;
  }
  .app1-MuiSwitch-switchBase {
    color: ${COLOR_WHITE} !important;
    padding: 11px 14px !important;
    background: none !important;
  }
  .app1-MuiSwitch-track {
    margin-top: -3px;
    height: 20px;
    border-radius: 40px;
    background: ${COLOR_BUTTON_OUTLINED_DISABLED} !important;
    opacity: unset !important;
  }
  .Mui-checked + .app1-MuiSwitch-track {
    background: ${COLOR_PRIMARY} !important;
  }
  .app1-MuiSwitch-thumb {
    width: 16px;
    height: 16px;
  }
`;
