import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import { PromptByConversation, TopPrompt, TopPromptByTeam } from '../models/Prompt';
import { Summary, SummaryInitial } from '../models/Insights';
import { InsightsApi } from '../api/InsightsApi';

export class InsightsStore {
  insightsApi: InsightsApi;
  conversationPrompts: PromptByConversation[] = [];
  topTeamPrompts: TopPromptByTeam[] = [];
  top10Prompts: TopPrompt[] = [];
  summary: Summary = SummaryInitial;

  constructor(insightsApi: InsightsApi) {
    this.insightsApi = insightsApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  async getPromptsByConversation(selectedPeriod: string) {
    const prompts = await this.insightsApi.getPromptsByConversation(selectedPeriod);

    runInAction(() => {
      this.conversationPrompts = prompts;
    });
  }

  async getTopPromptsByTeam(selectedPeriod: string) {
    const prompts = await this.insightsApi.getTopPromptsByTeam(selectedPeriod);

    runInAction(() => {
      this.topTeamPrompts = prompts;
    });
  }

  async getTop10Prompts(selectedPeriod: string) {
    const prompts = await this.insightsApi.getTop10Prompts(selectedPeriod);

    runInAction(() => {
      this.top10Prompts = prompts;
    });
  }

  async getSummary(selectedPeriod: string) {
    const summary = await this.insightsApi.getSummary(selectedPeriod);

    runInAction(() => {
      this.summary = summary;
    });
  }
}
