import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import { WorkflowApi } from '../api/WorkflowApi';
import {
  Workflow,
  WorkflowRun,
  EMPTY_WORKFLOW,
  EMPTY_WORKFLOW_RUN,
  CreateWorkflowRunDto,
  UpdateWorkflowRunDto,
} from '../models/Workflows';

export class WorkflowStore {
  workflowsApi: WorkflowApi;
  selectedWorkflow: Workflow = EMPTY_WORKFLOW;
  selectedWorkflowRun: WorkflowRun = EMPTY_WORKFLOW_RUN;
  workflows: Workflow[] = [];
  workflowRuns: WorkflowRun[] = [];
  loading: boolean = false;

  constructor(workflowsApi: WorkflowApi) {
    this.workflowsApi = workflowsApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  setSelectedWorkflow(selectedWorkflow: Workflow) {
    runInAction(() => {
      this.selectedWorkflow = selectedWorkflow;
    });
  }

  async getWorkflowsForOrganization(organizationId: string) {
    this.setLoading(true);
    try {
      const workflows = await this.workflowsApi.getAllWorkflowsByOrganizaton(organizationId);

      runInAction(() => {
        this.workflows = workflows;
      });
    } finally {
      this.setLoading(false);
    }
  }

  //Run
  setSelectedWorkflowRun(selectedWorkflowRun: WorkflowRun) {
    runInAction(() => {
      this.selectedWorkflowRun = selectedWorkflowRun;
    });
  }

  async createWorkflowRun(createWorkflowRunDto: CreateWorkflowRunDto) {
    this.setLoading(true);
    try {
      const run = await this.workflowsApi.createWorkflowRun(createWorkflowRunDto);

      runInAction(() => {
        this.selectedWorkflowRun = run;
      });

      return run;
    } finally {
      this.setLoading(false);
    }
  }

  async getWorkflowRunsByUserId(userId: string) {
    this.setLoading(true);
    try {
      const runs = await this.workflowsApi.findAllRunsByUserId(userId);

      runInAction(() => {
        this.workflowRuns = runs;
      });
    } finally {
      this.setLoading(false);
    }
  }

  async getWorkflowRunByIdAndSetInStore(userId: string) {
    const workflowRun = await this.workflowsApi.findOneRun(userId);

    runInAction(() => {
      this.selectedWorkflowRun = workflowRun;
    });

    return workflowRun;
  }

  deleteWorkflowRun(workflowRunId: string) {
    return this.workflowsApi.deleteRun(workflowRunId);
  }

  async updateRun(workflowRunId: string, updateWorkflowRunDto: UpdateWorkflowRunDto) {
    const updatedWorkFlowRun = await this.workflowsApi.updateRun(workflowRunId, updateWorkflowRunDto);

    runInAction(() => {
      this.selectedWorkflow = updatedWorkFlowRun;
    });
  }

  setLoading(loading: boolean) {
    runInAction(() => {
      this.loading = loading;
    });
  }
}
