import { Team } from '../../../models/Team';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR_PRIMARY, COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';
import Typography from '@mui/material/Typography';

interface PromptsGalleryTeamFilterItemProps {
  isSelected: boolean;
  onClick: () => void;
  team?: Team;
  isAllTeams?: boolean;
}

const PromptsGalleryTeamFilterItem = ({ team, isAllTeams, isSelected, onClick }: PromptsGalleryTeamFilterItemProps) => {
  return (
    <PromptsGalleryTeamFilterItemContainer isSelected={isSelected} onClick={onClick}>
      <Typography
        variant={'body2'}
        sx={isSelected ? { whiteSpace: 'nowrap', color: COLOR_WHITE } : { whiteSpace: 'nowrap' }}
      >
        {isAllTeams ? 'All prompts' : team?.name}
      </Typography>
    </PromptsGalleryTeamFilterItemContainer>
  );
};

const PromptsGalleryTeamFilterItemContainer = styled(Box)<{ isSelected: boolean }>`
  cursor: pointer;
  width: fit-content;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid ${props => (props.isSelected ? COLOR_PRIMARY : GRAY_COLORS.GRAY_2)};
  background-color: ${props => (props.isSelected ? COLOR_PRIMARY : 'unset')};
  margin-right: 12px;
  margin-top: 12px;

  &:hover {
    background-color: ${props => (props.isSelected ? COLOR_PRIMARY + 'DE' : GRAY_COLORS.GRAY_2 + '33')};
  }
`;

export default PromptsGalleryTeamFilterItem;
