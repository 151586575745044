import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';

import { COLOR_DARK_BACKGROUND, COLOR_TEAM_PROVECTUS_BACKGROUND, GRAY_COLORS } from '../../../constants/colors';

export const Container = styled(Box)`
  padding: 30px 36px;
  background-color: ${COLOR_DARK_BACKGROUND};
  border-radius: 8px;
`;

export const SettingsItem = styled(Box)`
  min-height: 26px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Divider = styled(Box)`
  height: 26px;
  width: 1px;
  background: ${GRAY_COLORS.GRAY_300};
`;

export const ToggleSettingsSection = styled(Box)`
  display: flex;
  align-items: center;

  margin-left: 16px;
`;

export const BlueIconButton = styled(IconButton)`
  background-color: ${COLOR_TEAM_PROVECTUS_BACKGROUND};
  border-radius: 4px;
  min-height: 36px;

  &:hover {
    background-color: #f2f9ff;
  }

  &:disabled {
    background-color: ${COLOR_TEAM_PROVECTUS_BACKGROUND};
  }
`;
