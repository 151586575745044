import { Box, Breadcrumbs, Link, styled } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { observer } from 'mobx-react';

import { useStore } from '../../../hooks/useStore';
import Flex from '../../utils/flex/Flex';
import { ClipLoader } from 'react-spinners';
import { GRAY_COLORS } from '../../../constants/colors';

const S3FileSystemNavigation = () => {
  const {
    s3FileSystemStore: { prefix, setPrefix, filesLoading },
  } = useStore();

  const folders = ('/' + prefix)
    .split('/')
    .filter((item, index) => !!item || index === 0)
    .map((item, index, items) => {
      return {
        name: `${item}`,
        prefix: `${items
          .filter((item, index) => !!item)
          .slice(0, index)
          .join('/')}/`,
        isCurrent: index == items.length - 1,
      };
    });

  return (
    <Flex sx={{ alignItems: 'center' }}>
      <Breadcrumbs>
        {folders.map((folder, index) => {
          if (index === 0) {
            return (
              <StyledLink
                onClick={() => {
                  setPrefix('');
                }}
              >
                <HomeIcon />
              </StyledLink>
            );
          }

          return (
            <StyledLink
              onClick={() => {
                setPrefix(folder.prefix);
              }}
            >
              {folder.name}
            </StyledLink>
          );
        })}
      </Breadcrumbs>

      {filesLoading && (
        <Box sx={{ marginLeft: '8px' }}>
          <ClipLoader size={20} color={GRAY_COLORS.GRAY_5} />
        </Box>
      )}
    </Flex>
  );
};

const StyledLink = styled(Link)`
  cursor: pointer;
`;

export default observer(S3FileSystemNavigation);
