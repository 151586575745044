import React, { useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { observer } from 'mobx-react';
import { Box, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router';

import { Paths } from '../../../../constants/routes';
import { GRAY_COLORS } from '../../../../constants/colors';
import { TimePeriods } from '../../../../constants/timePeriods';

import Flex from '../../../../components/utils/flex/Flex';
import { CustomSearchBar } from '../../../../components/CustomSearchBar/CustomSearchBar';
import { filterObjectsByKeys } from '../../../../utils/filterObjectsByKeys';
import { Conversation } from '../../../../models/Conversation';
import { useStore } from '../../../../hooks/useStore';
import { LoadingSpinner } from '../../../../components/spinner/LoadingSpinner';
import HistoryItem from './components/HistoryItem';
import { groupObjectsByCreationDate } from '../../../../utils/groupObjectByCreatedAt';
import Divider from '../../../../components/Divider/Divider';
import TimePeriodFilter from './components/TimePeriodFilter';
import Button from '../../../../components/buttons/Button';
import WarningModal from '../../../../components/modal/WarningModal';

const HistoryTab = () => {
  const {
    conversationStore: {
      conversations,
      getAllUsersConversations,
      setEmptyConversation,
      setMessageInput,
      setSelectedPromptId,
      setSelectedPromptModel
    },
    localizationStore: { i18next: i18n },
    modelStore: { selectedModel, setSelectedModel },
  } = useStore();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [timePeriod, setTimePeriod] = useState(TimePeriods.allTime);
  const [filteredConversations, setFilteredConversations] = useState<Conversation[]>([]);
  const [isNewConversationModalOpen, setIsNewConversationModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getAllUsersConversations();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!searchValue) {
      setFilteredConversations(conversations);
      return;
    }

    const newFilteredConversations = filterObjectsByKeys(conversations, ['content', 'title'], searchValue);

    setFilteredConversations(newFilteredConversations);
  }, [searchValue, conversations]);

  const handleCreateNewConversation = async () => {
    setSelectedPromptModel(false);
    setSelectedModel(null);
    setSelectedPromptId('');
    setEmptyConversation();
    setMessageInput('');
    navigate(`${Paths.CHAT}`);
    setIsNewConversationModalOpen(false);
  };

  const memoizedHistoryItems = useMemo(() => {
    const expandedFilters = {
      [TimePeriods.allTime]: TimePeriods.allTime,
      [TimePeriods.today]: TimePeriods.today,
      [TimePeriods.last7Days]: `${TimePeriods.today}-${TimePeriods.last7Days}`,
      [TimePeriods.last30Days]: `${TimePeriods.today}-${TimePeriods.last7Days}-${TimePeriods.last30Days}`,
    };

    const groupedConversations = groupObjectsByCreationDate(filteredConversations);
    const itemsToBeRendered: any[] = [];

    Object.keys(groupedConversations).forEach(key => {
      if (timePeriod !== TimePeriods.allTime && !expandedFilters[timePeriod].includes(key)) {
        return;
      }

      const conversationsInsideGroup: Conversation[] = groupedConversations[key];

      if (Array.isArray(conversationsInsideGroup) && conversationsInsideGroup.length > 0) {
        itemsToBeRendered.push(
          <Flex sx={{ alignItems: 'center' }}>
            <Divider sx={{ flex: 1, opacity: 0.5 }} />
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '21px',
                color: GRAY_COLORS.GRAY_5,
                padding: '0 8px',
              }}
            >
              {i18n.t(`timePeriods.filter.${key}`)}
            </Typography>
            <Divider sx={{ flex: 1, opacity: 0.5 }} />
          </Flex>
        );

        conversationsInsideGroup.forEach(conversation => {
          itemsToBeRendered.push(<HistoryItem conversation={conversation} />);
        });
      }
    });

    return itemsToBeRendered;
  }, [filteredConversations, timePeriod]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Flex
      sx={{
        height: '100%',
        padding: '16px',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Flex>
        <CustomSearchBar
          onChange={setSearchValue}
          onCancelSearch={() => setSearchValue('')}
          placeholder={'Search'}
          sx={{ marginBottom: '24px', flex: 3 }}
        />
        <TimePeriodFilter timePeriod={timePeriod} setTimePeriod={setTimePeriod} id="History-Select-Filter" />
      </Flex>

      <Virtuoso data={memoizedHistoryItems} itemContent={(_index, item) => item} />

      <Box sx={{ paddingTop: '10px' }}>
        <Button onClick={() => setIsNewConversationModalOpen(true)} id="newChat">
          <Add sx={{ marginRight: '8px' }} />
          {i18n.t('conversation.newChat')}
        </Button>
      </Box>

      <WarningModal
        isOpen={isNewConversationModalOpen}
        onClose={() => setIsNewConversationModalOpen(false)}
        heading={i18n.t('conversation.newConversationModal.heading')}
        description={i18n.t('conversation.newConversationModal.description')}
        confirmButton={
          <Button
            onClick={handleCreateNewConversation}
            variant={'contained'}
            sx={{ width: 'fit-content', height: '40px !important' }}
            id="startNewChat"
          >
            {i18n.t('conversation.startNew')}
          </Button>
        }
      />
    </Flex>
  );
};

export default observer(HistoryTab);
