import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';

import { COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';
import { Paths } from '../../constants/routes';

import CustomModal from '../../components/modal/CustomModal';
import Flex from '../../components/utils/flex/Flex';
import Button from '../../components/buttons/Button';
import { useStore } from '../../hooks/useStore';
import { getTextOverflowEllipsisStyles } from '../typography/utils';
import PromptContentContainer from '../../pages/PromptsGalleryPage/components/PromptContentContainer';
import SelectWithCheckmarks from '../select/Select';
import { extractVariablesFromPromptContent } from '../../utils/extractVariablesFromPromptContent';

interface StartNewChatFromPromptModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const StartNewChatFromPromptModal = ({ isOpen, onClose }: StartNewChatFromPromptModalProps) => {
  const {
    promptsStore: { currentPrompt, setEmptyPrompt },
    conversationStore: {
      setSelectedPromptId,
      setMessageInput,
      setEmptyConversation,
      setPrimaryConversationModelSettings,
      setSelectedPromptModel,
      conversationSettingsState,
    },
    modelStore: { organizationModels },
    uiStore: { toggleIsPromptDetailsCardDisplayed, toggleIsStartNewConversationFromPromptOpen },
    localizationStore: { i18next: i18n },
  } = useStore();

  const navigate = useNavigate();

  const [variablesState, setVariablesState] = useState<any>({});
  const [promptText, setPromptText] = useState('');

  const { title } = currentPrompt;

  useEffect(() => {
    setVariablesState(extractVariablesFromPromptContent(currentPrompt.content));

    const recommendedModel = currentPrompt?.recommendedModel;
    const conversationModel = organizationModels.find(model => model.modelVersion === recommendedModel?.modelVersion);

    recommendedModel?.modelId &&
      setPrimaryConversationModelSettings({
        accuracy: recommendedModel?.temperature as number,
        length: recommendedModel?.maxTokens as number,
        // setting model from conversation settings instead of recommended model from DB
        model: recommendedModel.modelVersion || (conversationSettingsState?.model as string),
        providerName: recommendedModel?.modelProvider || (conversationSettingsState?.providerName as string),
        modelDisplayName: conversationModel?.displayName || (conversationSettingsState?.modelDisplayName as string),
      });
  }, [currentPrompt]);

  const onStartNewChat = () => {
    setSelectedPromptId(currentPrompt.id);
    setEmptyConversation(!!currentPrompt?.recommendedModel?.modelId);
    setMessageInput(promptText);

    const recommendedModel = currentPrompt?.recommendedModel;

    if (recommendedModel?.modelId) {
      const conversationModel = organizationModels.find(
        model => model.modelVersion === currentPrompt?.recommendedModel?.modelVersion
      );

      setSelectedPromptModel(true);
      setPrimaryConversationModelSettings({
        accuracy: recommendedModel?.temperature as number,
        length: recommendedModel?.maxTokens as number,
        // setting model from conversation settings instead of recommended model from DB
        model: recommendedModel.modelVersion || (conversationSettingsState?.model as string),
        providerName: recommendedModel?.modelProvider || (conversationSettingsState?.providerName as string),
        modelDisplayName: conversationModel?.displayName as string,
      });
    } else {
      const defaultModel = organizationModels.find(model => model.isDefault);

      setSelectedPromptModel(false);
      setPrimaryConversationModelSettings({
        accuracy: defaultModel?.config?.maxTemperature?.value as number,
        length: defaultModel?.config?.maxTokens?.value as number,
        model: defaultModel?.modelVersion as string,
        providerName: defaultModel?.providerName as string,
        modelDisplayName: defaultModel?.displayName as string,
      });
    }

    toggleIsPromptDetailsCardDisplayed(false);
    toggleIsStartNewConversationFromPromptOpen(false);
    setEmptyPrompt();
    localStorage.setItem('selectedItem', `${i18n.t('home.newConversation.chat')}`);
    navigate(`${Paths.CHAT}`);
  };

  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <Flex
        sx={{
          flexDirection: 'column',
          width: '600px',
          backgroundColor: COLOR_WHITE,
          overflow: 'scroll',
          maxHeight: '90vh',
        }}
      >
        <Typography
          variant={'h5'}
          sx={{ fontSize: '16px', width: `calc(100% - 40px)`, ...getTextOverflowEllipsisStyles(3) }}
        >
          {title}
        </Typography>

        <IconButton onClick={onClose} sx={{ position: 'absolute', top: '24px', right: '24px' }}>
          <CloseIcon sx={{ color: GRAY_COLORS.GRAY_6 }} />
        </IconButton>

        <PromptContentContainer
          prompt={{
            ...currentPrompt,
            recommendedModel: {
              modelId: currentPrompt?.recommendedModel?.modelId ?? '',
              modelProvider: conversationSettingsState.providerName, // Setting model from conversation setting state instead of recommended model
              displayName: conversationSettingsState.model,
              modelVersion: conversationSettingsState.model,
              maxTokens: conversationSettingsState.length,
              temperature: conversationSettingsState.accuracy,
            },
          }}
          variablesState={variablesState}
          setPromptText={setPromptText}
        />
        <Box>
          {Object.keys(variablesState).map(key => {
            let options = [];
            let label = null;
            let optionsToDisplay: string[] = [];
            const dropdownMatches = key.match(/"([^']+)"/);
            if (dropdownMatches) {
              options = JSON.parse('[' + key + ']');
              label = options[0];
              optionsToDisplay = options?.slice(1) || [];
            }

            return (
              <Box sx={{ marginTop: '12px' }}>
                {!dropdownMatches && (
                  <>
                    <Typography>{key}</Typography>

                    <CustomTextField
                      id={`variable-${key}`}
                      value={variablesState[key]}
                      name={key}
                      onChange={event => {
                        setVariablesState((prev: any) => {
                          return { ...prev, [key]: event.target.value };
                        });
                      }}
                      variant={'outlined'}
                      type={'string'}
                      placeholder={`Insert ${key}...`}
                    />
                  </>
                )}

                {label && optionsToDisplay?.length && (
                  <SelectWithCheckmarks
                    sx={{ width: '100%' }}
                    label={label}
                    value={variablesState[key] || 'Select'}
                    onChange={event =>
                      setVariablesState((prev: any) => {
                        return { ...prev, [key]: event.target.value };
                      })
                    }
                    options={optionsToDisplay?.map((opt: string) => ({ name: opt, value: opt }))}
                  />
                )}
              </Box>
            );
          })}
        </Box>

        <Flex sx={{ justifyContent: 'flex-end', marginTop: '24px' }}>
          <Button
            variant={'outlined'}
            sx={{
              height: '40px',
              width: 'fit-content',
              marginRight: '8px',
              borderColor: `${GRAY_COLORS.GRAY_2} !important`,
              color: `${GRAY_COLORS.GRAY_9} !important`,
            }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            sx={{
              width: 'fit-content',
              height: '40px',
            }}
            onClick={onStartNewChat}
            startIcon={<AddIcon />}
          >
            Start new chat
          </Button>
        </Flex>
      </Flex>
    </CustomModal>
  );
};

const CustomTextField = styled(TextField)`
  width: 100%;

  & .MuiInputBase-root {
    height: 48px;
  }
`;

export default observer(StartNewChatFromPromptModal);
