import { Prompt } from './Prompt';
import { Team } from './Team';
import { EMPTY_ORGANIZATION, Organization } from './Organization';

export interface User {
  id: string;
  fullName: string;
  email: string;
  role: string;
  profilePicture?: string;
  profilePictureUrlExpiration?: Date;
  profilePictureFileKey?: string;
  position?: string;
  conversations: Prompt[];
  organization: Organization;
  teams: Team[];
}

export interface UserWithIdAndName {
  id: string;
  fullName: string;
  profilePicture?: string;
  profilePictureUrlExpiration?: Date;
  profilePictureFileKey?: string;
  position?: string;
  email?: string;
}

export const EMPTY_USER: User = {
  conversations: [],
  email: '',
  fullName: '',
  id: '',
  organization: EMPTY_ORGANIZATION,
  position: '',
  profilePicture: '',
  role: '',
  teams: [],
};
