import React, { ChangeEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../hooks/useStore';
import TextInput from '../inputs/TextInput';
import GeneralModal from '../modal/GeneralModal';
import { getFileExtensionFromName } from '../../utils/getFileExtensionFromName';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (id: string, name: string) => void;
}
const RenameModal = ({ isOpen, onClose, onSave }: ModalProps) => {
  const {
    localizationStore: { i18next: i18n },
    knowledgeBaseStore: { selectedFile },
  } = useStore();
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (selectedFile) {
      const extension = getFileExtensionFromName(selectedFile.name);
      setFileName(selectedFile.name.split(`.${extension}`).join(''));
    }
  }, [selectedFile]);

  const onInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;

    setFileName(value);
  };

  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      onSave={() => {
        if (selectedFile) {
          onSave(selectedFile.id, fileName.concat(`.${getFileExtensionFromName(selectedFile.name)}`));
          onClose();
        }
      }}
      title={i18n.t('knowledgeBase.menu.rename')}
    >
      <TextInput
        id="rename-fileinput"
        value={fileName}
        onChange={onInputChange}
        name={'fileName'}
        variant={'outlined'}
      />
    </GeneralModal>
  );
};

export default observer(RenameModal);
