import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';

import { COLOR_PRIMARY } from '../../constants/colors';

const StyledContainer = styled.div<{ contained?: boolean }>`
  width: ${props => (props.contained ? 'auto' : '100%')};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface LoadingSpinnerProps {
  color?: string;
  width?: string;
  contained?: boolean;
}

export const LoadingSpinner = ({ color, contained, width }: LoadingSpinnerProps) => {
  return (
    <StyledContainer contained={contained}>
      <ClipLoader color={color || COLOR_PRIMARY} size={width} />
    </StyledContainer>
  );
};
