export enum SocketEvents {
  joinRoom = 'joinRoom',
  leave = 'leaveRoom',
  startedStream = 'started-stream',
  endedStream = 'ended-stream',
  messageChunkSent = 'message-chunk-sent',
  partialNewConversationCreated = 'partial-new-conversation-created',
  fileStatusChanged = 'file-status-changed',
  messageProcessed = 'message-processed',
  knowledgeBaseStatusChanged = 'knowledgeBase-status-changed',
}
