import React, { useEffect } from 'react';
import { Avatar, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react';

import Flex from '../utils/flex/Flex';
import { User, UserWithIdAndName } from '../../models/User';
import Subtitle from '../typography/Subtitle';
import { UserFromGetModel } from '../../models/UserModel';
import { useStore } from '../../hooks/useStore';

interface UserAvatarProps {
  // TODO: look into those types
  avatar?: string | null;
  displayDetails?: boolean;
  user?: User | UserFromGetModel | UserWithIdAndName;
  sx?: SxProps;
  size?: number;
}

const UserAvatar = ({ avatar, user, displayDetails, sx, size }: UserAvatarProps) => {
  const sizeStyles = { width: `${size || 32}px`, height: `${size || 32}px` };
  const {
    appState: { s3DocumentsApi },
    userStore: { updateUserProfilePictureData },
  } = useStore();

  const getInitials = (fullName: string) => {
    return fullName
      ? fullName
          .split(' ')
          .filter(word => word !== '')
          .map(word => word[0].toUpperCase())
          .join('')
          .slice(0, 2)
      : '...';
  };

  useEffect(() => {
    (async () => {
      if (
        user?.id &&
        user?.profilePictureFileKey &&
        user?.profilePictureUrlExpiration &&
        new Date(user?.profilePictureUrlExpiration).getTime() < new Date().getTime()
      ) {
        const readUrl = await s3DocumentsApi.generateAssetsReadUrl(user?.profilePictureFileKey);

        await updateUserProfilePictureData(user.id, {
          profilePicture: readUrl.signedUrl,
          profilePictureUrlExpiration: readUrl.expirationDate,
        });
      }
    })();
  }, [user]);

  return (
    <Flex sx={sx}>
      {/*// TODO: take the url from the user*/}
      {avatar || user?.profilePicture ? (
        <Avatar src={avatar || user?.profilePicture} sx={{ ...sizeStyles }} />
      ) : (
        <Flex
          sx={{
            ...sizeStyles,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            background: `radial-gradient(circle, rgba(206,207,210,1) 0%, rgba(234,241,240,1) 100%)`,
            padding: '10px',
          }}
        >
          <Typography fontSize={(size || 32) / 2.1}>{getInitials(user?.fullName || user?.email)}</Typography>
        </Flex>
      )}
      {displayDetails && (
        <Flex sx={{ flexDirection: 'column', marginLeft: '10px' }}>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '12px',
              lineHeight: '18px',
            }}
          >
            {user?.fullName || ''}
          </Typography>
          <Subtitle
            sx={{
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '18px',
            }}
          >
            {user?.position || ''}
          </Subtitle>
        </Flex>
      )}
    </Flex>
  );
};

export default observer(UserAvatar);
