import { ReactNode, useEffect, useState } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { observer } from 'mobx-react';

import AddFilesStep from './steps/AddFilesStep';
import AddInputStep from './steps/AddInputStep';
import FullHeightScreenWrapper from '../FullHeightComponentWrapper/FullHeightScreenWrapper';
import { useStore } from '../../hooks/useStore';
import { getWorkflowRunEmptyState } from './utils/getWorkflowRunEmptyState';
import SubmitStep from './steps/SubmitStep';
import { replacePlaceholders } from './utils/replacePlaceholders';
import AddStepperStep from './steps/AddStepperStep';

const stepComponents: Record<string, any> = {
  addFileStep: AddFilesStep,
  addInputStep: AddInputStep,
  submitStep: SubmitStep,
  addStepperStep: AddStepperStep,
};

const WorkflowStepsRenderer = () => {
  const {
    workFlowStore: { selectedWorkflow, setSelectedWorkflow },
    createWorkflowRunStore: { setWorkflowRunData, setWorkflowRunDataField, workflowRunData },
  } = useStore();

  const [currentStep, setCurrentStep] = useState(0);
  const [parsedWorkflow, setParsedWorkflow] = useState(selectedWorkflow);

  const { steps } = parsedWorkflow;

  const StepComponent = stepComponents[steps[currentStep].name as string];

  useEffect(() => {
    setWorkflowRunData(getWorkflowRunEmptyState(selectedWorkflow));
    setParsedWorkflow(selectedWorkflow);
  }, [selectedWorkflow.id]);

  useEffect(() => {
    if (workflowRunData) {
      const updatedWorkedFlow = replacePlaceholders(selectedWorkflow, workflowRunData, setWorkflowRunDataField);

      setSelectedWorkflow(updatedWorkedFlow);
      setParsedWorkflow(updatedWorkedFlow);
    }
  }, [currentStep]);

  return (
    <FullHeightScreenWrapper sx={{ backgroundColor: 'transparent', height: 'calc(100vh - 56px)', gap: '1rem' }}>
      <Stepper activeStep={currentStep} sx={{ marginBottom: '2rem' }}>
        {parsedWorkflow?.steps?.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: ReactNode;
          } = {};
          return (
            <Step key={label.stepConfiguration.displayName} {...stepProps}>
              <StepLabel {...labelProps}>{label.stepConfiguration.displayName}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {StepComponent && (
        <StepComponent
          step={steps[currentStep]}
          currentStepIndex={currentStep}
          setCurrentStepIndex={setCurrentStep}
          key={`step-${currentStep}`}
        />
      )}
    </FullHeightScreenWrapper>
  );
};

export default observer(WorkflowStepsRenderer);
