import React from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../hooks/useStore';
import AppTable from '../../table/AppTable';
import S3FileTableRow from './S3FileTableRow';

const S3FileExplorerEntries = () => {
  const {
    s3FileSystemStore: { s3Data },
  } = useStore();

  const tableHeaderColumnNames = [
    {
      name: 'File',
      width: 15,
    },
    {
      name: 'Last Modified',
      width: 5,
    },
    {
      name: 'Size',
      width: 2,
    },
  ];

  return (
    <AppTable
      style={{ marginTop: '24px', maxHeight: 'calc(100% - 48px - 36px)' }}
      tableHeaderColumnNames={tableHeaderColumnNames}
    >
      {s3Data?.folders.map((folder: any) => {
        return <S3FileTableRow item={folder} isFolder={true} />;
      })}

      {s3Data?.objects.map((folder: any) => {
        return <S3FileTableRow item={folder} />;
      })}
    </AppTable>
  );
};

export default observer(S3FileExplorerEntries);
