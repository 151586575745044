import React from 'react';
import { Divider as MuiDivider, SxProps } from '@mui/material';
import { GRAY_COLORS } from '../../constants/colors';

interface DividerProps {
  isVertical?: boolean;
  width?: string;
  height?: string;
  color?: string;
  sx?: SxProps;
}

const Divider = ({ isVertical = false, width = '100%', height = '1px', color, sx }: DividerProps) => {
  const dividerStyles: SxProps = {
    width: isVertical ? height : width,
    height: isVertical ? width : height,
    backgroundColor: color || GRAY_COLORS.GRAY_300,
  };

  return <MuiDivider sx={{ ...dividerStyles, ...sx }} />;
};

export default Divider;
