import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import jsPDF from 'jspdf';
import DeleteIcon from '@mui/icons-material/Delete';
import { useToasts } from 'react-toast-notifications';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { COLOR_PRIMARY, COLOR_RED, COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';
import { SIZES_SMALL } from '../../../constants/sizes';
import { Paths } from '../../../constants/routes';

import { useStore } from '../../../hooks/useStore';
import FullHeightScreenWrapper from '../../../components/FullHeightComponentWrapper/FullHeightScreenWrapper';
import Button from '../../../components/buttons/Button';
import CustomModal from '../../../components/modal/CustomModal';
import { WorkflowRunStatuses } from '../../../constants/workflows';
import { CustomSearchBar } from '../../../components/CustomSearchBar/CustomSearchBar';
import { SortOptionsContracts } from '../../../constants/sortOptions';
import { SortContractFormulas } from '../../../utils/sortContracts';
import { Workflow, WorkflowRun } from '../../../models/Workflows';
import WorkflowResultsComponent from '../../../components/WorkflowResultsComponent/WorkflowResultsComponent';
import { LoadingSpinner } from '../../../components/spinner/LoadingSpinner';

const PageContainer = styled(Box)`
  align-items: center;
  height: 100%;
  margin: 0;
  gap: 16px;
  background: ${COLOR_WHITE};
  border-radius: 8px;
  overflow: auto;
  padding: 24px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  width: 100%;

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: auto;
  }
`;

const AllWorkflowsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 8px;
  overflow: auto;
  padding: 24px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  width: 14rem;
  height: 14rem;

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: auto;
  }
`;

const DownloadOptionBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
`;

// Tooltip to show workflow details
const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .${tooltipClasses.tooltip} {
    background: ${GRAY_COLORS.GRAY_11};
    color: ${COLOR_WHITE};
    font-size: 0.875rem;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    padding: 8px;
  }
`;

// Main workflow page with all workflows and list of all workflow runs
const WorkflowPage = () => {
  const {
    localizationStore: { i18next: i18n },
    workFlowStore: {
      getWorkflowRunsByUserId,
      getWorkflowsForOrganization,
      workflowRuns,
      workflows,
      loading,
      setSelectedWorkflow,
      setSelectedWorkflowRun,
      deleteWorkflowRun,
      selectedWorkflowRun,
    },
    userStore: { userData },
  } = useStore();

  const [filteredWorkflows, setFilteredworkflows] = useState<any>([]);
  const [viewWorkflowRunResultModal, setViewWorkflowRunResultModal] = useState(false); // To open close result modal of run
  const [page, setPage] = React.useState(1);
  const [searchValue, setSearchValue] = useState(''); // Used for search functionality
  const [sortOrder, setSortOrder] = useState(SortOptionsContracts.newestFirst); // Sorting functionality
  const [copyButtonText, setCopyButtonText] = useState(i18n.t('conversation.copyChat.action')); // Copy button text

  // To handle pagination
  const itemsPerPage = 10;
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const navigate = useNavigate();
  const { addToast } = useToasts();

  // To create a workflow run
  const handleCreateWorkflow = async (workflow: Workflow) => {
    setSelectedWorkflow(workflow);
    navigate(`${Paths.WORKFLOWS}/create-workflow-run`);
  };

  // Get workflow runs for specific user to show in table
  const getWorkflowRunsByUser = async () => {
    await getWorkflowRunsByUserId(userData.id);
  };

  // To handle show workflow run result
  const handleViewWorkflowRunResult = (data: WorkflowRun) => {
    setSelectedWorkflowRun(data);

    if (data.result && data.status === WorkflowRunStatuses.DONE) {
      setSelectedWorkflowRun(data);
      setViewWorkflowRunResultModal(true);
    }
  };

  // Workflow run delete handler
  const handleDeleteWorkflowRun = async (id: string) => {
    await deleteWorkflowRun(id);
    getWorkflowRunsByUser();
    addToast(i18n.t('workFlows.deleted.successfully'), { appearance: 'success' });
  };

  // Hanlde workflow run result download in PDF format only as of now
  const handleDownloadWorkflowRunResult = async (selectedWorkflowRun: any) => {
    // Checking this because workflowRun can either have workflowRunName or name
    const fileName = selectedWorkflowRun?.variables?.workflowRunName
      ? selectedWorkflowRun?.variables?.workflowRunName
      : selectedWorkflowRun?.variables?.name;

    const element = document.getElementById('resultElement')?.querySelector('table');

    // Initiating new pdf
    const doc = new jsPDF({
      format: 'a4',
      unit: 'px',
      orientation: 'landscape',
    });

    doc.html(element as any, {
      html2canvas: {
        scale: 0.5,
        useCORS: true,
        scrollX: 0,
        scrollY: 0,
        letterRendering: true,
        logging: false,
      },
      autoPaging: 'text',
      margin: 10,

      async callback(pdf) {
        pdf.save(fileName);
      },
    });
  };

  const isHtmlTable = (string: string) => {
    const tableRegex = /<table[^>]*>([\s\S]*?)<\/table>/i;

    return tableRegex.test(string);
  };

  const htmlTableToCsv = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const table = doc.querySelector('table');

    if (!table) {
      throw new Error('No table found in the HTML string');
    }

    let csv: string[] = [];
    const rows = table.querySelectorAll('tr');

    rows.forEach(row => {
      const cols = row.querySelectorAll('td, th');
      let rowData: string[] = [];
      cols.forEach(col => {
        let cellData = col.textContent?.replace(/(\r\n|\n|\r)/gm, '').replace(/,/g, '');
        rowData.push(`"${(cellData || '').trim()}"`);
      });
      csv.push(rowData.join(','));
    });

    return csv.join('\n');
  };

  // To copy result of workflow run as store in DB
  const handleCopyResult = (data: any) => {
    let result = JSON.stringify(data?.result);

    if (isHtmlTable(result)) {
      result = htmlTableToCsv(result);
    }

    navigator.clipboard
      .writeText(result)
      .then(() => {
        setCopyButtonText('Copied!'); // Changing copy button text
        addToast(i18n.t('conversation.copyMessage.successful'), { appearance: 'success' });
        setTimeout(() => {
          // This is to set copy button text again after delay of 5 sec
          setCopyButtonText(i18n.t('conversation.copyChat.action'));
        }, 5000);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  // To reload all the workflow run to see the updated status
  const handleRefresh = () => {
    getWorkflowRunsByUser();
  };

  // To get all the available workflows for given organization
  useEffect(() => {
    if (userData?.organization?.id) {
      getWorkflowsForOrganization(userData?.organization?.id);
    }
  }, [userData?.organization?.id]);

  // To get all the workflows runs for given user (only shows self workflow runs)
  useEffect(() => {
    if (userData?.id) {
      getWorkflowRunsByUser();
    }
  }, [userData?.id]);

  // use effect to handle search
  useEffect(() => {
    if (!searchValue) {
      setFilteredworkflows(workflowRuns);
    }
    const filteredOptions = workflowRuns?.filter(
      (item: any) =>
        // Checking this because workflowRun can either have workflowRunName or name
        item?.variables?.workflowRunName?.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
        item?.variables?.name?.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
    setFilteredworkflows(filteredOptions);
  }, [searchValue, workflowRuns]);

  // use effect for sorting
  useEffect(() => {
    const sortFunction = SortContractFormulas[sortOrder];

    if (searchValue) {
      setFilteredworkflows(
        sortFunction(
          workflowRuns.filter(
            (workflow: any) =>
              workflow?.variables?.workflowRunName?.toLowerCase().includes(searchValue) ||
              workflow?.variables?.name?.toLowerCase().includes(searchValue)
          )
        )
      );
      return;
    }

    setFilteredworkflows(sortFunction(workflowRuns));
  }, [searchValue, workflowRuns, sortOrder]);
  return (
    <FullHeightScreenWrapper
      sx={{ backgroundColor: 'transparent', height: 'calc(100vh - 56px)', flexDirection: 'row' }}
    >
      <PageContainer>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Typography variant="h3" sx={{ fontSize: '22px', fontWeight: 700 }}>
              {i18n.t('sidebarMenu.apps.action')}
            </Typography>
            {/* All work flows */}
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1rem' }}>
              {workflows.map((workflow, indedx) => (
                <AllWorkflowsContainer key={indedx}>
                  <Typography variant="h6" sx={{ fontSize: '22px', fontWeight: 700 }}>
                    {i18n.t(workflow.name)}
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 700 }}>
                    {i18n.t(workflow.category)}
                  </Typography>
                  <StyledTooltip title={workflow?.configuration?.workflowDescription} arrow>
                    <Box sx={{ textAlign: 'right', background: 'transparent' }}>
                      <Button
                        sx={{
                          padding: '9px 16px !important',
                          height: '40px !important',
                          width: 'fit-content',
                          fontWeight: 400,
                          marginRight: '8px',
                        }}
                        onClick={() => handleCreateWorkflow(workflow)}
                      >
                        {i18n.t('workFlows.contractAnalysis.tryThis')}
                      </Button>
                    </Box>
                  </StyledTooltip>
                </AllWorkflowsContainer>
              ))}
            </Box>
            {/* All work flows */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '1rem',
                marginBottom: '1rem',
              }}
            >
              <IconButton onClick={() => handleRefresh()}>
                <RefreshIcon
                  fontSize={'large'}
                  sx={{ border: '1px solid ', borderRadius: '50%', padding: '2px', color: '#131F4D' }}
                ></RefreshIcon>
              </IconButton>
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <CustomSearchBar
                  sx={{ width: '100%' }}
                  onChange={setSearchValue}
                  onCancelSearch={() => setSearchValue('')}
                  placeholder={'Search by name'}
                />
                <TextField
                  sx={{ width: '100%' }}
                  id="sort-analysis"
                  value={sortOrder}
                  select
                  onChange={(event: any) => setSortOrder(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment sx={{ width: '100px', marginRight: '14px' }} position="start">
                        Sort:
                      </InputAdornment>
                    ),
                  }}
                >
                  {[
                    SortOptionsContracts.nameAtoZ,
                    SortOptionsContracts.nameZtoA,
                    SortOptionsContracts.oldestFirst,
                    SortOptionsContracts.newestFirst,
                  ]?.map((item, index) => (
                    <MenuItem value={item} key={`sort-contract-${index}`}>
                      <Typography variant={'subtitle2'}>{i18n.t(`workFlows.contractAnalysis.sort.${item}`)}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Box>
            {/* List of all workflow run */}
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Workflow</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredWorkflows
                    ?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                    .map((data: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell>
                          {data?.variables?.workflowRunName ? data?.variables?.workflowRunName : data?.variables?.name}
                        </TableCell>
                        <TableCell>{data?.workflow?.name}</TableCell>
                        <TableCell>{data?.workflow?.category}</TableCell>
                        <TableCell>{data?.user?.email}</TableCell>
                        <TableCell>{data?.status === 'IN_PROGRESS' ? 'IN PROGRESS' : data.status}</TableCell>
                        <TableCell>
                          {data?.startedAt
                            .slice(0, -5)
                            .split('T')
                            .map((part: any) => part)
                            .join(' ')}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <IconButton
                            size={'small'}
                            onClick={() => handleViewWorkflowRunResult(data)}
                            sx={{
                              color:
                                data.result && data.status === WorkflowRunStatuses.DONE
                                  ? COLOR_PRIMARY
                                  : GRAY_COLORS.GRAY_700,
                            }}
                            title="view result"
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                          <IconButton
                            size={'small'}
                            onClick={() => handleDeleteWorkflowRun(data.id)}
                            sx={{ color: COLOR_RED }}
                            title="Delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* List of all workflow run */}
            <Pagination
              sx={{ justifyContent: 'end', marginTop: '20px' }}
              count={Math.ceil(workflowRuns.length / itemsPerPage)}
              page={page}
              onChange={handleChangePage}
            />
          </>
        )}
      </PageContainer>
      {/* Workflow run result modal */}
      {viewWorkflowRunResultModal && (
        <CustomModal
          isOpen={viewWorkflowRunResultModal}
          onClose={() => {
            setViewWorkflowRunResultModal(false);
          }}
          closeOnBackdropClick
          sx={{
            width: '90%',
            height: '90%',
            flexDirection: 'column',
          }}
        >
          <DownloadOptionBox>
            <Box sx={{ textAlign: 'right', display: 'flex', gap: '16px', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ fontSize: '22px', fontWeight: 700 }}>
                {i18n.t(`${selectedWorkflowRun?.workflow?.name} result`)}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ textAlign: 'right' }}>
                <Button
                  sx={{
                    padding: '9px 16px !important',
                    height: '40px !important',
                    width: 'fit-content',
                    fontWeight: 400,
                    marginRight: '8px',
                  }}
                  variant="outlined"
                  onClick={() => handleCopyResult(selectedWorkflowRun)}
                >
                  <ContentCopyIcon sx={{ marginRight: '0.5rem' }} />
                  {copyButtonText}
                </Button>
                <Button
                  sx={{
                    padding: '9px 16px !important',
                    height: '40px !important',
                    width: 'fit-content',
                    fontWeight: 400,
                    marginRight: '8px',
                  }}
                  onClick={() => handleDownloadWorkflowRunResult(selectedWorkflowRun)}
                >
                  {i18n.t('workFlows.contractAnalysis.contractAnalysis.downloadResult')}
                </Button>
              </Box>

              <IconButton
                onClick={() => {
                  setViewWorkflowRunResultModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DownloadOptionBox>
          <WorkflowResultsComponent />
        </CustomModal>
      )}
      {/* Workflow run result modal */}
    </FullHeightScreenWrapper>
  );
};

export default observer(WorkflowPage);
