import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { GRAY_COLORS } from '../../../../constants/colors';

import UserAvatar from '../../../UserAvatar/UserAvatar';
import Flex from '../../../utils/flex/Flex';
import { User } from '../../../../models/User';

const TeamUserAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid white;
  margin-left: -8px;
  background-color: ${GRAY_COLORS.GRAY_2};
  padding: 0;
  z-index: 90;
  cursor: pointer;
`;

interface TeamUsersPicturesProps {
  users: User[];
}

const TeamUsersPictures = ({ users }: TeamUsersPicturesProps) => {
  return (
    <Flex alignItems={'center'}>
      {users?.slice(0, 5).map((user, index) => (
        <UserAvatar
          key={`user-picture-${index}`}
          user={user}
          size={24}
          sx={{ marginLeft: index === 0 ? 0 : '-8px', zIndex: 90 }}
        />
      ))}
      {users?.length > 5 && (
        <TeamUserAvatar>
          <Typography variant={'body2'} sx={{ fontSize: '8px', lineHeight: '21px', color: GRAY_COLORS.GRAY_5 }}>
            +{users.length - 5}
          </Typography>
        </TeamUserAvatar>
      )}
    </Flex>
  );
};

export default TeamUsersPictures;
