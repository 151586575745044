import { ApiConfig } from './ApiConfig';

export class WorkSpaceApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: any) {
    this.apiConfig = apiConfig;
  }
  getWorkSpaceSetting() {
    return this.apiConfig.sendRequest('GET', '/workflow-settings');
  }
}
