import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import { Typography } from '@material-ui/core';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import { useStore } from '../../../hooks/useStore';
import { COLOR_BLACK, COLOR_PRIMARY, COLOR_SUCCESS, GRAY_COLORS } from '../../../constants/colors';
import TextButton from '../../buttons/TextButton';
import { FileAndDocumentKey } from '../DragAndDropFileInput';

const CardContainer = styled(Box)`
  display: flex;
  align-items: center;

  padding: 16px;
  border-radius: 4px;
  border: 1px solid #e0e2e9;
  background-color: #fbfcff;
`;

interface FileUploadCardProps {
  file: FileAndDocumentKey;
  progress: number;
  removeFileByKey: (key: string) => void;
}

const FileUploadCard = ({ file, progress, removeFileByKey }: FileUploadCardProps) => {
  const {
    appState: { s3DocumentsApi },
    localizationStore: { i18next: i18n },
  } = useStore();

  const [loading, setLoading] = useState(false);

  const handleRemoveFile = async () => {
    if (!file.documentKey) {
      return;
    }

    try {
      setLoading(true);
      await s3DocumentsApi.deleteFile(file.documentKey);

      removeFileByKey(file.documentKey);
    } catch (e) {
      console.log('e ------------------->> ', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CardContainer>
      {progress < 100 ? (
        <UploadFileOutlinedIcon sx={{ marginRight: '16px' }} />
      ) : (
        <CheckCircleIcon sx={{ marginRight: '16px', fill: COLOR_SUCCESS, width: '24px', height: '24px' }} />
      )}
      <Stack>
        <Typography style={{ wordBreak: 'break-all', marginRight: '4px' }}>{file.file.name}</Typography>
        <Typography style={{ fontWeight: 400, color: GRAY_COLORS.GRAY_6 }}>
          {progress}% {i18n.t('patients:files:uploaded')}
        </Typography>
      </Stack>
      {progress < 100 ? (
        <CircularProgress variant={'determinate'} value={progress} sx={{ marginLeft: 'auto' }} thickness={5} />
      ) : (
        <TextButton
          sx={{ marginLeft: 'auto', textTransform: 'none', color: COLOR_BLACK }}
          startIcon={<RemoveCircleOutlineOutlinedIcon sx={{ fontSize: '14px !important', color: COLOR_PRIMARY }} />}
          onClick={handleRemoveFile}
          loading={loading}
        >
          {i18n.t('common:remove')}
        </TextButton>
      )}
    </CardContainer>
  );
};

export default FileUploadCard;
