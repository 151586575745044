import React from 'react';

import { contentTypes } from '../../constants/contentTypes';
import { COLOR_WHITE } from '../../constants/colors';
import chatIcon from '../../assets/icons/contentTypes/chatIcon.svg';
import imageIcon from '../../assets/icons/contentTypes/imageIcon.svg';

import Flex from '../utils/flex/Flex';
import { Box } from '@mui/material';

const { chat, image } = contentTypes;

const handleBackgroundColor = (contentType: string): string => {
  switch (contentType) {
    case chat:
      return '#76A9FA';
    case image:
      return '#9CA3AF';
    default:
      return '#9CA3AF';
  }
};

const handleIcon = (contentType: string): string => {
  switch (contentType) {
    case chat:
      return chatIcon;
    case image:
      return imageIcon;
    default:
      return imageIcon;
  }
};

interface ContentTypeTagProps {
  contentType: string;
}

const ContentTypeTag = ({ contentType }: ContentTypeTagProps) => {
  const backgroundColor = handleBackgroundColor(contentType);
  const icon = handleIcon(contentType);

  return (
    <Flex
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '26px',
        padding: '2px 8px',
        width: 'max-content',
        backgroundColor: backgroundColor,
        borderRadius: '4px',
        color: COLOR_WHITE,
      }}
    >
      <img src={icon} alt={`${contentType}-tag-icon`} />
      <Box sx={{ marginLeft: '8px', fontSize: '14px', lineHeight: '22px', fontWeight: '500' }}>{contentType}</Box>
    </Flex>
  );
};

export default ContentTypeTag;
