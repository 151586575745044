import autoBind from 'auto-bind';
import { makeAutoObservable, runInAction } from 'mobx';

import { TeamApi } from '../api/TeamApi';
import { CreateTeamDto, INITIAL_TEAM, Team } from '../models/Team';

export class TeamStore {
  teamApi: TeamApi;
  teams: Array<Team> = [];

  selectedTeam: Team = INITIAL_TEAM;

  constructor(teamApi: TeamApi) {
    this.teamApi = teamApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  setSelectedTeam(team: Team) {
    runInAction(() => {
      this.selectedTeam = team;
    });
  }

  clearSelectedTeam() {
    runInAction(() => {
      this.selectedTeam = INITIAL_TEAM;
    });
  }

  async getTeamsByUserOrganization() {
    const teams = await this.teamApi.getTeamsByUserOrganization();

    runInAction(() => {
      this.teams = teams;
    });

    return teams;
  }

  async createTeam(teamDto: CreateTeamDto) {
    const result = await this.teamApi.createTeam(teamDto);

    if (result?.errors) {
      return result;
    }

    await this.getTeamsByUserOrganization();
  }

  async updateTeam(id: string | undefined, updateTeamDto: Partial<CreateTeamDto>) {
    if (!id) {
      return;
    }

    const result = await this.teamApi.updateTeam(id, updateTeamDto);
    if (result?.errors) {
      return result;
    }

    runInAction(() => {
      if (this.selectedTeam.id === id) {
        this.selectedTeam = result;
      }
    });

    await this.getTeamsByUserOrganization();
  }

  async getTeamById(id: string) {
    const team = await this.teamApi.getTeamById(id);
    this.setSelectedTeam(team);
  }

  async removeTeam(id: string) {
    await this.teamApi.removeTeam(id);

    const teams = this.teams.filter(team => team.id !== id);

    runInAction(() => {
      this.teams = teams;
      this.selectedTeam = INITIAL_TEAM;
    });
  }
}
