import React from 'react';

import { FileSources } from '../../../constants/FileSources';
import FromComputer from '../../../assets/images/supportedFileSources/fromComputer.svg';
import FromS3 from '../../../assets/images/supportedFileSources/fromS3.png';
import FromGoogleDrive from '../../../assets/images/supportedFileSources/fromGoogleDrive.svg';
import FromSharePoint from '../../../assets/images/supportedFileSources/fromSharePoint.png';

type FileSourceIconProps = {
  source: string;
  size: number;
  style?: React.CSSProperties;
};

const getImageUrl = (source: string) => {
  switch (source) {
    case FileSources.computer:
      return FromComputer;
    case FileSources.S3:
      return FromS3;
    case FileSources.googleDrive:
      return FromGoogleDrive;
    case FileSources.sharePoint:
      return FromSharePoint;
    default:
      return FromComputer;
  }
};

const FileSourceIcon = ({ source, size, style }: FileSourceIconProps) => {
  return (
    <img
      src={getImageUrl(source)}
      style={{ height: `${size}px`, width: `${size}px`, maxHeight: `${size}px`, maxWidth: `${size}px`, ...style }}
      alt={source}
    />
  );
};

export default FileSourceIcon;
