import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PopoverProps, Toolbar } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { GRAY_COLORS } from '../../constants/colors';
import { useStore } from '../../hooks/useStore';
import UserAvatar from '../UserAvatar/UserAvatar';
import UserMenu from '../userMenu/UserMenu';
import Flex from '../utils/flex/Flex';
import DrawerComponent from '../Drawer/Drawer';

const drawerWidth = 250;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'white',
  color: 'black',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    min-height: 54px;
    padding: 0 32px;
  }
`;

interface NavigationContainerProps {
  children: React.ReactNode;
}

const NavigationContainer = ({ children }: NavigationContainerProps) => {
  const {
    uiStore: { toggleIsNavigationDrawerExpanded, dimensions },
    userStore: { userData },
  } = useStore();

  const theme = useTheme();

  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [anchorMenuElement, setAnchorMenuElement] = useState<PopoverProps['anchorEl']>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);

  const isSmallScreen = dimensions.small || dimensions.tinySmall || dimensions.tiny;

  useEffect(() => {
    if (isSmallScreen) {
      toggleIsNavigationDrawerExpanded(false);
    }
  }, [isSmallScreen]);

  const handleExpand = (event: { currentTarget: Element }) => {
    setIsMenuExpanded(prevState => !prevState);
    if (!isMenuExpanded) {
      setAnchorMenuElement(event.currentTarget);
    } else {
      setAnchorMenuElement(null);
    }
  };

  const handleCloseMenu = () => {
    setIsMenuExpanded(false);
    setAnchorMenuElement(null);
  };
  const handleDrawerToggle = () => {
    const newDrawerOpenState = !isDrawerOpen;
    setIsDrawerOpen(newDrawerOpenState);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <AppBar position="fixed" open={isDrawerOpen}>
        <StyledToolbar>
          <Flex sx={{ gap: '8px' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              sx={{
                marginRight: 5,
              }}
            >
              {isDrawerOpen ? theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
          </Flex>
          <IconButton size={'small'} onClick={handleExpand} disableRipple>
            <UserAvatar user={userData} sx={{ marginRight: '8px' }} />
            {isMenuExpanded ? (
              <ExpandLessIcon fontSize="small" sx={{ fill: GRAY_COLORS.GRAY_500 }} />
            ) : (
              <ExpandMoreIcon fontSize="small" sx={{ fill: GRAY_COLORS.GRAY_500 }} />
            )}
          </IconButton>
          <UserMenu anchorElement={anchorMenuElement as any} handleCloseMenu={handleCloseMenu as any} />
        </StyledToolbar>
      </AppBar>
      <DrawerComponent isDrawerOpen={isDrawerOpen} />
      <Box
        component="main"
        sx={{
          flex: 1,
          transition: 'margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
          marginTop: '56px',
          minHeight: 'calc(100vh - 56px)',
          marginLeft: isDrawerOpen ? '250px !important' : '50px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default observer(NavigationContainer);
