export const filterObjectsByKeys = (array: any, keys: string[], searchValue: string) => {
  if (!array || !keys) {
    return [];
  }

  const parseString = (str: string) => {
    return str.replace(/\s+/g, '').trim().toLowerCase();
  };

  return array.filter((obj: any) => {
    if (!obj) {
      return false;
    }

    for (let key of keys) {
      if (
        key in obj &&
        //@ts-ignore
        obj[key] &&
        //@ts-ignore
        typeof obj[key] === 'string' &&
        //@ts-ignore
        parseString(obj[key]).includes(parseString(searchValue))
      ) {
        return true;
      }
    }
    return false;
  });
};
