import { ApiConfig } from './ApiConfig';
import { IntegrationModel } from '../models/IntegrationModel';

export class IntegrationApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  async getIntegrations() {
    return await this.apiConfig.sendRequest('GET', '/integrations');
  }

  async upsertIntegrations({ integrations }: { integrations: IntegrationModel[] }) {
    return await this.apiConfig.sendRequest('POST', '/integrations', { integrations });
  }
}
