import { ApiConfig } from './ApiConfig';
import { OrganizationModel } from '../models/OrganizationModel';

export class ModelApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  async findAll(): Promise<OrganizationModel[]> {
    return await this.apiConfig.sendRequest('GET', '/organization-models');
  }

  async findAllAccessibleByUser(): Promise<OrganizationModel[]> {
    return await this.apiConfig.sendRequest('GET', '/organization-models/accessible');
  }

  async updateAll(models: Partial<OrganizationModel>[]) {
    return await this.apiConfig.sendRequest('PATCH', '/organization-models/all', { models });
  }

  async findDefaultFileModel() {
    return await this.apiConfig.sendRequest('GET', '/organization-models/default-file-model');
  }

  async findOne(id: string): Promise<OrganizationModel> {
    return await this.apiConfig.sendRequest('POST', `/organization-models/${id}`);
  }
}
