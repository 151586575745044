import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';
import { PromptsApi } from '../api/PromptsApi';
import { CreatePromptDto, INITIAL_PROMPT, Prompt, UpdatePromptDto } from '../models/Prompt';

export class PromptsStore {
  promptsApi: PromptsApi;
  organizationPrompts: Prompt[] = [];
  userPrompts: Prompt[] = [];
  userTeamsPrompts: Prompt[] = [];
  currentPrompt: Prompt = INITIAL_PROMPT;

  conversationEmptyStatePrompts: Prompt[] = [];

  constructor(promptsApi: PromptsApi) {
    this.promptsApi = promptsApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  setEmptyPrompt() {
    runInAction(() => {
      this.currentPrompt = INITIAL_PROMPT;
    });
  }

  setCurrentPrompt(prompt: Prompt) {
    runInAction(() => {
      this.currentPrompt = prompt;
    });
  }

  async getPromptsByOrganization() {
    const prompts = await this.promptsApi.getPromptsByOrganization();

    runInAction(() => {
      this.organizationPrompts = prompts;
    });
  }

  async getPromptsByUserTeams() {
    const prompts = await this.promptsApi.getPromptsByUserTeams();

    runInAction(() => {
      this.userTeamsPrompts = prompts;
    });
  }

  async getPromptsByUser() {
    const prompts = await this.promptsApi.getPromptsByUser();

    runInAction(() => {
      this.userPrompts = prompts;
    });
  }

  async getConversationEmptyStatePrompts() {
    const prompts = await this.promptsApi.getTopPrompts();
    runInAction(() => {
      this.conversationEmptyStatePrompts = prompts.slice(0, 3) || [];
    });
  }

  async getPromptById(id: string) {
    const prompt = await this.promptsApi.getPromptById(id);

    runInAction(() => {
      this.currentPrompt = prompt;
    });
  }

  async createPrompt(createPromptDto: CreatePromptDto, isOrganizationPrompt: boolean, isUserPrompt: boolean) {
    const prompt = await this.promptsApi.createPrompt(createPromptDto);

    runInAction(() => {
      if (isOrganizationPrompt) {
        this.userTeamsPrompts = [prompt, ...this.userTeamsPrompts];
      }

      if (isUserPrompt) {
        this.userPrompts = [prompt, ...this.userPrompts];
      }
    });
  }

  async updatePrompt(promptId: string, updatePromptDto: UpdatePromptDto) {
    const updatedPrompt = await this.promptsApi.update(promptId, updatePromptDto);
    const indexOfUpdatedPromptInUserTeamPrompts = this.userTeamsPrompts.findIndex(
      prompt => prompt.id === updatedPrompt.id
    );
    const indexOfUpdatedPromptInUserPrompts = this.userPrompts.findIndex(prompt => prompt.id === updatedPrompt.id);

    runInAction(() => {
      if (indexOfUpdatedPromptInUserTeamPrompts > -1) {
        const newPromptsArray = [...this.userTeamsPrompts];
        newPromptsArray[indexOfUpdatedPromptInUserTeamPrompts] = updatedPrompt;

        this.userTeamsPrompts = newPromptsArray;
      }

      if (indexOfUpdatedPromptInUserPrompts > -1) {
        const newPromptsArray = [...this.userPrompts];
        newPromptsArray[indexOfUpdatedPromptInUserPrompts] = updatedPrompt;

        this.userPrompts = newPromptsArray;
      }
    });
  }

  // TODO: make distinction between organization prompts and user prompts
  async removePrompt(id: string) {
    await this.promptsApi.remove(id);

    runInAction(() => {
      const indexOfDeletedItemInUserTeamsPrompts = this.userTeamsPrompts.findIndex(prompt => prompt.id === id);
      const indexOfDeletedItemInUserPrompts = this.userPrompts.findIndex(prompt => prompt.id === id);

      if (indexOfDeletedItemInUserTeamsPrompts > -1) {
        const newPromptsArray = [...this.userTeamsPrompts];
        newPromptsArray.splice(indexOfDeletedItemInUserTeamsPrompts, 1);

        this.userTeamsPrompts = newPromptsArray;
      }

      if (indexOfDeletedItemInUserPrompts > -1) {
        const newPromptsArray = [...this.userPrompts];
        newPromptsArray.splice(indexOfDeletedItemInUserPrompts, 1);

        this.userPrompts = newPromptsArray;
      }
    });
  }
}
