export const EmptyUser = {
  id: '',
  fullName: '',
  email: '',
  position: '',
  role: '',
  profilePicture: '',
  phoneNumber: '',
};

export const EmptyUserPassword = {
  oldPassword: '',
  newPassword: '',
  repeatNewPassword: '',
};

export const EmptyUserFromGet = {
  id: '',
  fullName: '',
  email: '',
  position: '',
  role: '',
  profilePicture: '',
  phoneNumber: '',
  teams: [],
  // organization: {},
};
