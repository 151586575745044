import { SxProps } from '@mui/material';

const getTextOverflowEllipsisStyles = (nrOfLines: number): SxProps => {
  if (nrOfLines === 1) {
    return { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'noWrap' };
  }

  return {
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    display: '-webkit-box !important',
    '-webkit-box-orient': 'vertical !important',
    '-webkit-line-clamp': `${nrOfLines} !important`,
  };
};

export { getTextOverflowEllipsisStyles };
