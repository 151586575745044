import React, { useCallback, useEffect, useState } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import ArticleIcon from '@mui/icons-material/Article';
import Close from '@mui/icons-material/Close';
import GridViewIcon from '@mui/icons-material/GridView';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';
import { Document, Page, pdfjs } from 'react-pdf';
import XLSFilePreview from '../../../../components/filePreview/XLSFilePreview';
import Button from '../../../../components/buttons/Button';

import FullHeightScreenWrapper from '../../../../components/FullHeightComponentWrapper/FullHeightScreenWrapper';

import { LoadingSpinner } from '../../../../components/spinner/LoadingSpinner';
import TabPanel from '../../../../components/tabs/TabPanel';
import Flex from '../../../../components/utils/flex/Flex';

import {
  COLOR_BORDER_PRIMARY,
  COLOR_PRIMARY,
  COLOR_TEXT_PRIMARY,
  COLOR_WHITE,
  GRAY_COLORS,
} from '../../../../constants/colors';
import { fileStatus } from '../../../../constants/fileStatus';

import { useStore } from '../../../../hooks/useStore';
import { File } from '../../../../models/File';
import BlackTooltip from '../../../../tooltips/BlackTooltip';
import { downloadDocumentSecurely } from '../../../../utils/documentsS3';
import { getFileExtensionFromName } from '../../../../utils/getFileExtensionFromName';

import { parseRedactionMapping, redactFileContent } from '../../../../utils/redactText';
import { CustomSearchBar } from '../../../../components/CustomSearchBar/CustomSearchBar';

const fileButtonStyle = {
  border: `1px solid ${GRAY_COLORS.GRAY_300} !important`,
  color: `${COLOR_PRIMARY} !important`,
  width: 'fit-content',
  padding: '9px 16px !important',
};

const DocumentWrapper = styled(Box)`
  width: 100%;

  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

const PageWrapper = styled(FullHeightScreenWrapper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${COLOR_BORDER_PRIMARY};
  padding: 0;
`;

export const FileContentToRender = (
  activeTab: number,
  setNumberOfPages: any,
  pageNumber: number,
  fileExtension: string,
  fileContent: string[],
  pdfFilePreview: string,
  fileUri: string,
  scale: number,
  file?: File | undefined,
  zoomIn?: () => void,
  zoomOut?: () => void,
  i18n?: any
) => {
  const docs = [{ uri: fileUri, fileType: fileExtension }];
  const [searchText, setSearchText] = useState('');

  const highlightPattern = (text: string, pattern: string) => {
    return text.replace(new RegExp(pattern, 'gi'), (value: string) => `<mark>${value}</mark>`);
  };
  const textRenderer = useCallback(
    (textItem: { str: string }) => highlightPattern(textItem.str, searchText),
    [searchText]
  );
  return (
    <DocumentWrapper sx={{ '& > *': DocViewerStyles, height: '100%' }}>
      {activeTab === 0 && file?.name && getFileExtensionFromName(file?.name) === 'pdf' ? (
        <Document
          file={pdfFilePreview}
          onLoadSuccess={(obj: any) => {
            setNumberOfPages(obj?._pdfInfo?.numPages || 1);
          }}
        >
          <div style={{ display: 'flex', gap: '1rem', padding: '1rem' }}>
            <CustomSearchBar
              onCancelSearch={() => setSearchText('')}
              onChange={setSearchText}
              sx={{ flex: 6, marginRight: '20px' }}
              placeholder={i18n.t('common.search.message')}
            />

            <Button onClick={() => zoomOut?.()} variant={'outlined'} sx={fileButtonStyle}>
              -
            </Button>
            <Button onClick={() => zoomIn?.()} variant={'outlined'} sx={fileButtonStyle}>
              +
            </Button>
          </div>

          <div style={{ overflow: 'auto' }}>
            <Page
              renderMode={'canvas'}
              customTextRenderer={textRenderer}
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              scale={scale}
              loading={
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <LoadingSpinner />
                </Box>
              }
            />
          </div>
        </Document>
      ) : fileExtension === 'csv' ? (
        <XLSFilePreview fileContent={fileContent} />
      ) : activeTab === 0 ? (
        <DocViewer
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
            },
            csvDelimiter: ',',
            pdfZoom: {
              defaultZoom: 1,
              zoomJump: 0.2,
            },
          }}
          prefetchMethod="GET"
          documents={docs}
          initialActiveDocument={docs[0]}
          pluginRenderers={DocViewerRenderers}
        />
      ) : (
        <Box sx={{ padding: '16px' }}>
          <pre
            style={{
              fontFamily: 'Poppins',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20px',
              color: COLOR_TEXT_PRIMARY,
              whiteSpace: 'pre-wrap',
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: fileContent[pageNumber - 1],
              }}
            />
          </pre>
        </Box>
      )}
    </DocumentWrapper>
  );
};

interface FilePreviewProps {
  file: File;
  isGridViewIconDisabled?: boolean;
  redactedViewDisabled?: boolean;
}

const FilePreview = ({ file, isGridViewIconDisabled = false, redactedViewDisabled }: FilePreviewProps) => {
  const {
    localizationStore: { i18next: i18n },
    appState: { s3DocumentsApi },
    conversationStore: { clearPreviewFile, showFilePreview, toggleShowFilePreview, previewFileInitialPage },
  } = useStore();

  pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

  const [pdfFilePreview, setPdfFilePreview] = useState('');
  const [numberOfPages, setNumberOfPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(previewFileInitialPage || 1);
  const [loading, setLoading] = useState(false);
  const [fileContent, setFileContent] = useState<string[]>([]);
  const [fileExtension, setFileExtension] = useState('');
  const [fileUri, setFileUri] = useState('');

  const [activeTab, setActiveTab] = useState(0);

  const downloadFile = async (preSignedUrl: { signedUrl: string; expirationDate: Date }) => {
    const blob = await downloadDocumentSecurely(preSignedUrl.signedUrl);
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64String = reader.result as string;
      const pdfBase64 = `data:application/pdf;base64,${base64String.slice(base64String.indexOf(',') + 1)}`;
      setPdfFilePreview(pdfBase64);
    };
  };

  useEffect(() => {
    if (!file.id || !showFilePreview) {
      return;
    }

    (async () => {
      setNumberOfPages(null);
      setPageNumber(previewFileInitialPage);
      setPdfFilePreview('');

      const preSignedUrl = await s3DocumentsApi.generateDocumentsReadUrl(file.key);
      setFileUri(preSignedUrl.signedUrl);

      try {
        setLoading(true);

        if (file.content) {
          try {
            const redactionMapping = parseRedactionMapping(file.redactionMapping);
            const redactedContent = redactFileContent(file.content, redactionMapping || []);
            const contentToDisplay = activeTab === 0 ? file.content : redactedContent;

            let pageContentContainsLineContent = false;
            const fileExt = getFileExtensionFromName(file.name);
            setFileExtension(fileExt);

            let pageContentArray = [];
            //for xls and xlsx files we use the text as html property to display the content
            if (['xls', 'xlsx', 'csv'].includes(fileExt)) {
              pageContentArray = JSON.parse(contentToDisplay).map((fileData: any) => fileData.metadata.text_as_html);
            } else {
              // for the rest of the files we use page content
              pageContentArray = pageContentArray = JSON.parse(contentToDisplay).map((fileData: any) => {
                if (
                  fileExt === 'json' ||
                  (fileData.metadata.filetype && fileData.metadata.filetype === 'text/markdown')
                ) {
                  pageContentContainsLineContent = true;
                }

                return fileData.pageContent;
              });
            }

            const contentArray = pageContentContainsLineContent ? [pageContentArray.join('\n')] : pageContentArray;

            setFileContent(contentArray);
            setNumberOfPages(contentArray.length);
          } catch (e) {
            console.log(`ERROR parsing file ${file.name}'s content ------------------->> `, e);
          }
        }

        const fileExt = getFileExtensionFromName(file.name);

        if (fileExt === 'pdf') {
          await downloadFile(preSignedUrl);
        }
      } catch (e) {
        console.log('e ------------------->> ', e);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      setFileContent([]);
    };
  }, [file.id, showFilePreview, activeTab]);

  useEffect(() => {
    setPageNumber(previewFileInitialPage);
  }, [previewFileInitialPage]);

  const [scale, setScale] = useState(1.0);
  if (!showFilePreview) {
    return <></>;
  }

  // Function to zoom in (increase scale)
  const zoomIn = () => {
    setScale(prevScale => prevScale + 0.25);
  };

  // Function to zoom out (decrease scale)
  const zoomOut = () => {
    setScale(prevScale => Math.max(0.5, prevScale - 0.25));
  };

  const tabs = [
    {
      title: 'conversation.filePreview.original',
      component: FileContentToRender(
        activeTab,
        setNumberOfPages,
        pageNumber,
        fileExtension,
        fileContent,
        pdfFilePreview,
        fileUri,
        scale,
        file,
        zoomIn,
        zoomOut,
        i18n
      ),
    },
  ];

  if (!file.skipRedaction && !redactedViewDisabled) {
    tabs.push({
      title: 'conversation.filePreview.redacted',
      component: FileContentToRender(
        activeTab,
        setNumberOfPages,
        pageNumber,
        fileExtension,
        fileContent,
        pdfFilePreview,
        fileUri,
        scale,
        file,
        zoomIn,
        zoomOut,
        i18n
      ),
    });
  }

  return (
    <PageWrapper>
      <Stack
        sx={{
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          width: '100%',
          zIndex: 500,
          padding: '16px 16px 0px 16px',
          backgroundColor: COLOR_WHITE,
        }}
      >
        <Flex sx={{ alignItems: 'center', gap: '16px', width: '100%', backgroundColor: COLOR_WHITE }}>
          {!isGridViewIconDisabled && (
            <BlackTooltip placement={'bottom'} title={i18n.t('conversation.files.title')} arrow>
              <div>
                <IconButton onClick={clearPreviewFile}>
                  <GridViewIcon sx={{ fill: GRAY_COLORS.GRAY_5 }} />
                </IconButton>
              </div>
            </BlackTooltip>
          )}
          <Divider orientation={'vertical'} flexItem />
          <ArticleIcon sx={{ fill: COLOR_PRIMARY }} />
          <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_9, fontWeight: 500 }}>
            {file?.name}
          </Typography>
          <IconButton
            size={'small'}
            sx={{ marginLeft: 'auto' }}
            onClick={() => {
              toggleShowFilePreview();
              setActiveTab(0);
            }}
          >
            <Close fontSize={'small'} />
          </IconButton>
        </Flex>
        {!redactedViewDisabled && (
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            variant="fullWidth"
            sx={{
              border: 'none',
              width: '100%',
              borderRadius: '8px',
              backgroundColor: GRAY_COLORS.GRAY_1,
              padding: '4px',
              minHeight: '32px',
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: COLOR_WHITE,
                height: '100%',
                borderRadius: '6px',
                zIndex: 100,
              },
            }}
          >
            {tabs.map((tab: { title: string; component: React.ReactNode }, index: number) => (
              <Tab
                key={`home-page-tab-${index}`}
                label={i18n.t(tab.title)}
                id={`home-page-tab-${index}`}
                aria-controls={`home-page-tab-panel-${index}`}
                disabled={index === 1 && file?.status !== fileStatus.DONE}
                sx={{
                  color: GRAY_COLORS.GRAY_7,
                  fontWeight: 400,
                  zIndex: 200,
                  minHeight: 'unset',
                  padding: '4px',
                  lineHeight: '20px',

                  '&.Mui-selected': {
                    color: COLOR_PRIMARY,
                    fontWeight: 500,
                  },
                }}
              />
            ))}
          </Tabs>
        )}
      </Stack>
      {loading ? (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LoadingSpinner />
        </Box>
      ) : (
        tabs.map((tab: { title: string; component: React.ReactNode }, index: number) => (
          <TabPanel key={`tab_${index}`} value={activeTab} index={index} sx={{ flex: 1 }}>
            {tab.component}
          </TabPanel>
        ))
      )}

      {!['csv', 'xls', 'xlsx'].includes(fileExtension) && (
        <Flex
          sx={{
            backgroundColor: COLOR_WHITE,
            width: '100%',
            justifyContent: 'center',
            padding: '16px 0',
            position: 'sticky',
            bottom: 0,
            marginTop: 'auto',
            zIndex: '1000',
          }}
        >
          <Pagination
            count={numberOfPages || 1}
            page={pageNumber}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => setPageNumber(value)}
            size="small"
          />
        </Flex>
      )}
    </PageWrapper>
  );
};

const DocViewerStyles = {
  '& > #proxy-renderer > #pdf-renderer > #pdf-controls': {
    backgroundColor: `${COLOR_WHITE} !important`,
  },
};

export default observer(FilePreview);
