import { ChangeEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react';

import { NumberInputField } from '../../../models/Steps/Fields';
import { useStore } from '../../../hooks/useStore';

type NumberInputProps = {
  field: NumberInputField;
};

// Used for number inputs in workflows
const WorkflowStepNumberInput = ({
  field: { name: fieldName, label, value = '', reset = false },
}: NumberInputProps) => {
  const {
    createWorkflowRunStore: { setWorkflowRunDataField },
  } = useStore();

  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    setFieldValue(value);
  }, [reset]);

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setFieldValue(newValue);
    setWorkflowRunDataField(fieldName, newValue);
  };

  return (
    <div>
      <TextField
        type="number"
        id={fieldName}
        label={label}
        value={fieldValue}
        onChange={handleChange}
        placeholder={`Enter ${label}...`}
        variant="outlined"
        fullWidth
        margin="normal"
      />
    </div>
  );
};

export default observer(WorkflowStepNumberInput);
