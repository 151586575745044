import React from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemText, makeStyles, ListItemIcon, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  listItem: {
    width: '95%',
    margin: '4px 4px',
    '&:hover': {
      backgroundColor: '#2857C0',
      borderRadius: '6px',
      margin: '4px 4px',
    },
  },
  selectedItem: {
    backgroundColor: '#1E429F',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#1E429F',
    },
    margin: '4px 4px',
  },
  iconImage: {
    height: '17px',
  },
});

interface TabItemProps {
  text: {
    title: string;
    path: string;
    logo: string;
  };
  isSelected: boolean;
  isDrawerOpen: boolean;
  handleItemClick: (title: string) => void;
}

const NavItems: React.FC<TabItemProps> = ({ text, isSelected, isDrawerOpen, handleItemClick }) => {
  const classes = useStyles();
  const isStatic = ['Agents', 'Feedback', 'Help Center'];
  const handleItemClickInternal = () => {
    handleItemClick(text.title);
  };

  return (
    <ListItem
      key={text.title}
      className={`${classes.listItem} ${isSelected ? classes.selectedItem : ''}`}
      onClick={handleItemClickInternal}
      component={Link}
      to={text.path}
    >
      <ListItemIcon style={{ minWidth: 0, marginRight: isDrawerOpen ? 16 : 'auto', justifyContent: 'center' }}>
        <img src={text.logo} alt={text.title} className={classes.iconImage} />
      </ListItemIcon>
      {isDrawerOpen && (
        <ListItemText
          primary={
            <Typography
              variant="body1"
              style={{
                color: isStatic.includes(text.title) ? 'gray' : 'white',
              }}
            >
              {text.title}
            </Typography>
          }
          style={{ opacity: isDrawerOpen ? 1 : 0 }}
        />
      )}
    </ListItem>
  );
};

export default NavItems;
