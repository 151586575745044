import { SyntheticEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import { Autocomplete, Checkbox } from '@mui/material';
import { observer } from 'mobx-react';

import { SelectInputField } from '../../../models/Steps/Fields';
import { useStore } from '../../../hooks/useStore';

type SelectInputProps = {
  field: SelectInputField;
};

// Used for dropdown in workflows
const WorkflowStepSelectInput = ({
  field: { name: fieldName, isMultiple, options, disableCloseOnSelect, value = [], reset = false },
}: SelectInputProps) => {
  const {
    createWorkflowRunStore: { setWorkflowRunDataField },
  } = useStore();

  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    setFieldValue(value);
  }, [reset]);

  const handleChange = (event: SyntheticEvent<Element, Event>, newValues: any) => {
    setFieldValue(newValues);
    setWorkflowRunDataField(fieldName, newValues);
  };

  return (
    <div>
      <Autocomplete
        id={fieldName}
        multiple={isMultiple}
        disableCloseOnSelect={disableCloseOnSelect}
        options={options}
        onChange={handleChange}
        value={fieldValue}
        fullWidth
        renderInput={({ inputProps, ...rest }) => {
          return <AutoCompleteTextField {...rest} inputProps={{ ...inputProps }} placeholder={'select query'} />;
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            {isMultiple && <Checkbox style={{ marginRight: 8 }} checked={selected} />}
            {option as any}
          </li>
        )}
      />
    </div>
  );
};

export default observer(WorkflowStepSelectInput);

const AutoCompleteTextField = styled(TextField)`
  & .MuiInputBase-root {
    height: unset;
  }
  & .MuiInputLabel-root {
    top: 0
  },
  & .MuiInputLabel-shrink {
    top: 0
  },
`;
