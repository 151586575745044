import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@material-ui/core/Box/Box';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useToasts } from 'react-toast-notifications';
import { observer } from 'mobx-react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Tooltip } from '@mui/material';

import { COLOR_BLACK } from '../../constants/colors';

import GenericCard from './GenericCard';
import Flex from '../utils/flex/Flex';
import { Prompt } from '../../models/Prompt';
import TeamTag, { handleTeamColors } from '../teamTag/TeamTag';
import { getTextOverflowEllipsisStyles } from '../typography/utils';
import { useStore } from '../../hooks/useStore';
import { OptionsButton, OptionsRowButton } from '../buttons/OptionsButton';
import FlexRowSpaceBetween from '../utils/flex/FlexRowSpaceBetween';
import { extractVariablesFromPromptContent } from '../../utils/extractVariablesFromPromptContent';
import isEmpty from 'lodash/isEmpty';

interface PromptsGalleryCardProps {
  prompt: Prompt;
  setIsAddPromptModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeletePromptModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDisplayedInDrawerMode?: boolean;
}

const PromptsGalleryCard = ({
  prompt,
  setIsAddPromptModalOpen,
  setIsDeletePromptModalOpen,
  setIsEditMode,
  isDisplayedInDrawerMode,
}: PromptsGalleryCardProps) => {
  const {
    promptsStore: { setCurrentPrompt },
    localizationStore: { i18next: i18n },
    uiStore: {
      toggleIsPromptDetailsCardDisplayed,
      toggleIsStartNewConversationFromPromptOpen,
      toggleIsFillPromptVariablesModalOpen,
    },
    modelStore: { organizationModels },
    conversationStore: { setMessageInput },
  } = useStore();

  const { addToast } = useToasts();

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(prompt.content);
      addToast(i18n.t('conversation.copyMessage.successful'), { appearance: 'success' });
    } catch (e) {
      addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
    }
  };

  const recommendedModel =
    prompt?.recommendedModel?.modelId &&
    organizationModels?.find(item => item.id === prompt?.recommendedModel?.modelId);
  const isModelDisabled = !!(prompt?.recommendedModel?.modelId && recommendedModel && !recommendedModel.isActive);

  const options: OptionsRowButton[] = [
    {
      optionName: 'Start new chat',
      optionLogo: <AddIcon key={`${prompt.id}-add-icon`} />,
      optionOnClick: (item: any) => {
        setCurrentPrompt(item);
        toggleIsStartNewConversationFromPromptOpen(true);
      },
      disabled: isModelDisabled,
    },
    {
      optionName: 'Copy to clipboard',
      optionLogo: <ContentCopyIcon key={`${prompt.id}-content-copy-icon`} />,
      optionOnClick: async (item: any) => {
        await handleCopyToClipboard();
      },
    },
    {
      optionName: 'Edit',
      optionLogo: <EditOutlinedIcon key={`${prompt.id}-edit-icon`} />,
      optionOnClick: (item: any) => {
        setCurrentPrompt(item);
        setIsEditMode(true);
        setIsAddPromptModalOpen(true);
      },
    },
    {
      optionName: 'Delete',
      optionLogo: <DeleteOutlinedIcon key={`${prompt.id}-delete-outlined-icon`} />,
      optionOnClick: (item: any) => {
        setCurrentPrompt(item);
        setIsDeletePromptModalOpen(true);
      },
    },
  ];

  const renderTeamTags = () => {
    if (!prompt?.teams?.length) {
      return (
        <Box sx={{ marginBottom: '12px' }}>
          <TeamTag team={'Private'} />
        </Box>
      );
    }

    const { backgroundColor, textColor } = handleTeamColors(prompt.teams[0].name);

    return (
      <Flex
        sx={{
          alignItems: 'center',
          marginBottom: '12px',
        }}
      >
        <TeamTag team={prompt.teams[0].name} sx={{ marginRight: '6px' }} />
        {prompt.teams.length > 1 && (
          <Tooltip
            title={prompt.teams.map(team => team.name).join(', ')}
            PopperProps={{
              sx: {
                '& > .MuiTooltip-tooltip': {
                  padding: '8px !important',
                  backgroundColor: `${COLOR_BLACK} !important`,
                  border: 'none !important',
                },
                '.MuiTooltip-arrow::before': {
                  backgroundColor: `${COLOR_BLACK} !important`,
                  border: 'none !important',
                },
              },
            }}
            arrow
          >
            <Box>
              <Flex sx={{ backgroundColor, alignItems: 'center', padding: '6px 8px 6px 4px', borderRadius: '4px' }}>
                <AddIcon sx={{ color: textColor, height: '16px' }} />
                <Typography
                  sx={{
                    color: textColor,
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '16px',
                    ...getTextOverflowEllipsisStyles(1),
                  }}
                >
                  {prompt.teams.length - 1}
                </Typography>
              </Flex>
            </Box>
          </Tooltip>
        )}
      </Flex>
    );
  };

  return (
    <GenericCard sx={{ cursor: 'pointer', boxShadow: 'none' }} key={`${prompt.id}-prompt-card`}>
      <Flex
        sx={{ flexDirection: 'column', height: '100%' }}
        onClick={() => {
          if (isDisplayedInDrawerMode) {
            setCurrentPrompt(prompt);

            if (!isEmpty(extractVariablesFromPromptContent(prompt.content))) {
              toggleIsFillPromptVariablesModalOpen(true);
              return;
            }

            setMessageInput(prompt.content);
          }

          toggleIsPromptDetailsCardDisplayed(true);
          setCurrentPrompt(prompt);
        }}
      >
        <FlexRowSpaceBetween>
          {renderTeamTags()}
          {!isDisplayedInDrawerMode && (
            <OptionsButton item={prompt} options={options} isIcon sx={{ width: 'fit-content' }} />
          )}
        </FlexRowSpaceBetween>

        <Typography sx={{ fontWeight: 600, fontSize: '16px', ...getTextOverflowEllipsisStyles(2) }}>
          {prompt.title}
        </Typography>
        <Typography
          sx={{
            marginTop: '2px',
            fontWeight: '400',
            fontSize: '14px',
            ...getTextOverflowEllipsisStyles(3),
          }}
        >
          {prompt.content}
        </Typography>
      </Flex>
    </GenericCard>
  );
};

export default observer(PromptsGalleryCard);
