import styled from 'styled-components';
import Box from '@mui/material/Box';
import { COLOR_WHITE } from '../../../../constants/colors';
import { SIZES_SMALL } from '../../../../constants/sizes';

export const ResultDetailsContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;
  background: #f3f3f3;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);
`;

export const StyledSpan = styled.span`
  font-weight: 500;
`;
