import styled from 'styled-components';

const TableContentContainer = styled.div`
  width: 100%;
  overflow: auto;

  table {
    width: 100%;
    overflow-x: auto;
    border: none !important;
    border-spacing: 0;

    tr {
      text-align: center !important;
    }
  }

  th {
    font-weight: 700;
  }

  td {
    font-weight: 400;
  }

  th,
  td {
    font-family: Poppins;
    font-style: normal;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    line-height: 20px;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: center;
    padding: 16px;
    background-color: #ffffff;
  }
`;

interface XLSFilePreviewProps {
  fileContent: string[];
}
const XLSFilePreview = ({ fileContent }: XLSFilePreviewProps) => {
  return (
    <TableContentContainer
      dangerouslySetInnerHTML={{
        __html: fileContent.length > 0 ? fileContent[0].replace('border="1"', '') : '',
      }}
    />
  );
};

export default XLSFilePreview;
