import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

import { GRAY_COLORS } from '../../../../constants/colors';

import { File } from '../../../../models/File';
import Flex from '../../../../components/utils/flex/Flex';
import { useStore } from '../../../../hooks/useStore';
import { getIconByName, TypeIcon } from '../../../../components/knowledgeBase/utils';
import { getTextOverflowEllipsisStyles } from '../../../../components/typography/utils';

const FileEntryContainer = styled(Stack)`
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${GRAY_COLORS.GRAY_2};
  cursor: pointer;

  &:hover {
    background: ${GRAY_COLORS.GRAY_1};
  }
`;

interface ConversationFileCardProps {
  file: File;
}

const ConversationFileCard = ({ file }: ConversationFileCardProps) => {
  const {
    conversationStore: { setPreviewFile },
  } = useStore();

  return (
    <FileEntryContainer
      onClick={() => {
        setPreviewFile(file);
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <TypeIcon src={getIconByName(file.name)} alt={file.name} />
        <Typography variant={'body2'} sx={getTextOverflowEllipsisStyles(1)}>
          {file.name}
        </Typography>
      </Flex>
    </FileEntryContainer>
  );
};

export default ConversationFileCard;
