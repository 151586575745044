import React from 'react';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';

import { GRAY_COLORS } from '../../../../../constants/colors';

import { File } from '../../../../../models/File';
import Flex from '../../../../../components/utils/flex/Flex';
import { useStore } from '../../../../../hooks/useStore';
import Stack from '@mui/material/Stack/Stack';

interface ShowSourceDocumentsComponentProps {
  sourceDocuments: string[];
  files: File[];
}
const ShowSourceDocumentsComponent = ({ sourceDocuments, files }: ShowSourceDocumentsComponentProps) => {
  const {
    conversationStore: { setPreviewFile },
  } = useStore();

  return (
    <Stack sx={{ marginTop: '16px', gap: '8px' }}>
      <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_6 }}>
        Source Documents:
      </Typography>

      {sourceDocuments.map(document => {
        const fileIndex = files.findIndex(fileEntry => fileEntry.id === document || fileEntry.key.includes(document));

        if (fileIndex < 0) {
          return <></>;
        }

        return (
          <Typography
            variant={'body2'}
            sx={{
              color: GRAY_COLORS.GRAY_6,
              cursor: 'pointer',
              textDecoration: 'underline',
              '&:hover': { color: GRAY_COLORS.GRAY_9 },
            }}
            onClick={() => {
              setPreviewFile(files[fileIndex]);
            }}
          >
            {files[fileIndex].name}
          </Typography>
        );
      })}
    </Stack>
  );
};

export default observer(ShowSourceDocumentsComponent);
