import { observer } from 'mobx-react';
import Box from '@mui/material/Box';

import S3FileSystemNavigation from './components/S3FileSystemNavigation';
import S3FileExplorerEntries from './components/S3FileExplorerEntries';
import { useStore } from '../../hooks/useStore';
import { useEffect } from 'react';
import Flex from '../utils/flex/Flex';
import Typography from '@mui/material/Typography';
import { GRAY_COLORS } from '../../constants/colors';

const S3FileExplorer = () => {
  const {
    s3FileSystemStore: { getS3Data, prefix, bucketName, s3FilesToAddToKnowledgeBase },
  } = useStore();

  useEffect(() => {
    (async () => {
      await getS3Data(bucketName, prefix);
    })();
  }, [prefix]);

  return (
    <>
      <Box
        sx={{
          marginTop: '12px',
          height: '400px',
        }}
      >
        <S3FileSystemNavigation />
        <S3FileExplorerEntries />
        {!!s3FilesToAddToKnowledgeBase.length && (
          <Flex sx={{ marginTop: '12px', justifyContent: 'flex-end' }}>
            <Typography sx={{ color: GRAY_COLORS.GRAY_700 }}>
              {s3FilesToAddToKnowledgeBase.length} Files Added
            </Typography>
          </Flex>
        )}
      </Box>
    </>
  );
};

export default observer(S3FileExplorer);
