import styled from 'styled-components';

const Headline1 = styled.header`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`;

const Headline2 = styled.header`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
`;

const Headline3 = styled.header<{ margin?: string }>`
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  margin: ${props => props.margin};
`;

const Headline4 = styled.header`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

const Headline5 = styled.header`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

export { Headline1, Headline2, Headline3, Headline4, Headline5 };
