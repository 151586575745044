import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { COLOR_WHITE } from '../../constants/colors';

export const PromptsGalleryContentContainer = styled(Box)`
  height: 100%;
  overflow: hidden;
  background-color: ${COLOR_WHITE};
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);
`;
