import CustomModal from './CustomModal';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import Typography from '@mui/material/Typography';

import WarningIcon from '../../assets/icons/warning.svg';

import Flex from '../utils/flex/Flex';
import PrimaryOutlinedButton from '../buttons/PrimaryOutlinedButton';

interface WarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  heading: string;
  description: string;
  confirmButton: ReactNode;
  icon?: ReactNode;
}

const WarningModal = ({ isOpen, onClose, heading, description, confirmButton, icon }: WarningModalProps) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose} sx={{ maxWidth: '500px' }}>
      <Stack sx={{ gap: '16px' }}>
        <Flex sx={{ gap: '8px', alignItems: 'center' }}>
          {icon || <img src={WarningIcon} alt={'WarningIcon'} style={{ height: 24, width: 24 }} />}
          <Typography variant={'body1'} sx={{ fontWeight: 500 }}>
            {heading}
          </Typography>
        </Flex>
        <Typography variant={'body2'}>{description}</Typography>
        <Flex sx={{ gap: '16px', justifyContent: 'flex-end', width: '100%' }}>
          <PrimaryOutlinedButton onClick={onClose}>No Thanks</PrimaryOutlinedButton>
          {confirmButton}
        </Flex>
      </Stack>
    </CustomModal>
  );
};

export default WarningModal;
