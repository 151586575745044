import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router';
import { useToasts } from 'react-toast-notifications';

import { ConversationTypes } from '../../../constants/conversationTypes';
import { Paths } from '../../../constants/routes';

import Flex from '../../../components/utils/flex/Flex';
import { Headline1 } from '../../../components/typography/Headlines';
import Button from '../../../components/buttons/Button';
import { CustomSearchBar } from '../../../components/CustomSearchBar/CustomSearchBar';
import SelectWithCheckmarks from '../../../components/select/Select';
import { DropdownOption } from '../../../models/DropdownOption';
import { CreateConversationDto, DEFAULT_SETTINGS } from '../../../models/Conversation';
import { useStore } from '../../../hooks/useStore';

const ContentOptions: DropdownOption[] = [
  {
    name: 'chat',
    value: 'chat',
  },
];

interface HistoryPageHeaderProps {
  setSearchValue: any;
}

const HistoryPageHeader = ({ setSearchValue }: HistoryPageHeaderProps) => {
  const [contentType, setContentType] = useState('chat');

  const {
    conversationStore: { createConversation },
    localizationStore: { i18next: i18n },
  } = useStore();

  const navigate = useNavigate();
  const { addToast } = useToasts();

  const handleCreateNewConversation = async () => {
    try {
      const conversationDto: CreateConversationDto = {
        type: ConversationTypes.Chat,
        model: DEFAULT_SETTINGS.model,
        temperature: DEFAULT_SETTINGS.accuracy,
        maxTokens: DEFAULT_SETTINGS.length,
      };

      const conversation = await createConversation(conversationDto);
      if (conversation) {
      navigate(`${Paths.CONVERSATION}/${conversation.id}`);
      }
    } catch (e) {
      console.log('e ------------------->> ', e);
      addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
    }
  };

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Flex sx={{ justifyContent: 'space-between', width: '100%' }}>
        <Headline1>History</Headline1>
        <Box>
          <Button onClick={handleCreateNewConversation}>New Conversation</Button>
        </Box>
      </Flex>
      <Flex sx={{ marginTop: '28px' }}>
        <CustomSearchBar
          onChange={setSearchValue}
          sx={{ flex: 5, marginRight: '20px' }}
          onCancelSearch={() => setSearchValue('')}
        />
        <SelectWithCheckmarks
          sx={{ flex: 1, minWidth: '100px' }}
          label={'Content Type'}
          value={contentType}
          onChange={event => setContentType(event.target.value)}
          options={ContentOptions}
        />
      </Flex>
    </Flex>
  );
};

export default HistoryPageHeader;
