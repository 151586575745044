import React from 'react';
import styled from 'styled-components';
import { ToastProps } from 'react-toast-notifications';
import { Divider } from '@mui/material';

import ErrorIcon from '../../assets/icons/toasts/alert.svg';
import WarningIcon from '../../assets/icons/toasts/warning.svg';
import SuccessIcon from '../../assets/icons/toasts/success.svg';
import InfoIcon from '../../assets/icons/toasts/info.svg';

import { COLOR_WHITE, GRAY_COLORS, TOAST_COLORS } from '../../constants/colors';

import { useStore } from '../../hooks/useStore';

const StyledContainer = styled.div<{
  backgroundColor: string;
  color: string;
  fromRight?: boolean;
  isNavigationDrawerExpanded?: boolean;
}>`
  height: 44px;
  border-radius: 6px;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px;
  gap: 8px;
  animation: slideAnimation 0.2s ease-in;
  margin: ${props => (props.isNavigationDrawerExpanded ? '0 0 16px 240px' : '0 0 16px 70px')};
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);

  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 22px;

  @keyframes slideAnimation {
    0% {
      transform: ${props => (props.fromRight ? 'translateX(320px)' : 'translateX(-320px)')};
    }
    100% {
      transform: translateX(0);
    }
  }
`;

const getBackgroundColorAndIcon = (appearance: 'error' | 'info' | 'success' | 'warning') => {
  switch (appearance) {
    case 'error':
      return [TOAST_COLORS.ERROR, ErrorIcon];
    case 'warning':
      return [TOAST_COLORS.WARNING, WarningIcon];
    case 'info':
      return [TOAST_COLORS.INFO, InfoIcon];
    case 'success':
      return [TOAST_COLORS.SUCCESS, SuccessIcon];
    default:
      return [TOAST_COLORS.INFO, InfoIcon];
  }
};

export const Notification = (props: ToastProps) => {
  const {
    uiStore: { toastPlacement, isNavigationDrawerExpanded },
  } = useStore();
  const { children, appearance } = props;

  const [backgroundColor, icon] = getBackgroundColorAndIcon(appearance);

  return (
    <StyledContainer
      fromRight={toastPlacement.includes('right')}
      isNavigationDrawerExpanded={isNavigationDrawerExpanded}
      backgroundColor={backgroundColor}
      color={appearance === 'info' ? GRAY_COLORS.GRAY_500 : COLOR_WHITE}
    >
      <img src={icon} alt={'toast-icon'} />
      <Divider
        sx={{ backgroundColor: appearance === 'info' ? GRAY_COLORS.GRAY_300 : COLOR_WHITE }}
        orientation={'vertical'}
        flexItem
      />
      {children}
    </StyledContainer>
  );
};
