import { Workflow } from '../../../models/Workflows';

export const getWorkflowRunEmptyState = (workflow: Workflow) => {
  const emptyStates: Record<string, any> = {};

  const getFieldInitialValue = (field: any) => {
    switch (field.type) {
      case 'textInput':
        return '';
      case 'fileInput':
        return field.isMultiple ? [] : null;
      case 'dynamicTextInputList':
        return Array(field.initialNumberOfInputs).fill('');
      case 'phoneInput':
        return '';
      case 'number':
        return 0;
      case 'float':
        return 0.0;
      case 'date':
        return new Date();
      case 'selectInput':
        return [];
      default:
        return null;
    }
  };

  const setObjectProperty = (obj: any, keyString: string, value: any) => {
    const keys = keyString.split('.');
    keys.reduce((acc: any, key: string, index: number) => {
      if (index === keys.length - 1) {
        acc[key] = value;
      } else {
        if (!acc[key] || typeof acc[key] !== 'object') {
          acc[key] = {};
        }
      }
      return acc[key];
    }, obj);
  };

  workflow.steps.forEach(step => {
    if (step.stepConfiguration && step.stepConfiguration.fields) {
      step.stepConfiguration.fields.forEach((field: any) => {
        const initialValue = field.initialValue || getFieldInitialValue(field);
        setObjectProperty(emptyStates, field.name, initialValue);
      });
    }
  });

  return emptyStates;
};
