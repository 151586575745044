import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Link,
  Box,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import styled, { css } from 'styled-components';

import Button from '../buttons/Button';
import ConversationFiles from '../../pages/ConversationPage/ConversationFiles';
import { useStore } from '../../hooks/useStore';
import { UpdateWorkflowRunDto } from '../../models/Workflows';

export type CreditSpreadingResultItemNew = {
  Category: string;
  correct: boolean;
  explanation: string;
  line_item: string;
  note_numbers: string[];
  page: number | string;
  response_from_statement: string;
  value: number;
  year: number;
  ground_truth?: number | string;
  [key: `year_${string}`]: string;
};

export type CreditSpreadingResultNew = {
  accuracy: number;
  results: CreditSpreadingResultItemNew[];
};

const CreditSpreadingResultTable = () => {
  const [workflowResult, setWorkflowResult] = useState<CreditSpreadingResultNew>({ accuracy: 0, results: [] });
  const [editedValues, setEditedValues] = useState<CreditSpreadingResultItemNew[]>([]);
  const [loading, setLoading] = useState(false);

  const {
    localizationStore: { i18next: i18n },
    workFlowStore: { selectedWorkflowRun, updateRun },
    conversationStore: { setPreviewFileInitialPage, setPreviewFile },
  } = useStore();

  useEffect(() => {
    setWorkflowResult(selectedWorkflowRun.result);
    setEditedValues(selectedWorkflowRun.result?.results || []);
    setPreviewFile(selectedWorkflowRun.variables.files[0]);
  }, [selectedWorkflowRun.id]);

  const handleValueChange = (index: number, newValue: number) => {
    setEditedValues(prevState => {
      const array = [...prevState];
      array[index].value = newValue;
      return array;
    });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const newResultObject = { ...selectedWorkflowRun.result };
      newResultObject.results = editedValues;

      const updateRunDto: UpdateWorkflowRunDto = {
        result: newResultObject,
      };

      await updateRun(selectedWorkflowRun.id, updateRunDto);
    } catch (error) {
      console.error('Error updating workflow run:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = (page: string | number) => {
    const parsedPage = typeof page === 'string' ? parseInt(page) : page;
    setPreviewFileInitialPage(parsedPage);
    setPreviewFile(selectedWorkflowRun.variables.files[0], true);
  };

  return (
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} width="100%" minHeight="0" flexGrow={1}>
      <Box width={{ xs: '100%', md: '50%' }} minHeight="0" display="flex" flexDirection="column">
        <ConversationFiles docFiles={selectedWorkflowRun.variables.files} redactedViewDisabled={true} />
      </Box>
      <Box
        width={{ xs: '100%', md: '50%', paddingRight: 2 }}
        pl={{ xs: 0, md: 2 }}
        minHeight="0"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        overflow="hidden"
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', marginBottom: '12px' }}>
          <Typography variant="h6" sx={{ textAlign: 'start' }}>
            Accuracy: {`${workflowResult.accuracy}`}
          </Typography>
        </Box>

        <TableContainer
          id={'resultElement'}
          component={Paper}
          style={{ flexGrow: 1, overflow: 'auto', marginTop: '12px' }}
        >
          <Table stickyHeader style={{ marginRight: 12 }}>
            <TableHead>
              <TableRow>
                <TableCell>{i18n.t('Name')}</TableCell>
                <TableCell>{i18n.t('Value')}</TableCell>
                <TableCell>{i18n.t('Page')}</TableCell>
                <TableCell>{i18n.t('Ground Truth')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workflowResult.results.map((item, index) => {
                const groundTruth = item.ground_truth || item[`year_${item.year}`];
                const isGroundTruthPresent = !!groundTruth && groundTruth !== 'NA';

                const isValueDifferentFromGroundTruth =
                  isGroundTruthPresent && Math.abs(parseFloat(groundTruth.toString())) !== Math.abs(item.value);

                return (
                  <TableRow key={item.line_item}>
                    <StyledTableCell highlight={isValueDifferentFromGroundTruth}>{item.line_item}</StyledTableCell>
                    <StyledTableCell highlight={isValueDifferentFromGroundTruth}>
                      <TextField
                        value={editedValues[index]?.value}
                        onChange={e => handleValueChange(index, Number(e.target.value))}
                        variant="standard"
                        fullWidth
                      />
                    </StyledTableCell>
                    <StyledTableCell highlight={isValueDifferentFromGroundTruth}>
                      <Tooltip
                        title={item.explanation || ''}
                        slotProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: 'rgba(0, 0, 0, 0.8)',
                              color: '#fff',
                              fontSize: theme => theme.typography.pxToRem(12),
                              borderRadius: theme => theme.shape.borderRadius,
                            },
                          },
                        }}
                      >
                        <Link href="#" onClick={() => handlePageClick(item.page)} underline="none">
                          {item.page}
                        </Link>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell highlight={isValueDifferentFromGroundTruth}>{groundTruth}</StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button color="primary" onClick={handleSave} disabled={loading} loading={loading}>
            {i18n.t('Save')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const StyledTableCell = styled(TableCell)<{ highlight?: boolean }>`
  ${({ highlight }) =>
    highlight &&
    `
      color: red !important;
      border-bottom: 1px solid red !important;
    `}
`;

export default observer(CreditSpreadingResultTable);
