import { Chat } from './Chat';
import { EMPTY_USER, User } from './User';
import { Team } from './Team';

export interface RecommendedModel {
  modelId: string;
  modelProvider: string;
  modelVersion: string;
  maxTokens: number;
  temperature: number;
  displayName: string
}

export interface Prompt {
  id: string;
  title: string;
  content: string;
  allTimeUseCounter: number;
  organizationId: string;
  options: string;
  isInTeamGallery: boolean;
  chats: Chat[];
  teams: Team[];
  user: User;
  createdAt: Date;
  updatedAt: Date;
  recommendedModel?: RecommendedModel;
}

export interface TopPromptByTeam {
  createdAt: string;
  createdBy: string;
  id: string;
  name: string;
  organizationId: string;
  promptCount: number;
  totalMembers: number;
  totalPrompts: number;
  updatedAt: string;
}

export interface PromptByConversation {
  createdAt: string;
  createdBy: string;
  id: string;
  name: string;
  organizationId: string;
  conversationsCount: number;
  totalMembers: number;
  totalPrompts: number;
  updatedAt: string;
}

export interface TopPrompt {
  id: string;
  title: string;
  content: string;
  allTimeUseCounter: number;
  teams: Array<Team>;
  user: User;
}

export interface CreatePromptTeamsDto {
  id: string;
}

export interface CreatePromptDto {
  title: string;
  content: string;
  teams: CreatePromptTeamsDto[];
  recommendedModel?: RecommendedModel;
}

export interface UpdatePromptDto extends CreatePromptDto {}

export const INITIAL_PROMPT: Prompt = {
  allTimeUseCounter: 0,
  chats: [],
  content: '',
  createdAt: new Date(),
  id: '',
  isInTeamGallery: false,
  options: '',
  organizationId: '',
  teams: [],
  title: '',
  updatedAt: new Date(),
  user: EMPTY_USER,
};
