import { ApiConfig } from './ApiConfig';
import { CreatePromptDto, Prompt, UpdatePromptDto } from '../models/Prompt';

export class PromptsApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  async getPromptsByUser(): Promise<Prompt[]> {
    return await this.apiConfig.sendRequest('GET', '/prompts');
  }

  async getPromptsByUserTeams(): Promise<Prompt[]> {
    return await this.apiConfig.sendRequest('GET', '/prompts/teams');
  }

  async getPromptsByOrganization(): Promise<Prompt[]> {
    return await this.apiConfig.sendRequest('GET', '/prompts/organization');
  }

  getTopPrompts(): Promise<Prompt[]> {
    return this.apiConfig.sendRequest('GET', '/prompts/top-prompts');
  }

  getPromptById(id: string): Promise<Prompt> {
    return this.apiConfig.sendRequest('GET', `/prompts/${id}`);
  }

  async createPrompt(createPromptDto: CreatePromptDto): Promise<Prompt> {
    return await this.apiConfig.sendRequest('POST', '/prompts', createPromptDto);
  }

  async update(id: string, createPromptDto: UpdatePromptDto): Promise<Prompt> {
    return await this.apiConfig.sendRequest('PATCH', `/prompts/${id}`, createPromptDto);
  }

  remove(id: string) {
    return this.apiConfig.sendRequest('DELETE', `/prompts/${id}`);
  }
}
