import { ApiConfig } from './ApiConfig';
import { AuditLog, CreateAuditLogDto, FullSearchModel } from '../models/AuditLog';

export class AuditLogsApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  getHistoryLogs(): Promise<Array<AuditLog>> {
    return this.apiConfig.sendRequest('GET', `/audit-logs`);
  }

  getHistoryLogsByType(type: string): Promise<Array<AuditLog>> {
    return this.apiConfig.sendRequest('GET', `/audit-logs?type=${type}`);
  }

  getPaginatedHistoryLogsWithFilters(data: FullSearchModel) {
    let endpoint = '/audit-logs/paginated';
    if (data?.page) {
      endpoint += `?page=${data?.page}`;

      if (data?.limit) {
        endpoint += `&limit=${data?.limit}`;
      }

      if (data?.startDate && data?.endDate) {
        endpoint += `&filter.createdAt=$btw:${data?.startDate},${data?.endDate}`;
      } else if (data?.startDate) {
        endpoint += `&filter.createdAt=$gte:${data?.startDate}`;
      } else if (data?.endDate) {
        endpoint += `&filter.createdAt=$lte:${data?.endDate}`;
      }

      if (data?.search?.length) {
        endpoint += `&search=${data?.search}`;
      }

      if (data?.entityType?.length) {
        const entities = data?.entityType
          ?.map(item => (item === 'Deleted conversation' ? 'conversation' : item))
          .join(',');
        endpoint += `&filter.entityType=$in:${entities.toLowerCase()}`;
      }

      if (data?.userId?.length) {
        const entities = data?.userId?.map(item => item).join(',');
        endpoint += `&filter.userId=$in:${entities}`;
      }

      if (data?.status?.length) {
        const entities = data?.status?.map(item => item.toLowerCase()).join(',');
        endpoint += `&filter.status=$in:${entities}`;
      }

      if (data?.teamId?.length) {
        data?.teamId.forEach(item => {
          endpoint += `&teamIds=${item}`;
        });
      }
    }

    return this.apiConfig.sendRequest('GET', endpoint);
  }

  createAuditLog(createAuditDto: CreateAuditLogDto): Promise<AuditLog> {
    return this.apiConfig.sendRequest('POST', '/audit-logs/login', createAuditDto);
  }
}
