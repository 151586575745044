import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';

import {
  COLOR_BORDER_PRIMARY,
  COLOR_BORDER_SECONDARY,
  COLOR_BUTTON_CONTAINED_DISABLED,
  COLOR_BUTTON_CONTAINED_ERROR,
  COLOR_BUTTON_CONTAINED_ERROR_DISABLED,
  COLOR_BUTTON_CONTAINED_ERROR_FOCUSED,
  COLOR_BUTTON_CONTAINED_ERROR_HOVER,
  COLOR_BUTTON_CONTAINED_FOCUSED,
  COLOR_BUTTON_CONTAINED_HOVER,
  COLOR_BUTTON_OUTLINED_DISABLED,
  COLOR_BUTTON_OUTLINED_FOCUSED,
  COLOR_BUTTON_OUTLINED_HOVER,
  COLOR_BUTTON_OUTLINED_SUCCESS_DISABLED,
  COLOR_BUTTON_OUTLINED_SUCCESS_FOCUSED,
  COLOR_BUTTON_OUTLINED_SUCCESS_HOVER,
  COLOR_BUTTON_OUTLINED_SUCCESS_TEXT,
  COLOR_PRIMARY,
  COLOR_QUATERNARY,
  COLOR_TABLE_HEADER,
  COLOR_TEXT_PRIMARY,
  COLOR_TEXT_QUATERNARY,
  COLOR_TEXT_SECONDARY,
  COLOR_TEXT_TERTIARY,
  COLOR_UTILS_QUATERNARY,
  COLOR_UTILS_SECONDARY,
  COLOR_WHITE,
  GRAY_COLORS,
} from '../constants/colors';
import { APP_HEADER_HEIGHT } from '../constants/utils';

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY,
    },
    secondary: {
      main: COLOR_TEXT_SECONDARY,
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans serif'].join(','),
    h1: {
      fontFamily: 'Poppins',
      color: COLOR_TEXT_PRIMARY,
      fontWeight: 300,
      fontSize: '96px',
      lineHeight: '144px',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontFamily: 'Poppins',
      color: COLOR_TEXT_PRIMARY,
      fontWeight: 300,
      fontSize: '60px',
      lineHeight: '90px',
      letterSpacing: '-0.5px',
    },
    h3: {
      fontFamily: 'Poppins',
      color: COLOR_TEXT_PRIMARY,
      fontWeight: 400,
      fontSize: '48px',
      lineHeight: '72px',
    },
    h4: {
      fontFamily: 'Poppins',
      color: COLOR_TEXT_PRIMARY,
      fontWeight: 400,
      fontSize: '34px',
      lineHeight: '51px',
      letterSpacing: '0.25px',
    },
    h5: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      color: COLOR_TEXT_PRIMARY,
      fontSize: '24px',
      lineHeight: '36px',
    },
    h6: {
      fontFamily: 'Poppins',
      color: COLOR_TEXT_PRIMARY,
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '30px',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontFamily: 'Poppins',
      color: COLOR_TEXT_PRIMARY,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontFamily: 'Poppins',
      color: COLOR_TEXT_PRIMARY,
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '21px',
      letterSpacing: '0.1px',
    },
    body1: {
      fontFamily: 'Poppins',
      color: COLOR_TEXT_PRIMARY,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    body2: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      color: COLOR_TEXT_QUATERNARY,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
    },
    button: {
      fontFamily: 'Poppins',
      color: COLOR_TEXT_SECONDARY,
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '1.25px',
    },
    caption: {
      fontFamily: 'Poppins',
      color: COLOR_TEXT_SECONDARY,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '15px',
      letterSpacing: '0.4px',
    },
    overline: {
      fontFamily: 'Poppins',
      color: COLOR_TEXT_SECONDARY,
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '12px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '6px',
          boxShadow: 'none',
          fontSize: '14px',
          fontWeight: '500',
          minWidth: 'unset',

          '&:hover': {
            boxShadow: 'none',
          },

          '&.MuiButton-sizeSmall': {
            padding: '8px 16px',
            lineHeight: '18px',
          },
          '&.MuiButton-sizeMedium': {
            padding: '8px 18px',
            lineHeight: '24px',
          },
          '&.MuiButton-sizeLarge': {
            padding: '16px 26px',
            lineHeight: '18px',
            height: '48px',
          },

          '&.MuiButton-contained': {
            '&:hover': {
              backgroundColor: COLOR_BUTTON_CONTAINED_HOVER,
            },

            '&:focused': {
              backgroundColor: COLOR_BUTTON_CONTAINED_FOCUSED,
            },

            '&:active': {
              backgroundColor: COLOR_BUTTON_CONTAINED_FOCUSED,
            },

            '&:disabled': {
              backgroundColor: COLOR_BUTTON_CONTAINED_DISABLED,
            },
          },

          '&.MuiButton-containedError': {
            backgroundColor: COLOR_BUTTON_CONTAINED_ERROR,
            color: COLOR_WHITE,

            '&:hover': {
              backgroundColor: COLOR_BUTTON_CONTAINED_ERROR_HOVER,
            },

            '&:focused': {
              backgroundColor: COLOR_BUTTON_CONTAINED_ERROR_FOCUSED,
            },

            '&:active': {
              backgroundColor: COLOR_BUTTON_CONTAINED_ERROR_FOCUSED,
            },

            '&:disabled': {
              backgroundColor: COLOR_BUTTON_CONTAINED_ERROR_DISABLED,
            },
          },

          '&.MuiButton-outlined': {
            backgroundColor: COLOR_WHITE,
            border: `1px solid ${COLOR_BORDER_SECONDARY}`,
            color: COLOR_TEXT_QUATERNARY,

            '&:hover': {
              backgroundColor: COLOR_BUTTON_OUTLINED_HOVER,
            },

            '&:focused': {
              backgroundColor: COLOR_BUTTON_OUTLINED_FOCUSED,
            },

            '&:active': {
              backgroundColor: COLOR_BUTTON_OUTLINED_FOCUSED,
            },

            '&:disabled': {
              backgroundColor: COLOR_BUTTON_OUTLINED_DISABLED,
              color: COLOR_QUATERNARY,
            },
          },

          '&.MuiButton-outlinedSuccess': {
            backgroundColor: COLOR_WHITE,
            border: `1px solid ${COLOR_BORDER_SECONDARY}`,
            color: COLOR_BUTTON_OUTLINED_SUCCESS_TEXT,

            '&:hover': {
              backgroundColor: COLOR_BUTTON_OUTLINED_SUCCESS_HOVER,
            },

            '&:focused': {
              backgroundColor: COLOR_BUTTON_OUTLINED_SUCCESS_FOCUSED,
            },

            '&:active': {
              backgroundColor: COLOR_BUTTON_OUTLINED_SUCCESS_FOCUSED,
            },

            '&:disabled': {
              backgroundColor: COLOR_BUTTON_OUTLINED_SUCCESS_DISABLED,
              color: COLOR_QUATERNARY,
            },
          },

          '&.MuiButton-text': {
            color: COLOR_TEXT_QUATERNARY,

            '&:hover': {
              backgroundColor: COLOR_BUTTON_OUTLINED_HOVER,
            },

            '&:focused': {
              backgroundColor: COLOR_BUTTON_OUTLINED_FOCUSED,
            },

            '&:active': {
              backgroundColor: COLOR_BUTTON_OUTLINED_FOCUSED,
            },

            '&:disabled': {
              backgroundColor: COLOR_BUTTON_OUTLINED_DISABLED,
              color: COLOR_QUATERNARY,
            },
          },

          '&.MuiButton-textSuccess': {
            color: COLOR_BUTTON_OUTLINED_SUCCESS_TEXT,

            '&:hover': {
              backgroundColor: COLOR_BUTTON_OUTLINED_SUCCESS_HOVER,
            },

            '&:focused': {
              backgroundColor: COLOR_BUTTON_OUTLINED_SUCCESS_FOCUSED,
            },

            '&:active': {
              backgroundColor: COLOR_BUTTON_OUTLINED_SUCCESS_FOCUSED,
            },

            '&:disabled': {
              backgroundColor: COLOR_BUTTON_OUTLINED_SUCCESS_DISABLED,
              color: COLOR_QUATERNARY,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:before': {
            borderColor: COLOR_BORDER_SECONDARY,
          },
          '&:after': {
            borderColor: COLOR_BORDER_SECONDARY,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR_WHITE,
          borderRadius: '6px',
          height: '40px',

          '&.MuiOutlinedInput-root': {
            borderRadius: '6px',
          },

          '&.MuiInputBase-multiline': {
            height: 'unset',
          },

          fieldset: {
            border: '1px solid #D1D5DB',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '-8px',
          fontSize: '14px',
          fontFamily: 'Poppins',
          fontWeight: 400,
          lineHeight: '22px',
          color: GRAY_COLORS.GRAY_500,

          '&.Mui-focused': {
            top: 0,
            color: GRAY_COLORS.GRAY_500,
          },

          '&.Mui-error': {
            top: 0,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: '1px solid #D1D5DB !important',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: COLOR_WHITE,
        },
        root: {
          height: APP_HEADER_HEIGHT,
          boxShadow: 'none',
          borderBottom: `1px solid ${COLOR_BORDER_PRIMARY}`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          overflowX: 'hidden',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: GRAY_COLORS.GRAY_100,
          },

          '& .MuiListItemText-primary': {
            fontSize: '14px',
            color: GRAY_COLORS.GRAY_500,
            fontWeight: 400,
            lineHeight: '21px',
          },

          '& .MuiListItemIcon-root': {
            minWidth: '18px',
            marginRight: '23px',
          },

          '& .MuiSvgIcon-root': {
            width: '18px',
            height: '18px',
          },

          '& .MuiListItemText-primary, & .MuiListItemIcon-root, & .MuiSvgIcon-root': {
            color: COLOR_TEXT_TERTIARY,
          },

          '&.Mui-selected': {
            backgroundColor: GRAY_COLORS.GRAY_100,
            '& .MuiListItemIcon-root, & .MuiSvgIcon-root, & .MuiListItemText-primary': {
              color: COLOR_PRIMARY,
            },
            '& .MuiListItemText-primary': {
              color: COLOR_TEXT_PRIMARY,
              fontWeight: 500,
            },
            '& .MuiListItemIcon-root, & .MuiSvgIcon-root': {
              color: COLOR_PRIMARY,
            },
            '&:hover': {
              backgroundColor: GRAY_COLORS.GRAY_100,
            },
          },
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          marginLeft: '20px',
          borderLeft: `1px solid ${COLOR_BORDER_SECONDARY}`,
          paddingLeft: '20px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${GRAY_COLORS.GRAY_100}`,
        },
        indicator: {
          height: '4px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: GRAY_COLORS.GRAY_500,
          '&.Mui-selected': {
            color: COLOR_TEXT_QUATERNARY,
            fontWeight: 500,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-root': {
            backgroundColor: COLOR_WHITE,
            border: `1px solid ${COLOR_BORDER_SECONDARY}`,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          background: COLOR_TABLE_HEADER,

          '& th': {
            background: COLOR_TABLE_HEADER,
            color: COLOR_TEXT_SECONDARY,
            fontWeight: 400,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR_WHITE,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            background: COLOR_WHITE,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '40px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: COLOR_WHITE,
          border: `1px solid ${COLOR_BORDER_PRIMARY}`,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 48,
          height: 32,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: COLOR_UTILS_SECONDARY,
              background: COLOR_WHITE,
              width: 28,
              height: 28,
              '&:hover': {
                background: COLOR_WHITE,
              },
              '& + .MuiSwitch-track': {
                backgroundColor: COLOR_UTILS_SECONDARY,
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: COLOR_UTILS_SECONDARY,
              background: COLOR_WHITE,
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: COLOR_UTILS_QUATERNARY,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: COLOR_UTILS_QUATERNARY,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 28,
            height: 28,
          },
          '& .MuiSwitch-track': {
            borderRadius: 32 / 2,
            backgroundColor: COLOR_UTILS_QUATERNARY,
            opacity: 1,
          },
        },
      },
    },
  },
});

type ThemeFactoryWrapperProp = {
  children: React.ReactNode;
};

export const ThemeFactoryWrapper = ({ children }: ThemeFactoryWrapperProp) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
