import { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';

import { useStore } from '../../hooks/useStore';
import { COLOR_PRIMARY } from '../../constants/colors';

type QuestionAndAnswerProps = {
  question: string;
  answer: string;
  meta: any;
  questionId: string;
  defaultExpanded?: boolean;
};

export const FormatExtractedInfo = ({ data }: any) => {
  const {
    conversationStore: { setPreviewFile, setPreviewFileInitialPage },
    workFlowStore: { selectedWorkflowRun },
  } = useStore();

  return (
    <div>
      {data.map((item: any, index: number) => {
        const file = selectedWorkflowRun?.variables?.files.find((file: any) => file.key === item.file_name);

        return (
          <div key={index} style={{ maxWidth: '90%' }}>
            <Tooltip title={item.extracted_info} placement="top">
              <span>{item.extracted_info.substring(0, 50)}...</span>
            </Tooltip>
            {' - '}
            <span
              style={{ textDecoration: 'underline', color: COLOR_PRIMARY }}
              onClick={() => {
                setPreviewFileInitialPage(parseInt(item.page_num));
                setPreviewFile(file, true);
              }}
            >
              {file?.name}
              {' - '}
              page {item.page_num}
            </span>
          </div>
        );
      })}
    </div>
  );
};

const QuestionAndAnswer = ({ question, answer, meta, questionId, defaultExpanded = false }: QuestionAndAnswerProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <Accordion
      expanded={expanded}
      defaultExpanded={defaultExpanded}
      onChange={() => setExpanded(prev => !prev)}
      sx={{
        '& .MuiCollapse-root': { border: 'none', marginLeft: '0px' },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
        <p>
          <span style={{ fontWeight: 'bold' }}>Question {questionId}: </span> {question}
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <p style={{ marginBottom: '20px' }}>
          <span style={{ fontWeight: 'bold' }}>Answer : </span> {answer}
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>Sources : </span>
          <FormatExtractedInfo data={meta} />
        </p>
      </AccordionDetails>
    </Accordion>
  );
};

export default QuestionAndAnswer;
