import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import configure from '../../config';
import defaultLogo from '../../config/addvaluemachine/logo.svg';
import { Paths } from '../../constants/routes';

interface LogoProps {
  size?: number;
}

const Logo = ({ size }: LogoProps) => {
  const navigate = useNavigate();
  const [logo, setLogo] = useState(defaultLogo);
  useEffect(() => {
    (async () => {
      const config = await configure();
      setLogo(config.logo);
    })();
  }, []);
  return (
    <img
      height={size || 30}
      src={logo}
      alt={'AvmLogo'}
      onClick={() => {
        navigate(Paths.CHAT);
      }}
    />
  );
};

export default Logo;
