import { Box, Typography } from '@mui/material';

import { COLOR_BORDER_PRIMARY, COLOR_WHITE } from '../../../constants/colors';
import { AddFilesStepInterfaces, StepInterfaceProps } from '../../../models/Steps/StepInterfaces';
import { renderField } from '../utils/renderFields';
import StepperButtonGroup from '../components/StepperButtonGroup';

interface AddFileStepProps extends StepInterfaceProps {
  step: AddFilesStepInterfaces;
}

const AddFilesStep = ({ step, setCurrentStepIndex, currentStepIndex }: AddFileStepProps) => {
  return (
    <Box sx={{ width: '100%', marginBottom: '1rem' }}>
      <Box
        sx={{
          border: `2px solid ${COLOR_BORDER_PRIMARY}`,
          borderRadius: '8px',
          marginBottom: '1rem',
          padding: '1.5rem',
          background: COLOR_WHITE,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600, mb: '0.5rem' }}>
          {step?.stepConfiguration?.displayText}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 500, mb: '0.5rem' }}>
          {step?.stepConfiguration?.stepInfo}
        </Typography>
        {step?.stepConfiguration?.fields.map(renderField)}
        <StepperButtonGroup currentStep={currentStepIndex} setCurrentStep={setCurrentStepIndex} />
      </Box>
    </Box>
  );
};

export default AddFilesStep;
