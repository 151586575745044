import logo from './logo.svg';

const config = {
  name: 'MCRO',
  domain: 'mcro',
  logo,
  features: {
    // feature flags
  },
  firebaseConfigTenant: {
    apiKey: "AIzaSyD1BQqIL-KY2mOWMJlfGkLKx4QmK65qDH0",
    authDomain: "addvaluemachine-mcro.firebaseapp.com",
    projectId: "addvaluemachine-mcro",
    storageBucket: "addvaluemachine-mcro.appspot.com",
    messagingSenderId: "732230650201",
    appId: "1:732230650201:web:31b475ba919012201c6448",
    measurementId: "G-RXJVXQ3XV8"
  }
};

export default config;
