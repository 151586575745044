export interface ImageToCropProps {
  src: string;
  fileName: string;
  type: string;
  size: number;
  originalFile: File;
}

export type PixelCropType = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export const limitSize = (size: number, maximumPixels: number) => {
  const requiredPixels = size * size;
  if (requiredPixels <= maximumPixels) return { size };

  const scalar = Math.sqrt(maximumPixels) / Math.sqrt(requiredPixels);
  return {
    size: Math.floor(size * scalar),
  };
};

//create image for crop
export const createImage = (url: string): any =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.src = url;
  });

export const getCroppedImg = async (imageSrc: string, pixelCrop: PixelCropType | null) => {
  if (!pixelCrop) {
    return null;
  }

  const image = await createImage(imageSrc);
  const imageType = imageSrc.indexOf('.png') ? 'image/png' : 'image/jpeg';
  const canvas = document.createElement('canvas');
  const ctx: any = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);

  //16777216 in the maximum amount of pixels allowed on a canvas on safari
  const safeArea = limitSize(maxSize, 16777216).size;

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(file);
    }, imageType);
  });
};
