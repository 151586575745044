import axios, { AxiosRequestConfig } from 'axios';

export const getDocumentsKey = (directoryName: string, name: string) =>
  `${directoryName}/${new Date().getTime()}-${name}`;
export const getAssetsKey = (tenant: string, directoryName: string, imageName: string) =>
  `${tenant}/${directoryName}/${new Date().getTime()}_${imageName}`;

export const uploadDocumentSecurely = async (
  preSignedUrl: string,
  file: any,
  options?: { setProgress?: any; setCancelToken?: any }
) => {
  const source = axios.CancelToken.source();
  if (options && options.setCancelToken) {
    options.setCancelToken(source);
  }

  const config: AxiosRequestConfig = {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    url: preSignedUrl,
    data: file,
    onUploadProgress: progressEvent => {
      if (options && options.setProgress && progressEvent.total) {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        options.setProgress(progress);
      }
    },
    cancelToken: source.token,
  };

  const response = await axios(config);

  return response?.status;
};

export const downloadDocumentSecurely = async (preSignedUrl: string) => {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: preSignedUrl,
    responseType: 'blob',
  };

  const response = await axios(config);
  return response.data;
};
