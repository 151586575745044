import logo from './logo.svg';
import AVMLogo from './AVMLogo.svg';
import chatLogo from './chatLogo.svg';
import agentLogo from './agentLogo.svg';
import feedbackLogo from './feedbackLogo.svg';
import helpCenterLogo from './helpCenterLogo.svg';
import logoutLogo from './logoutLogo.svg';
import promptGalleryLogo from './promptGalleryLogo.svg';
import knowledgeBaseLogo from './knowledgeBaseLogo.svg';
import jiraLogo from './jiraLogo.svg';
import sharePointLogo from './sharePointLogo.svg';
import oneDriveLogo from './oneDriveLogo.svg';
import agentAvatarLogo from './agentAvatarLogo.svg';
import uploadIcon from './uploadIcon.svg';
import uploadingFile from './uploadingFile.svg';

const config = {
  name: 'Add Value Machine',
  domain: 'addvaluemachine',
  logo,
  AVMLogo,
  chatLogo,
  agentLogo,
  feedbackLogo,
  helpCenterLogo,
  logoutLogo,
  promptGalleryLogo,
  knowledgeBaseLogo,
  jiraLogo,
  sharePointLogo,
  oneDriveLogo,
  agentAvatarLogo,
  uploadIcon,
  uploadingFile,
  features: {
    // feature flags
  },
};

export const googleDriveConfig = {
  clientId:
    process.env.GOOGLE_DRIVE_CLIENT_ID || '171553118899-7j1k4ldns7lrqefrjvf5ob79bg0l6oii.apps.googleusercontent.com',
  developerKey: process.env.GOOGLE_DRIVE_DEVELOPER_KEY || 'AIzaSyD2wd9qso3jz6WdF-ipfGtf2sng41FmR2U',
};
export default config;
