import { CallTranscriptResultInterface } from '../CallTranscriptResultsPage';
import { FortressResultInterface } from '../FortressResultsPage';
import { DynamicJsonObject } from '../FieldsExtractionResultPage';
import { ResumeParserFileResult, QueryResponseFields } from '../ResumeParserResultsPage';

export const isCallTranscriptResult = (obj: any): obj is CallTranscriptResultInterface[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      item =>
        item &&
        typeof item.FileID === 'string' &&
        typeof item.ResponseSummary === 'string' &&
        Array.isArray(item.QueryResponses) &&
        item.QueryResponses.every((qr: any) => qr && typeof qr.question === 'string' && typeof qr.response === 'string')
    )
  );
};

export const isFortressResultInterface = (obj: any): obj is FortressResultInterface => {
  return (
    obj &&
    typeof obj === 'object' &&
    Object.values(obj).every(
      item =>
        item &&
        typeof item === 'object' &&
        Array.isArray((item as any).meta) &&
        typeof (item as any).final_answer === 'string' &&
        typeof (item as any).question === 'string'
    )
  );
};

export const isDynamicJsonObject = (obj: any): obj is DynamicJsonObject => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  return Object.values(obj).every(value => Array.isArray(value) && value.every(item => typeof item === 'string'));
};

export const isHtmlString = (obj: any): obj is string => {
  return typeof obj === 'string' && /<\/?[a-z][\s\S]*>/i.test(obj);
};

export const isCreditSpreading = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  return Object.values(obj).every((item: any) => {
    return item && typeof item === 'object'
      ? typeof item.value === 'number' &&
          (!item.ground_truth || typeof item.ground_truth === 'string') &&
          (typeof item.page === 'string' || typeof item.page === 'number') &&
          typeof item.reason === 'string'
      : typeof item === 'number';
  });
};

export const isCreditSpreadingNew = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  if (typeof obj.accuracy !== 'number') {
    return false;
  }

  if (!Array.isArray(obj.results)) {
    return false;
  }

  return obj.results.every((item: any) => {
    if (typeof item !== 'object') return false;

    const hasRequiredFields =
      typeof item.Category === 'string' &&
      (typeof item.correct === 'boolean' || typeof item.correct === 'string') &&
      typeof item.explanation === 'string' &&
      typeof item.line_item === 'string' &&
      Array.isArray(item.note_numbers) &&
      item.note_numbers.every((note: any) => typeof note === 'string') &&
      (typeof item.page === 'number' || typeof item.page === 'string') &&
      typeof item.response_from_statement === 'string' &&
      typeof item.value === 'number' &&
      typeof item.year === 'number';

    // TODO: add this back if this proves to be mandatory
    // const hasYearFields = Object.keys(item).some(key => key.startsWith('year_') && typeof item[key] === 'string');

    return hasRequiredFields;
  });
};

// RESUME PARSER
export const resumeParserFields = [
  'ACT Score',
  'SAT Score',
  'GPA',
  'Graduation Year',
  'TotalExperience',
  'priorexpIndex',
];

const isResumeParserResponseFields = (obj: any): obj is QueryResponseFields => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  return resumeParserFields.every(key => {
    return key in obj;
  });
};

const isResumeParserResponse = (obj: any): obj is ResumeParserFileResult => {
  if (obj && typeof obj.FileID === 'string' && typeof obj.QueryResponses === 'string') {
    try {
      const queryResponses = JSON.parse(obj.QueryResponses);
      const a = isResumeParserResponseFields(queryResponses);

      return a;
    } catch {
      return false;
    }
  }
  return false;
};

export const isResumeParserResponseArray = (obj: any): obj is ResumeParserFileResult[] => {
  return Array.isArray(obj) && obj.every(item => isResumeParserResponse(item));
};
