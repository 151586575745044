import React from 'react';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';

import { useStore } from '../../hooks/useStore';
import GeneralModal from '../modal/GeneralModal';
import { KnowledgeBaseModel } from '../../models/KnowledgeBaseModel';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  knowledgeBaseToDelete?: KnowledgeBaseModel;
}
const DeleteKnowledgeBaseModal = ({ isOpen, onClose, knowledgeBaseToDelete }: ModalProps) => {
  const {
    localizationStore: { i18next: i18n },
    knowledgeBaseStore: { deleteKnowledgeBase },
  } = useStore();

  const onSave = async () => {
    if (knowledgeBaseToDelete) {
      await deleteKnowledgeBase(knowledgeBaseToDelete.id);
    }
    onClose();
  };

  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      onSave={async () => {
        await onSave();
        onClose();
      }}
      title={i18n.t('knowledgeBase.delete.KnowledgeBaseTitle')}
      type={'delete'}
    >
      <Typography variant={'body2'}>{i18n.t('knowledgeBase.delete.KnowledgeBaseMessage')}</Typography>
    </GeneralModal>
  );
};

export default observer(DeleteKnowledgeBaseModal);
