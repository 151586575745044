import CancelIcon from '@mui/icons-material/Cancel';
import { Typography } from '@mui/material';

import { COLOR_ERROR_ICON } from '../../../../../constants/colors';

import Flex from '../../../../../components/utils/flex/Flex';
import { useStore } from '../../../../../hooks/useStore';

const FileCancelledComponent = () => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  return (
    <Flex sx={{ width: '100%', gap: '8px', marginTop: '24px', alignItems: 'center' }}>
      <CancelIcon sx={{ fill: COLOR_ERROR_ICON }} />
      <Typography variant={'body2'}>{i18n.t('conversation.file.fileCancelled')}</Typography>
    </Flex>
  );
};

export default FileCancelledComponent;
