import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { VirtuosoGrid, VirtuosoGridHandle } from 'react-virtuoso';

import HistoryCard from '../../../components/cards/HistoryCard';
import { useStore } from '../../../hooks/useStore';
import { LoadingSpinner } from '../../../components/spinner/LoadingSpinner';
import Flex from '../../../components/utils/flex/Flex';
import { styled } from '@mui/material/styles';
import { filterObjectsByKeys } from '../../../utils/filterObjectsByKeys';

const ListContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(312px, 1fr));
  gap: 16px;
  padding: 0 4px 0 4px;
`;

interface ConversationsHistoryContentProps {
  searchValue: string;
}

const ConversationsHistoryContent = ({ searchValue }: ConversationsHistoryContentProps) => {
  const {
    conversationStore: { getAllUsersConversations, conversations },
  } = useStore();

  const [loading, setLoading] = useState(true);
  const [filteredConversations, setFilteredConversations] = useState<any>(conversations);

  useEffect(() => {
    (async () => {
      await getAllUsersConversations();

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!searchValue) {
      setFilteredConversations(conversations);
      return;
    }

    const newFilteredConversations = filterObjectsByKeys(conversations, ['content'], searchValue);

    setFilteredConversations(newFilteredConversations);
  }, [searchValue, conversations]);

  const virtuosoGridRef = useRef<VirtuosoGridHandle>(null);

  if (loading) {
    return (
      <Box>
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <Flex sx={{ flexDirection: 'column', flex: 1, marginTop: '16px' }}>
      <VirtuosoGrid
        ref={virtuosoGridRef}
        totalCount={filteredConversations?.length || 0}
        data={filteredConversations || []}
        itemContent={(_index, item) => {
          return <HistoryCard conversation={item} />;
        }}
        components={{
          // @ts-ignore
          List: ListContainer,
        }}
      />
    </Flex>
  );
};

export default observer(ConversationsHistoryContent);
