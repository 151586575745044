import logo from './logo.svg';

const config = {
  name: 'Renaissance',
  domain: 'renaissance',
  logo,
  features: {
    // feature flags
  }
};

export default config;
