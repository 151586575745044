import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { observer } from 'mobx-react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import styled from 'styled-components';

import { InnerFormField } from '../../../models/Steps/Fields';
import { useStore } from '../../../hooks/useStore';
import { renderField } from '../utils/renderFields';
import Button from '../../buttons/Button';
import { COLOR_RED, GRAY_COLORS } from '../../../constants/colors';

type DynamicFormInputsListProps = {
  name: string;
  field: InnerFormField;
  arrName: string;
  initialNumberOfInputs?: number;
  addButton?: string;
};

const StyledIconButton = styled(IconButton)({
  '&:hover': {
    backgroundColor: GRAY_COLORS.GRAY_12,
  },
});

const DynamicFormInputsList = ({ field, name, arrName, addButton }: DynamicFormInputsListProps) => {
  const {
    createWorkflowRunStore: {
      workflowRunData,
      setWorkflowRunFormField,
      removeKeyFromWorkflowRunData,
      removeElementFromWorkflowRunData,
    },
  } = useStore();
  const [fieldValues, setFieldValues] = useState(field);
  const [keys, setKeys] = useState<Array<string>>();
  const [values, setValues] = useState<Array<any>>([]);
  const [resetValue, setResetValue] = useState<boolean>(true);

  useEffect(() => {
    if (workflowRunData[arrName] && workflowRunData[arrName]?.length) {
      setKeys(Object.keys(workflowRunData[arrName][0]));
      const values = [];
      for (let item of workflowRunData[arrName]) {
        values.push([
          ...Object.values(item).map(item => {
            if (typeof item === 'object' && item !== null) {
              if (Array.isArray(item)) {
                return item.map(({ value }) => value)?.join(',');
              } else {
                return '';
              }
            }
            return item;
          }),
        ]);
      }
      setValues(values);
    }
  }, [JSON.stringify(workflowRunData[arrName])]);

  const handleAddIconClick = () => {
    setWorkflowRunFormField(name, arrName);
    removeKeyFromWorkflowRunData(name);
    setFieldValues(fieldValues.map(item => ({ ...item, reset: resetValue })));
    setResetValue(!resetValue);
  };

  const handleDeleteClick = (index: number) => {
    removeElementFromWorkflowRunData(arrName, index);
  };

  return (
    <>
      {fieldValues?.map(renderField)}
      <Button
        sx={{
          cursor: 'pointer',
        }}
        onClick={handleAddIconClick}
      >
        {addButton}
      </Button>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px', margin: '16px 0px' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {keys?.map(key => (
                  <TableCell key={key}>{key}</TableCell>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values?.map((fieldValues, index) => (
                <TableRow key={index}>
                  {fieldValues?.map((value: string, innerIndex: number) => (
                    <TableCell key={`${index}.${innerIndex}`}>{value}</TableCell>
                  ))}
                  <TableCell>
                    {index !== values.length - 1 && (
                      <StyledIconButton onClick={() => handleDeleteClick(index)} aria-label="delete">
                        <DeleteIcon sx={{ color: COLOR_RED }} />
                      </StyledIconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default observer(DynamicFormInputsList);
