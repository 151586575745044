import { Close } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import * as moment from 'moment';
import 'moment-timezone';
import React, { ReactNode, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import Button from '../../../../../components/buttons/Button';
import AddPromptModalAutocomplete from '../../../../../components/modal/AddPromptModal/components/AddPromptModalAutocomplete';

import CustomModal from '../../../../../components/modal/CustomModal';
import Flex from '../../../../../components/utils/flex/Flex';
import FlexRowStart from '../../../../../components/utils/flex/FlexRowStart';

import { COLOR_PRIMARY, GRAY_COLORS } from '../../../../../constants/colors';
import { useStore } from '../../../../../hooks/useStore';
import ConversationChat from '../ConversationChat';

const PreviewContainer = styled(Box)`
  width: 100%;
  height: 317px;
  border: 1px solid ${GRAY_COLORS.GRAY_2};
  border-radius: 6px;
  overflow: hidden;
`;

const PreviewHeader = styled(Box)`
  background: ${GRAY_COLORS.GRAY_1};
  border-bottom: 1px solid ${GRAY_COLORS.GRAY_2};
  padding: 12px 16px;
  width: 100%;
`;

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  heading: string;
  confirmButton: ReactNode;
  icon?: ReactNode;
}

const ShareChatMessage = ({ isOpen, onClose, heading, confirmButton, icon }: ModalProps) => {
  const {
    localizationStore: { i18next: i18n },
    userStore: { getUsersByUserOrganization, users },
    conversationStore: { currentConversation, shareConversation },
  } = useStore();
  const { addToast } = useToasts();

  const [error, setError] = useState('');
  const [listOfUsers, setListOfUsers] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      setListOfUsers([]);
      if (isOpen) {
        await getUsersByUserOrganization();
      }
    })();
  }, [isOpen]);

  const onShare = async () => {
    if (!listOfUsers.length) {
      setError('Please select users.');
      return;
    }
    try {
      await shareConversation({ conversationId: currentConversation.id, userIds: listOfUsers?.map(item => item.id) });
      addToast(i18n.t('conversation.shareConversationModal.save.successful'), { appearance: 'success' });
      onClose();
    } catch (e) {
      addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      sx={{ maxWidth: '640px', width: '600px', padding: '24px', borderRadius: '8px' }}
    >
      <Stack sx={{ gap: '16px', width: '100%' }}>
        <Flex sx={{ justifyContent: 'space-between', width: '100%' }}>
          <Typography variant={'subtitle1'} sx={{ fontWeight: 700, fontSize: '18px' }}>
            {heading}
          </Typography>
          <IconButton size={'small'} onClick={onClose}>
            <Close fontSize={'small'} />
          </IconButton>
        </Flex>

        <Typography variant={'subtitle2'}>{i18n.t('menuPopUp.addMembers.action')}</Typography>
        <AddPromptModalAutocomplete
          key={'teams-autocomplete'}
          label={i18n.t('logsList.table.header.user')}
          options={users}
          value={listOfUsers}
          placeHolder={
            listOfUsers?.length ? i18n.t('users.placeholder.addAnother') : i18n.t('users.placeholder.chooseUsers')
          }
          error={error}
          setValue={value => {
            setListOfUsers(value);
            setError('');
          }}
        />

        <PreviewContainer>
          <PreviewHeader>
            <Typography variant={'subtitle2'} fontWeight={600}>
              {i18n.t('conversation.shareConversationModal.header.preview')}
            </Typography>
            <Typography variant={'body2'} color={GRAY_COLORS.GRAY_7} sx={{ marginTop: '4px' }}>
              {`${currentConversation.user?.fullName || currentConversation.user?.email} · ${
                currentConversation?.updatedAt &&
                moment.tz(currentConversation?.updatedAt, 'EST').format('MMM DD, YYYY ')
              }
              `}
            </Typography>
          </PreviewHeader>
          <Box sx={{ padding: '16px', width: '100%', height: '78%' }}>
            <ConversationChat />
          </Box>
        </PreviewContainer>

        <FlexRowStart sx={{ gap: '16px' }}>
          <InfoOutlinedIcon sx={{ color: COLOR_PRIMARY }} />
          <Typography variant={'subtitle2'} color={GRAY_COLORS.GRAY_500}>
            {i18n.t('conversation.shareConversationModal.info')}
          </Typography>
        </FlexRowStart>

        <Button
          onClick={onShare}
          variant={'contained'}
          sx={{ width: 'fit-content', height: '40px !important', marginRight: 0, marginLeft: 'auto' }}
          id='Share-Chat'
        >
          {i18n.t('common.share')}
        </Button>
      </Stack>
    </CustomModal>
  );
};

export default observer(ShareChatMessage);
