import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

import { COLOR_BLACK } from '../constants/colors';

const BlackTooltip = (props: TooltipProps) => {
  return (
    <Tooltip
      {...props}
      PopperProps={{
        sx: {
          '& > .MuiTooltip-tooltip': {
            padding: '8px !important',
            backgroundColor: `${COLOR_BLACK} !important`,
            border: 'none !important',
          },
          '.MuiTooltip-arrow::before': {
            backgroundColor: `${COLOR_BLACK} !important`,
            border: 'none !important',
          },
        },
        ...props.PopperProps,
      }}
    >
      {props.children}
    </Tooltip>
  );
};

export default BlackTooltip;
