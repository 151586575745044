import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../hooks/useStore';
import TextInput from '../../inputs/TextInput';
import TextButton from '../../buttons/TextButton';
import S3FileExplorer from '../../S3FileSystemNavigator/S3FileExplorer';
import Typography from '@mui/material/Typography';
import Box from '@material-ui/core/Box/Box';

const S3SourceSelectedComponent = () => {
  const {
    localizationStore: { i18next: i18n },
    s3FileSystemStore: {
      s3Data,
      getS3Data,
      bucketName,
      setBucketName,
      setS3Data,
      setPrefix,
      clearData,
      setS3FilesToAddToKnowledgeBase,
    },
  } = useStore();

  const [inputDisabled, setInputDisabled] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    clearData();

    return () => {
      clearData();
    };
  }, []);

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <TextInput
        id="bucketName"
        placeholder={i18n.t('knowledgeBase.textField.bucketName.placeHolder')}
        name={'bucketName'}
        value={bucketName}
        error={i18n.t(error)}
        onChange={event => {
          setBucketName(event.target.value);
          setError('');
        }}
        variant={'outlined'}
        type={'text'}
        testId={'create-knowledge-base-name'}
        disabled={inputDisabled}
        sx={{
          marginBottom: '16px',
          '& .MuiInputLabel-root.Mui-error': { top: '-4px' },
          '& .MuiInputLabel-root': { top: '-4px' },
          '& .MuiInputLabel-shrink': { top: 0 },
        }}
        customEndAdornment={
          <Box>
            <TextButton
              sx={{ width: 'fit-content !important', padding: '9px 16px', height: '40px' }}
              onClick={async () => {
                if (inputDisabled || error) {
                  setInputDisabled(false);
                  setBucketName('');
                  setS3Data(undefined);
                  setPrefix('');
                  setError('');
                  setS3FilesToAddToKnowledgeBase([]);
                  return;
                }

                setPrefix('');
                const result = await getS3Data(bucketName, '');

                if (!result) {
                  setError('knowledgeBase.uploading.s3error.message');
                  return;
                }

                setError('');
                setInputDisabled(true);
              }}
            >
              <Typography>{inputDisabled || error ? 'Clear Search' : 'Search'}</Typography>
            </TextButton>
          </Box>
        }
      />
      {s3Data && <S3FileExplorer />}
    </Box>
  );
};

export default observer(S3SourceSelectedComponent);
