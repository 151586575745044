import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import * as moment from 'moment';
import { observer } from 'mobx-react-lite';
import { Box, Stack, Typography } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { i18n } from 'i18next';
import styled from 'styled-components';

import { Paths } from '../../constants/routes';
import { COLOR_BLACK, COLOR_BORDER_PRIMARY, COLOR_PRIMARY, COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';

import FlexColumnStart from '../utils/flex/FlexColumnStart';
import ViewModeSimpleRow from '../viewMode/ViewModeSimpleRow';
import { getFileType } from './utils';
import Divider from '../Divider/Divider';
import { useStore } from '../../hooks/useStore';
import Flex from '../utils/flex/Flex';

const DetailsPanel = styled(Box)`
  background: ${COLOR_WHITE};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 24px 0;
  height: 100%;
  width: 350px;
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

const PanelSection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 20px;
`;

const HighlightedRow = styled(Stack)`
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background: ${GRAY_COLORS.GRAY_1};
  }
`;

const ConversationRow = ({ date, conversationId, i18n }: { date: string; i18n: i18n; conversationId: string }) => {
  const [hovered, setHovered] = useState(false);

  const navigate = useNavigate();

  return (
    <HighlightedRow
      flexDirection={'row'}
      alignItems={'start'}
      justifyContent={'space-between'}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => navigate(`${Paths.CHAT}/${conversationId}`)}
    >
      <Typography variant={'subtitle2'} color={GRAY_COLORS.GRAY_500} width={'120px'} fontWeight={500}>
        {date}
      </Typography>
      <Flex sx={{ gap: '8px' }}>
        {hovered && (
          <Typography variant={'subtitle2'} color={COLOR_PRIMARY} fontWeight={500}>
            {i18n.t('knowledgeBase.fileDetails.openChat')}
          </Typography>
        )}
        <KeyboardArrowRight sx={{ color: GRAY_COLORS.GRAY_5 }} />
      </Flex>
    </HighlightedRow>
  );
};

const FileDetails = ({ numberOfPages }: { numberOfPages?: number | null }) => {
  const {
    localizationStore: { i18next: i18n },
    knowledgeBaseStore: { selectedFile },
    conversationStore: { conversationsByFile },
  } = useStore();

  return (
    <DetailsPanel>
      <PanelSection>
        <Typography variant="subtitle1" sx={{ fontWeight: 500, color: COLOR_BLACK }}>
          {i18n.t('knowledgeBase.fileDetails.title.properties')}
        </Typography>
        <FlexColumnStart>
          <ViewModeSimpleRow
            title={i18n.t('knowledgeBase.fileDetails.uploadDate')}
            text={selectedFile?.createdAt && moment.tz(selectedFile?.createdAt, 'EST').format('MM.DD.YYYY \n hh:mm A')}
            noBorder
            sx={{ padding: '6px 0' }}
          />
          <ViewModeSimpleRow
            title={i18n.t('knowledgeBase.fileDetails.fileType')}
            text={selectedFile?.name && getFileType(selectedFile?.name)?.toUpperCase()}
            noBorder
            sx={{ padding: '6px 0' }}
          />
          <ViewModeSimpleRow
            title={i18n.t('knowledgeBase.fileDetails.pages')}
            textNode={<>{numberOfPages}</>}
            noBorder
            sx={{ padding: '6px 0' }}
          />
        </FlexColumnStart>
      </PanelSection>
      <Divider color={COLOR_BORDER_PRIMARY} />
      {conversationsByFile?.length ? (
        <PanelSection>
          <Typography variant="subtitle1" sx={{ fontWeight: 500, color: COLOR_BLACK }}>
            {i18n.t('knowledgeBase.fileDetails.title.chatHistory')}
          </Typography>

          {conversationsByFile?.map((item, index) => (
            <ConversationRow
              key={`convers-${index}`}
              i18n={i18n}
              conversationId={item.id}
              date={item?.createdAt && moment.tz(selectedFile?.createdAt, 'EST').format('DD MMM, YYYY')}
            />
          ))}
        </PanelSection>
      ) : null}
    </DetailsPanel>
  );
};

export default observer(FileDetails);
