import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import { Box, FormControl, FormHelperText, RadioGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/Radio/Radio';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { RadioButtonUncheckedRounded, TripOrigin } from '@mui/icons-material';
import { Mention, MentionsInput } from 'react-mentions';

import { COLOR_PRIMARY, GRAY_COLORS } from '../../../../constants/colors';

import TextInput from '../../../inputs/TextInput';
import { useStore } from '../../../../hooks/useStore';
import { Team } from '../../../../models/Team';
import Flex from '../../../utils/flex/Flex';
import AddPromptModalAutocomplete from './AddPromptModalAutocomplete';

interface GeneralSettingsProps {
  formData: { title: string; content: string };
  setFormData: React.Dispatch<React.SetStateAction<{ title: string; content: string }>>;
  formErrors: { title: string; content: string };
  setIsDirtyState: React.Dispatch<React.SetStateAction<boolean>>;
  onKeyUp: (e: React.KeyboardEvent) => Promise<void>;
  onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  selectedTeams: any;
  setSelectedTeams: React.Dispatch<any>;
  setShareMethod: React.Dispatch<React.SetStateAction<string>>;
  shareMethod: string;
  teams: Team[];
}

const GeneralSettings = ({
  setIsDirtyState,
  formData,
  setShareMethod,
  shareMethod,
  setFormData,
  formErrors,
  onKeyUp,
  onChange,
  selectedTeams,
  setSelectedTeams,
  teams,
}: GeneralSettingsProps) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  const handleRadioButtonClick = (event: any) => {
    setIsDirtyState(true);

    if (event.target.value === 'private') {
      setSelectedTeams([]);
    }

    if (event.target.value === shareMethod) {
      return;
    }

    setShareMethod(event.target.value);
  };

  return (
    <>
      <Typography variant={'subtitle1'} sx={{ fontWeight: 600, marginBottom: '16px' }}>
        Title
      </Typography>

      <TextInput
        id="title"
        placeholder={i18n.t('prompts.textField.title.placeHolder')}
        name={'title'}
        value={formData.title}
        error={i18n.t(formErrors.title)}
        onKeyUp={onKeyUp}
        onChange={onChange}
        variant={'outlined'}
        type={'text'}
        testId={'add-prompt-title'}
        sx={{
          marginBottom: '16px',
          '& > .MuiInputBase-root': { height: '48px !important' },
          '& .MuiInputLabel-root.Mui-error': { top: '-4px' },
          '& .MuiInputLabel-root': { top: '-4px' },
          '& .MuiInputLabel-shrink': { top: 0 },
        }}
      />

      <Box>
        <Typography variant={'subtitle1'} sx={{ fontWeight: 600, marginBottom: '12px', marginTop: '8px' }}>
          Compose Prompt
        </Typography>

        <Flex
          sx={{
            borderRadius: '6px',
            padding: '8px',
            backgroundColor: GRAY_COLORS.GRAY_1,
            marginBottom: '12px',
            alignItems: 'center',
          }}
        >
          <LightbulbOutlinedIcon sx={{ color: COLOR_PRIMARY, marginRight: '8px' }} />
          <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_10 }}>
            {'Tip: Use square brackets [] within the prompt to insert variables.'}
          </Typography>
        </Flex>

        <MentionsInput
          value={formData.content}
          onChange={event => {
            setFormData({ ...formData, content: event.target.value });
            setIsDirtyState(true);
          }}
          style={{
            control: {
              backgroundColor: '#fff',
              overflow: 'scroll',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
            },

            '&multiLine': {
              control: {
                height: 'auto',
                resize: 'vertical',
              },
              highlighter: {
                padding: 12,
                border: '1px solid transparent',
                zIndex: 999,
                pointerEvents: 'none',
              },
              input: {
                overflow: 'scroll',
                padding: 12,
                borderRadius: '6px',
                border: '1px solid #D1D5DB',
              },
            },
          }}
          placeholder={'e.g. Write a blog post about [topic]'}
        >
          <Mention
            trigger="@"
            displayTransform={username => `[${username}]`}
            markup="[__id__]"
            data={[]}
            regex={/\[([^\[]+?)]/}
            style={{ backgroundColor: '#EDF3FE', color: COLOR_PRIMARY }}
          />
        </MentionsInput>

        {formErrors.content && (
          <FormHelperText sx={{ color: '#d32f2f', marginBottom: '16px' }}>{i18n.t(formErrors.content)}</FormHelperText>
        )}
      </Box>

      <Box sx={{ marginTop: '12px' }}>
        <Typography variant={'subtitle1'} sx={{ fontWeight: 600, marginBottom: '8px' }}>
          Sharing
        </Typography>

        <Box sx={{ padding: '0 12px' }}>
          <Box>
            <FormControl>
              <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
                <StyledFormControlLabel
                  value={'private'}
                  control={
                    <StyledRadio
                      checked={shareMethod === 'private'}
                      onClick={handleRadioButtonClick}
                      icon={<RadioButtonUncheckedRounded />}
                      checkedIcon={<TripOrigin sx={{ color: COLOR_PRIMARY }} />}
                    />
                  }
                  label={`Private`}
                />

                <StyledFormControlLabel
                  value={'shared'}
                  control={
                    <StyledRadio
                      checked={shareMethod === 'shared'}
                      onClick={handleRadioButtonClick}
                      icon={<RadioButtonUncheckedRounded />}
                      checkedIcon={<TripOrigin sx={{ color: COLOR_PRIMARY }} />}
                    />
                  }
                  label={`Shared with team(s)`}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Box>

      {shareMethod === 'shared' && (
        <Box sx={{ marginTop: '12px' }}>
          <Typography variant={'subtitle1'} sx={{ fontWeight: 600, marginBottom: '8px' }}>
            Team(s)
          </Typography>

          <AddPromptModalAutocomplete
            key={'teams-autocomplete'}
            placeHolder={'Select team(s)'}
            options={teams}
            value={selectedTeams}
            setValue={value => {
              setSelectedTeams(value);
              setIsDirtyState(true);
            }}
          />
        </Box>
      )}
    </>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  border: 1px solid ${GRAY_COLORS.GRAY_2};
  border-radius: 4px;
  padding: 12px 16px;
  margin: 6px;
`;

const StyledRadio = styled(Radio)`
  padding: 0 !important;
  margin: 0 8px 0 0 !important;
`;

export default observer(GeneralSettings);
