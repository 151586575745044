import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from '@mui/material';

import { useStore } from '../../../hooks/useStore';
import { FormatExtractedInfo } from '../../Fortress/QuestionAndAnswer';
import ConversationFiles from '../../../pages/ConversationPage/ConversationFiles';
import CustomModal from '../../modal/CustomModal';
import { COLOR_BLACK } from '../../../constants/colors';

interface MetaData {
  file_name: string;
  page_num: number;
  original_text: string;
  cleaned: string;
  extracted_info: string;
  page_image: string | null;
}

interface Solution {
  meta: MetaData[];
  final_answer: string;
  question: string;
}

export interface FortressResultInterface {
  [key: string]: Solution;
}

const StyledDiv = styled.div`
  color: ${COLOR_BLACK};
  font-weight: bold;
`;

const FortressResultsPage = () => {
  const [workflowResult, setWorkflowResult] = useState<FortressResultInterface>();

  const {
    localizationStore: { i18next: i18n },
    workFlowStore: { selectedWorkflowRun },
    conversationStore: { showFilePreview, toggleShowFilePreview },
  } = useStore();

  useEffect(() => {
    setWorkflowResult(selectedWorkflowRun.result);
  }, [selectedWorkflowRun.id]);

  return (
    <>
      <Box sx={{ overflow: 'auto' }}>
        <div id="resultElement">
          {/* converted result into tabular form */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{i18n.t('Questions')}</TableCell>
                  <TableCell>{i18n.t('Answer and Meta')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workflowResult &&
                  Object.keys(workflowResult).map((key: string, index) => {
                    return (
                      <TableRow key={key}>
                        <TableCell sx={{ width: '25%', alignContent: 'baseline' }}>
                          <StyledDiv>Question {key}:</StyledDiv> {workflowResult[key].question}
                        </TableCell>
                        <TableCell sx={{ width: '75%' }}>
                          <div>
                            <StyledDiv>{i18n.t('Answer')}:</StyledDiv>
                            <p>{workflowResult[key].final_answer}</p>
                          </div>
                          <div>
                            <StyledDiv>{i18n.t('Meta')}:</StyledDiv>
                            <FormatExtractedInfo data={workflowResult[key].meta} />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
      <CustomModal isOpen={showFilePreview} closeOnBackdropClick={true} onClose={toggleShowFilePreview}>
        <Box
          sx={{
            transition: theme =>
              theme.transitions.create(['width'], {
                easing: showFilePreview ? theme.transitions.easing.sharp : theme.transitions.easing.easeOut,
                duration: showFilePreview
                  ? theme.transitions.duration.leavingScreen
                  : theme.transitions.duration.enteringScreen,
              }),
          }}
        >
          <ConversationFiles docFiles={selectedWorkflowRun.variables.files} />
        </Box>
      </CustomModal>
    </>
  );
};

export default observer(FortressResultsPage);
