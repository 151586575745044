import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import {
  EMPTY_INTEGRATION_MODEL,
  INITIAL_SQL_CONNECTION,
  IntegrationModel,
  SelectedSqlConnection,
} from '../models/IntegrationModel';
import { IntegrationApi } from '../api/IntegrationApi';

export class IntegrationsStore {
  integrationsApi: IntegrationApi;
  selectedIntegration: null | IntegrationModel = EMPTY_INTEGRATION_MODEL;
  integrations: IntegrationModel[] = [];
  // sql database connection
  selectedSqlConnection: SelectedSqlConnection = INITIAL_SQL_CONNECTION;

  constructor(integrationsApi: IntegrationApi) {
    this.integrationsApi = integrationsApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  setSelectedIntegration(selectedIntegration: IntegrationModel | null) {
    runInAction(() => {
      this.selectedIntegration = selectedIntegration;
    });
  }

  // setting selected sql connection
  setSelectedSqlConnection(selectedSqlConnection: SelectedSqlConnection) {
    runInAction(() => {
      this.selectedSqlConnection = selectedSqlConnection;
    });
  }

  async getIntegrations() {
    const integrations = await this.integrationsApi.getIntegrations();

    runInAction(() => {
      this.integrations = integrations;
    });
  }

  async upsertIntegrations({ integrations }: { integrations: IntegrationModel[] }) {
    await this.integrationsApi.upsertIntegrations({ integrations });
    await this.getIntegrations();
  }
}
