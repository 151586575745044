import React, { useState } from 'react';
import CustomModal from './CustomModal';

import Typography from '@mui/material/Typography';
import { useStore } from '../../hooks/useStore';
import Flex from '../utils/flex/Flex';
import Button from '../buttons/Button';
import { GRAY_COLORS } from '../../constants/colors';

interface DeletePromptModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ConfirmDeletePromptModal = ({ isOpen, onClose }: DeletePromptModalProps) => {
  const {
    promptsStore: { currentPrompt, removePrompt },
  } = useStore();

  const [loading, setLoading] = useState(false);

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} sx={{ maxWidth: '500px' }}>
      <Flex sx={{ flexDirection: 'column' }}>
        <Typography variant={'h5'} sx={{ fontSize: '16px' }}>
          Delete prompt
        </Typography>
        <Typography variant={'body2'} sx={{ lineHeight: '24px', marginTop: '12px' }}>
          Are you sure you want do delete <strong>"{currentPrompt.title}"</strong> This action cannot be undone.
        </Typography>

        <Flex sx={{ justifyContent: 'flex-end', marginTop: '24px' }}>
          <Button
            variant={'outlined'}
            sx={{
              height: '40px',
              width: 'fit-content',
              marginRight: '8px',
              borderColor: `${GRAY_COLORS.GRAY_2} !important`,
              color: `${GRAY_COLORS.GRAY_9} !important`,
            }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            color={'error'}
            loading={loading}
            sx={{
              width: 'fit-content',
              height: '40px',
            }}
            onClick={async () => {
              setLoading(true);
              await removePrompt(currentPrompt.id);
              setLoading(false);

              onClose();
            }}
          >
            Delete
          </Button>
        </Flex>
      </Flex>
    </CustomModal>
  );
};

export default ConfirmDeletePromptModal;
