import { observer } from 'mobx-react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Box from '@mui/material/Box';
import {
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../../hooks/useStore';
import { ResultDetailsContainer } from './components/styledComponents';

interface QueryResponse {
  question: string;
  response: string;
}

export interface CallTranscriptResultInterface {
  FileID: string;
  ResponseSummary: string;
  QueryResponses: QueryResponse[];
}

const CallTranscriptResultsPage = () => {
  const [value, setValue] = useState('1');
  const [workflowResult, setWorkflowResult] = useState<CallTranscriptResultInterface[]>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const {
    localizationStore: { i18next: i18n },
    workFlowStore: { selectedWorkflowRun },
  } = useStore();

  useEffect(() => {
    setWorkflowResult(selectedWorkflowRun.result);
  }, [selectedWorkflowRun.id]);

  return (
    <Box sx={{ overflowY: 'auto' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '12px' }}>
          <TabList onChange={handleChange} aria-label="call transcript summarization tabs">
            {workflowResult?.map((resultData: any, index: number) => (
              <Tab label={resultData?.FileID} value={`${index + 1}`} />
            ))}
          </TabList>
        </Box>
        {workflowResult?.map((row: any, index: number) => (
          <TabPanel value={`${index + 1}`} sx={{ padding: '0px' }}>
            {/* added resultElement id to get result  */}
            <TableContainer id="resultElement" component={Paper}>
              <ResultDetailsContainer sx={{ flexDirection: 'column' }}>
                <Typography variant="subtitle1" sx={{ fontSize: '14px', fontWeight: 700 }}>
                  {i18n.t('Response Summary :')}
                </Typography>
                {workflowResult?.[index]?.ResponseSummary}
              </ResultDetailsContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{i18n.t('workFlows.callTranscriptSummarization.question')}</TableCell>
                    <TableCell>{i18n.t('workFlows.callTranscriptSummarization.response')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.QueryResponses?.map((response: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{response?.question}</TableCell>
                      <TableCell>{response?.response}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default observer(CallTranscriptResultsPage);
