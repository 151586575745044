import logo from './logo.svg';

const config = {
  name: 'Add Value Machine',
  domain: 'demo',
  logo,
  features: {
    // feature flags
  }
};
export default config;
