import React from 'react';

import { AuthenticationWrapper, LoginWrapper } from './routesRestrictions';
import NavigationContainer from '../components/navigation/NavigationContainer';
import NavigationFilePreviewContainer from '../components/navigation/NavigationFilePreviewContainer';

export const authenticationAndSidebarWrapper = (Component: () => JSX.Element) => {
  return (
    <AuthenticationWrapper>
      <NavigationContainer>
        <Component />
      </NavigationContainer>
    </AuthenticationWrapper>
  );
};

export const authenticationAndSidebarFilePreviewWrapper = (Component: () => JSX.Element) => {
  return (
    <AuthenticationWrapper>
      <NavigationFilePreviewContainer>
        <Component />
      </NavigationFilePreviewContainer>
    </AuthenticationWrapper>
  );
};

export const loginWrapper = (Component: () => JSX.Element) => {
  return (
    <LoginWrapper>
      <Component />
    </LoginWrapper>
  );
};
