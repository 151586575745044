import { Organization } from './Organization';
import { User } from './User';

export interface CreateWorkflowRunDto {
  userId: string;
  workflowId: string;
  variables?: CreateWorkflowRunDtoConfiguration;
  status?: string;
  result?: any;
}

export type UpdateWorkflowRunDto = Partial<CreateWorkflowRunDto>;

export interface CreateWorkflowRunDtoConfiguration {
  api: string;
  [key: string]: any;
}

export interface Configuration {
  [key: string]: any;
}

export interface WorkflowStep {
  name: string;
  stepConfiguration: Configuration;
}

export interface Workflow {
  id: string;
  name: string;
  category: string;
  createdBy: string;
  steps: WorkflowStep[];
  configuration: Configuration;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  organizationId: string;
  organization?: Organization;
}
export interface WorkflowRun {
  id: string;
  userId: string;
  createdBy: string;
  status: string;
  variables: Configuration;
  startedAt: Date;
  completedAt: Date;
  deletedAt: Date;
  user?: User;
  result?: any;
  workflow?: Workflow;
}

export const EMPTY_WORKFLOW_RUN: WorkflowRun = {
  createdBy: '',
  id: '',
  status: '',
  userId: '',
  variables: {},
  startedAt: new Date(),
  completedAt: new Date(),
  deletedAt: new Date(),
};

export const EMPTY_WORKFLOW: Workflow = {
  id: '',
  organizationId: '',
  name: '',
  category: '',
  createdBy: '',
  steps: [],
  configuration: {},
  createdAt: new Date(),
  deletedAt: new Date(),
  updatedAt: new Date(),
};
