import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import { SimpleFilterModel } from '../models/FilterModel';

export class FilterStore {
  showClearButton: boolean = false;
  localFilters: SimpleFilterModel[] = [];
  selectedFilters: SimpleFilterModel[] = [];
  searchValueFilter: string = '';
  globalSearchValueFilter: string = '';

  constructor() {
    makeAutoObservable(this);
    autoBind(this);
  }

  resetFilters() {
    runInAction(() => {
      this.showClearButton = false;
      this.searchValueFilter = '';
      this.localFilters = [];
      this.selectedFilters = [];
    });
  }

  setSearchValueFilter(newValue: string) {
    runInAction(() => {
      this.searchValueFilter = newValue;
    });
  }

  setGlobalSearchValueFilter(newValue: string) {
    runInAction(() => {
      this.globalSearchValueFilter = newValue;
    });
  }

  addFilterLocally(filterId: number, filterType: string, extraData?: string) {
    runInAction(() => {
      const filterAlreadyExists = this.localFilters.find(item => item.type === filterType && item.id === filterId);
      if (!filterAlreadyExists) {
        this.localFilters = Array.from(
          new Set([...this.localFilters, { id: filterId, type: filterType, extraData: extraData }])
        );
      }
    });
  }

  removeFilterLocally(filterId: number | string, filterType: string) {
    const index = this.localFilters.findIndex(filter => filter.id === filterId && filter.type === filterType);

    this.localFilters.splice(index, 1);
    const newFiltersList = this.localFilters;

    runInAction(() => {
      this.localFilters = [...newFiltersList];
    });
  }

  removeFilterTypeLocally(filterType: string) {
    const newFilters = this.localFilters.filter(item => item.type !== filterType);
    runInAction(() => {
      this.localFilters = [...newFilters];
    });
  }

  applyLocalFilters() {
    runInAction(() => {
      this.localFilters = [...this.selectedFilters];
    });
  }

  applyFilters() {
    runInAction(() => {
      this.selectedFilters = [...this.localFilters];
    });
  }

  applyAndReturnFilters() {
    runInAction(() => {
      this.selectedFilters = [...this.localFilters];
    });
    return [...this.localFilters];
  }

  resetFiltersLocally() {
    runInAction(() => {
      this.localFilters = [];
    });
  }
}
