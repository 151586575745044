import { Button, ButtonProps } from '@mui/material';

import { COLOR_PRIMARY } from '../../constants/colors';

interface PrimaryOutlinedButtonProps extends ButtonProps {}

const PrimaryOutlinedButton = ({ children, sx, ...props }: PrimaryOutlinedButtonProps) => {
  return (
    <Button
      variant={'outlined'}
      sx={{
        color: `${COLOR_PRIMARY} !important`,
        borderColor: `${COLOR_PRIMARY} !important`,
        fontWeight: 600,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default PrimaryOutlinedButton;
