import IconButton from '@mui/material/IconButton';
import { useToasts } from 'react-toast-notifications';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';

import { ChatSentiment } from '../../../../../constants/chatSentiment';
import { COLOR_TEXT_PRIMARY } from '../../../../../constants/colors';

import Copy from '../../../../../assets/icons/copyChat.svg';
import LikeClicked from '../../../../../assets/icons/like-clicked.svg';
import Like from '../../../../../assets/icons/like.svg';
import DislikeClicked from '../../../../../assets/icons/dislike-clicked.svg';
import Dislike from '../../../../../assets/icons/dislike.svg';
import HallucinationClicked from '../../../../../assets/icons/hallucination-clicked.svg';
import Hallucination from '../../../../../assets/icons/hallucination.svg';

import Flex from '../../../../../components/utils/flex/Flex';
import Divider from '../../../../../components/Divider/Divider';
import { Chat } from '../../../../../models/Chat';
import { useStore } from '../../../../../hooks/useStore';
import { Paths } from '../../../../../constants/routes';

const ActionTooltip = (props: TooltipProps) => (
  <Tooltip
    componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: COLOR_TEXT_PRIMARY,
          marginBottom: '0 !important',
          padding: '16px',
          borderRadius: '6px',
        },
      },
      arrow: {
        sx: {
          color: COLOR_TEXT_PRIMARY,
        },
      },
    }}
    placement={'top'}
    arrow
    {...props}
  >
    {props.children}
  </Tooltip>
);

interface ChatActionsProps {
  chat: Chat;
}

const ChatActions = ({ chat }: ChatActionsProps) => {
  const {
    conversationStore: { updateChatSentiment, currentConversation },
    localizationStore: { i18next: i18n },
    userStore: { userData },
  } = useStore();
  const { id, message, sentiment } = chat;

  const { addToast } = useToasts();
  const location = useLocation();
  const reactionDisabled =
    !location?.pathname?.includes(Paths.CHAT) ||
    currentConversation?.user?.id !== userData?.id ||
    currentConversation.sharedWithMe;

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(message);
      addToast(i18n.t('conversation.copyMessage.successful'), { appearance: 'success' });
    } catch (e) {
      console.log('e ------------------->> ', e);
      addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
    }
  };

  const handleSentiment = (sentiment: ChatSentiment) => {
    try {
      updateChatSentiment(id, sentiment);
    } catch (e) {
      addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
    }
  };

  return (
    <Flex sx={{ marginTop: '16px', alignItems: 'center' }}>
      <ActionTooltip title={i18n.t('conversation.copyChat.action')}>
        <IconButton onClick={handleCopyToClipboard}>
          <img src={Copy} alt={'copy-chat'} />
        </IconButton>
      </ActionTooltip>
      <Divider isVertical={true} width={'20px'} sx={{ marginLeft: '12px', marginRight: '12px' }} />
      <ActionTooltip title={i18n.t('conversation.likeChat.action')}>
        <IconButton
          onClick={() => handleSentiment(sentiment === ChatSentiment.Like ? ChatSentiment.Neutral : ChatSentiment.Like)}
          disabled={reactionDisabled}
        >
          {sentiment === ChatSentiment.Like ? (
            <img src={LikeClicked} alt={'like-chat'} />
          ) : (
            <img src={Like} alt={'like-chat'} />
          )}
        </IconButton>
      </ActionTooltip>
      <ActionTooltip title={i18n.t('conversation.dislikeChat.action')}>
        <IconButton
          onClick={() =>
            handleSentiment(sentiment === ChatSentiment.Dislike ? ChatSentiment.Neutral : ChatSentiment.Dislike)
          }
          disabled={reactionDisabled}
          sx={{ marginLeft: '4px' }}
        >
          {sentiment === ChatSentiment.Dislike ? (
            <img src={DislikeClicked} alt={'dislike-chat'} />
          ) : (
            <img src={Dislike} alt={'dislike-chat'} />
          )}
        </IconButton>
      </ActionTooltip>
      <ActionTooltip title={i18n.t('conversation.hallucinationChat.action')}>
        <IconButton
          onClick={() =>
            handleSentiment(
              sentiment === ChatSentiment.Hallucination ? ChatSentiment.Neutral : ChatSentiment.Hallucination
            )
          }
          disabled={reactionDisabled}
          sx={{ marginLeft: '4px' }}
        >
          {sentiment === ChatSentiment.Hallucination ? (
            <img src={HallucinationClicked} alt={'dislike-chat'} />
          ) : (
            <img src={Hallucination} alt={'dislike-chat'} />
          )}
        </IconButton>
      </ActionTooltip>
    </Flex>
  );
};

export default ChatActions;
