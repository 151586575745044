import React, { ReactNode } from 'react';
import { SxProps } from '@mui/material';
import { Box } from '@mui/material';

import { COLOR_SECONDARY, COLOR_WHITE } from '../../constants/colors';

export interface GenericCardProps {
  children: ReactNode;
  height?: string;
  key?: string;
  disableHover?: boolean;
  sx?: SxProps;
}

const GenericCard = ({ children, key, height, disableHover, sx }: GenericCardProps) => {
  return (
    <Box
      sx={{
        flex: 1,
        height: height,
        padding: '16px',
        border: '1px solid #F3F4F6',
        borderRadius: '8px',
        backgroundColor: COLOR_WHITE,
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.1)',
        transition: 'background-color 1s ease-out 100ms, opacity 1s ease-out 100ms',
        '&:hover': {
          backgroundColor: `${disableHover ? COLOR_WHITE : COLOR_SECONDARY + '66'}`,
        },
        ...sx,
      }}
      key={key}
    >
      {children}
    </Box>
  );
};

export default GenericCard;
