import React from 'react';
import { Container, Content } from '../components/login/LoginComponents';
import SignInCard from '../components/login/SignInCard';
import CopyRight from '../components/copyRight/CopyRight';

const LoginPage = () => {
  return (
    <Container>
      <Content>
        <div />
        <SignInCard />
        <CopyRight />
      </Content>
    </Container>
  );
};

export default LoginPage;
