import styled from 'styled-components';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { COLOR_PRIMARY, GRAY_COLORS } from '../../constants/colors';
import pdfIcon from '../../assets/images/knowledge-base/file-type-pdf.png';
import xlsIcon from '../../assets/images/knowledge-base/file-type-xls.png';
import docIcon from '../../assets/images/knowledge-base/file-type-doc.png';
import imageIcon from '../../assets/images/knowledge-base/file-type-image.png';
import genericIcon from '../../assets/images/knowledge-base/file-type-generic.png';

export const MoreIcon = styled(MoreVertOutlinedIcon)`
  color: ${GRAY_COLORS.GRAY_5};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    color: ${COLOR_PRIMARY};
    background: #d7e9fd;
  }
`;

export const TypeIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

export const getFileType = (name: string) => name.split('.').pop();

export const getIconByName = (name: string) => {
  const type = getFileType(name);
  switch (type) {
    case 'pdf':
      return pdfIcon;
    case 'xls':
    case 'xlsx':
    case 'csv':
      return xlsIcon;
    case 'doc':
    case 'docx':
    case 'txt':
    case 'md':
      return docIcon;
    case 'png':
    case 'jpeg':
    case 'heic':
    case 'tiff':
    case 'webp':
    case 'svg':
      return imageIcon;
    default:
      return genericIcon;
  }
};
