import { useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import {
  Button,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { useStore } from '../../hooks/useStore';
import ConversationFiles from '../../pages/ConversationPage/ConversationFiles';
import { Paths } from '../../constants/routes';
import { Headline2 } from '../typography/Headlines';
import { COLOR_BORDER_PRIMARY, COLOR_PRIMARY, COLOR_WHITE } from '../../constants/colors';
import { TabContext, TabList, TabPanel } from '@mui/lab';

const AdcbResultPage = () => {
  const {
    localizationStore: { i18next: i18n },
    conversationStore: {
      showFilePreview,
      setPreviewFile,
      setPreviewFileInitialPage,
      adcbSolution,
      fortressFiles,
      setFortressSolutionFiles,
      setFortressSolutionId,
      setFortressSolutionValue,
      setIsBackBtnUsed,
      setAgentFiles,
    },
  } = useStore();

  const navigate = useNavigate();

  const ScreenContainer = styled(Box)`
    width: 100%;
    height: calc(90vh - 56px - 120px);
    display: flex;
    gap: 16px;
    padding: 24px;
  `;

  const [value, setValue] = useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>
      <Button
        onClick={() => {
          setFortressSolutionFiles([]);
          setFortressSolutionId('');
          setFortressSolutionValue({});
          navigate(Paths.CHAT);
          setPreviewFile(fortressFiles[0].key, false);
          setAgentFiles([]);
          setIsBackBtnUsed(true);
        }}
      >
        <ArrowBackIosNewIcon sx={{ fontSize: '14px', fontWeight: 700 }} />
        <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: 700 }}>
          BACK
        </Typography>
      </Button>

      <Headline2 style={{ marginLeft: '24px' }}>A1 RAG - AGENT</Headline2>

      <ScreenContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: showFilePreview ? '60%' : '100%' }}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '12px' }}>
                <TabList onChange={handleChange} aria-label="evaluation result tabs">
                  {fortressFiles.map((file: any, index) => {
                    return <Tab label={file.name} value={`${index + 1}`} />;
                  })}
                </TabList>
              </Box>
              {fortressFiles.map((item: any, index: number) => {
                return (
                  <TabPanel value={`${index + 1}`}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Information Type</TableCell>
                            <TableCell>Details</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.keys(adcbSolution).map((key: string, innnerKey) => (
                            <TableRow key={innnerKey}>
                              <TableCell>{key}</TableCell>
                              <TableCell>{adcbSolution[key][index]}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                );
              })}
            </TabContext>
          </Box>
          <Box
            sx={{
              marginTop: '1rem',
              padding: '1rem',
              background: COLOR_WHITE,
              border: `1px solid ${COLOR_BORDER_PRIMARY}`,
              borderRadius: '8px',
              boxShadow: `0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)`,
              display: 'flex',
            }}
          >
            Sources : &nbsp;
            <span
              style={{ textDecoration: 'underline', color: COLOR_PRIMARY }}
              onClick={() => {
                setPreviewFileInitialPage(parseInt('1'));
                setPreviewFile(fortressFiles[0].key);
              }}
            >
              {i18n.t('Files')}
            </span>
          </Box>
        </Box>

        <Box
          sx={{
            maxWidth: 'calc(100vw * 0.4)',
            width: showFilePreview ? '40%' : 0,
            transition: theme =>
              theme.transitions.create(['width'], {
                easing: showFilePreview ? theme.transitions.easing.sharp : theme.transitions.easing.easeOut,
                duration: showFilePreview
                  ? theme.transitions.duration.leavingScreen
                  : theme.transitions.duration.enteringScreen,
              }),
          }}
        >
          <ConversationFiles docFiles={fortressFiles} />
        </Box>
      </ScreenContainer>
    </>
  );
};

export default observer(AdcbResultPage);
