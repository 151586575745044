// @ts-ignore-next-line
import heicConvert from 'heic-convert';

const convertHeicToPng = async (url: any) => {
  const output = await fetch(url)
    .then(async data => {
      const buffer = Buffer.from(await data.arrayBuffer());
      return heicConvert({ buffer, format: 'PNG' });
    })
    .catch(e => {
      console.log(e);
    });

  return new Blob([output]);
};

export default convertHeicToPng;
