import React from 'react';

import { LoadingSpinner } from '../components/spinner/LoadingSpinner';
interface WithLoadingProps {
  loading?: boolean;
}

const withLoading = <P extends object>(Component: React.ComponentType<P>): React.FC<P & WithLoadingProps> => {
  return ({ loading, ...props }) => {
    return loading ? <LoadingSpinner /> : <Component {...(props as P)} />;
  };
};

export default withLoading;
