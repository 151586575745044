import React, { ChangeEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import { GRAY_COLORS } from '../../../constants/colors';
import { Paths } from '../../../constants/routes';
import { ConversationTypes } from '../../../constants/conversationTypes';
import NoSourcesEmptyState from '../../../assets/icons/emptyStates/NoSourcesEmptyState.svg';

import { useStore } from '../../../hooks/useStore';
import { File } from '../../../models/File';
import FileMenu from '../FileMenu';
import RenameModal from '../RenameModal';
import DeleteModal from '../DeleteModal';
import { useNavigate } from 'react-router';
import AppTable from '../../table/AppTable';
import FileListViewRow from './FileListViewRow';
import EmptyStateComponent from '../../EmptyState/EmptyStateComponent';
import TextInput from '../../inputs/TextInput';
import FlexRowSpaceBetween from '../../utils/flex/FlexRowSpaceBetween';
import { filterObjectsByKeys } from '../../../utils/filterObjectsByKeys';

const inputStyle = {
  width: '100%',
  marginRight: '20px',
  '.MuiInputBase-root': {
    height: '40px',
  },
  '.MuiOutlinedInput-input': {
    padding: '10px 14px',
    width: '100%',
  },
};

const FilesListView = () => {
  const {
    knowledgeBaseStore: {
      knowledgeBaseFiles,
      selectedFile,
      setSelectedFile,
      currentKnowledgeBase,
      updateFileName,
      deleteFile,
    },
    conversationStore: { createConversation, conversationSettingsState },
    localizationStore: { i18next: i18n },
    appState: { fileApi },
  } = useStore();

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [filteredKnowledgeBaseFiles, setFilteredKnowledgeBaseFiles] = useState(knowledgeBaseFiles);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    if (!searchValue) {
      setFilteredKnowledgeBaseFiles(knowledgeBaseFiles);
    }

    const newFilteredKnowledgeBaseFiles = filterObjectsByKeys(knowledgeBaseFiles, ['name'], searchValue);

    setFilteredKnowledgeBaseFiles(newFilteredKnowledgeBaseFiles);
  }, [searchValue, knowledgeBaseFiles]);

  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const onInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;

    setSearchValue(value);
  };

  const onSaveUpdate = async (fileId: string, name: string) => {
    setLoading(true);
    await updateFileName(fileId, name);
    setLoading(false);
    setAnchorEl(null);
  };

  const onDeleteFile = async () => {
    if (selectedFile && currentKnowledgeBase) {
      setLoading(true);
      await deleteFile(selectedFile.id, currentKnowledgeBase.id);
      fileApi.startProcessingKnowledgeBase(currentKnowledgeBase.id);
      setLoading(false);
      setAnchorEl(null);
    }
  };

  const startNewConversation = async () => {
    if (selectedFile) {
      const conversation = await createConversation({
        type: ConversationTypes.Chat,
        model: 'gpt-4-file',
        temperature: conversationSettingsState.accuracy,
        maxTokens: conversationSettingsState.length,
        fileId: selectedFile?.id,
        chat: {
          files: [{ id: selectedFile?.id, name: selectedFile?.name }],
        },
      });

      if (conversation) {
        navigate(`${Paths.CHAT}/${conversation.id}`, { state: { dontRefresh: true } });
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMoreFiles = (e: React.MouseEvent<HTMLButtonElement>, file: File) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedFile(file);
    setAnchorEl(e.currentTarget);
  };

  const tableHeaderColumnNames = [
    {
      name: 'Name',
      width: 20,
    },
    {
      name: 'Source',
      width: 1,
    },
    {
      name: 'User',
      width: 10,
    },
    {
      name: 'Date',
      width: 8,
    },
    {
      name: 'Redacted',
      width: 4,
    },
    {
      name: 'Status',
      width: 8,
    },
    {
      name: 'More',
      width: 1,
    },
  ];

  return (
    <>
      <FlexRowSpaceBetween sx={{ marginTop: '24px' }}>
        <TextInput
          id="search-input"
          value={searchValue}
          onChange={onInputChange}
          placeholder={'Search'}
          name={'searchFilter'}
          variant={'outlined'}
          isSearchField
          sx={{ ...inputStyle }}
        />
      </FlexRowSpaceBetween>

      {filteredKnowledgeBaseFiles && filteredKnowledgeBaseFiles.length === 0 ? (
        <EmptyStateComponent
          image={NoSourcesEmptyState}
          boldSubtitleText={searchValue ? 'No files match your filter' : 'No sources added yet'}
          text={searchValue ? 'Click “Add Source” to add more files' : 'Click “Add Source” to get started'}
        />
      ) : (
        <AppTable
          style={{ flex: 1, marginTop: '24px', maxHeight: 'calc(100% - 132px)' }}
          tableHeaderColumnNames={tableHeaderColumnNames}
        >
          {filteredKnowledgeBaseFiles?.map(file => {
            return <FileListViewRow file={file} handleClickMoreFiles={handleClickMoreFiles} />;
          })}

          <FileMenu
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            options={[
              // TODO: this might prove useful in the future
              // {
              //   name: i18n.t('knowledgeBase.menu.startNewChat'),
              //   icon: <AddIcon sx={{ color: GRAY_COLORS.GRAY_6 }} />,
              //   onClick: startNewConversation,
              // },
              {
                name: i18n.t('knowledgeBase.menu.rename'),
                icon: <EditIcon sx={{ color: GRAY_COLORS.GRAY_6 }} />,
                onClick: () => setShowRenameModal(true),
              },
              {
                name: i18n.t('knowledgeBase.menu.delete'),
                icon: <DeleteIcon sx={{ color: GRAY_COLORS.GRAY_6 }} />,
                onClick: () => setShowDeleteModal(true),
                itemSx: { borderTop: `1px solid ${GRAY_COLORS.GRAY_2}` },
              },
            ]}
          />

          <RenameModal isOpen={showRenameModal} onClose={() => setShowRenameModal(false)} onSave={onSaveUpdate} />
          <DeleteModal isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)} onSave={onDeleteFile} />
        </AppTable>
      )}
    </>
  );
};

export default observer(FilesListView);
