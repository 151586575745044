import { ApiConfig } from './ApiConfig';
import { ComplianceModel, UpdateComplianceRuleDto } from '../models/ComplianceModel';

export class ComplianceApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  getCompliances(): Promise<Array<ComplianceModel>> {
    return this.apiConfig.sendRequest('GET', `/compliance`);
  }

  updateCompliances(updateComplianceRuleDto: Array<UpdateComplianceRuleDto>) {
    return this.apiConfig.sendRequest('PATCH', '/compliance', updateComplianceRuleDto);
  }
}
