import CancelIcon from '@mui/icons-material/Cancel';
import { Typography } from '@mui/material';

import { COLOR_ERROR_ICON } from '../../../../../constants/colors';
import { fileStatus } from '../../../../../constants/fileStatus';

import Flex from '../../../../../components/utils/flex/Flex';
import { useStore } from '../../../../../hooks/useStore';

interface FileFailedProcessingProps {
  status: fileStatus.FAILED_REDACTION | fileStatus.FAILED_PROCESSING;
}
const FileFailedProcessingComponent = ({ status }: FileFailedProcessingProps) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  return (
    <Flex sx={{ width: '100%', gap: '8px', marginTop: '24px', alignItems: 'center' }}>
      <CancelIcon sx={{ fill: COLOR_ERROR_ICON }} />
      <Typography variant={'body2'}>
        {status === fileStatus.FAILED_PROCESSING
          ? i18n.t('conversation.file.failedAnalyzing')
          : i18n.t('conversation.file.failedRedaction')}
      </Typography>
    </Flex>
  );
};

export default FileFailedProcessingComponent;
