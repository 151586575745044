export const SIZES_BIG = '1350px';
export const SIZES_MEDIUM = '1100px';
export const SIZES_SMALL_MEDIUM = '1024px';
export const SIZES_SMALL = '800px';
export const SIZES_TINY_SMALL = '500px';
export const SIZES_TINY = '400px';

export const SIZES_NUMBER_BIG = 1350;
export const SIZES_NUMBER_MEDIUM = 1100;
export const SIZES_NUMBER_SMALL_MEDIUM = 1024;
export const SIZES_NUMBER_SMALL = 800;
export const SIZES_NUMBER_TINY_SMALL = 500;
export const SIZES_NUMBER_TINY = 400;
