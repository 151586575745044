import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import { KnowledgeBaseApi } from '../api/KnowledgeBaseApi';
import {
  AddFilesToKnowledgeBaseDto,
  CreateKnowledgeBaseDto,
  EMPTY_KNOWLEDGE_MODEL,
  KnowledgeBaseModel,
} from '../models/KnowledgeBaseModel';
import { File } from '../models/File';
import { FileApi } from '../api/FileApi';
import { Team } from '../models/Team';

export class KnowledgeBaseStore {
  knowledgeBaseApi: KnowledgeBaseApi;
  fileApi: FileApi;
  knowledgeBases: KnowledgeBaseModel[] = [EMPTY_KNOWLEDGE_MODEL];
  uploadInProgress: boolean = false;
  uploadProgress: number = 0;
  showFilePreview: boolean = false;
  selectedFile?: File;
  selectedTeam?: Team;
  currentKnowledgeBase: KnowledgeBaseModel = EMPTY_KNOWLEDGE_MODEL;
  knowledgeBaseFiles: File[] = [];
  activeFileTab: number = 0;

  constructor(knowledgeBaseApi: KnowledgeBaseApi, fileApi: FileApi) {
    this.knowledgeBaseApi = knowledgeBaseApi;
    this.fileApi = fileApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  setSelectedFile(file: File) {
    runInAction(() => {
      this.selectedFile = file;
    });
  }

  setActiveFileTab(tab: number) {
    runInAction(() => {
      this.activeFileTab = tab;
    });
  }

  setCurrentKnowledgeBase(kb: KnowledgeBaseModel) {
    runInAction(() => {
      this.currentKnowledgeBase = kb;
      this.knowledgeBaseFiles = kb.files;
    });
  }

  setCurrentKnowledgeBaseStatus(status: string) {
    runInAction(() => {
      this.currentKnowledgeBase.status = status;
    });
  }

  async findOneWithoutUpdatingStore(id: string) {
    return await this.knowledgeBaseApi.findOne(id);
  }

  async getAllKnowledgeBasesByOrganization(): Promise<KnowledgeBaseModel[]> {
    const knowledgeBases = await this.knowledgeBaseApi.getAllKnowledgeBasesByOrganization();

    runInAction(() => {
      this.knowledgeBases = knowledgeBases?.length ? knowledgeBases : [EMPTY_KNOWLEDGE_MODEL];
    });

    return knowledgeBases;
  }

  async getKnowledgeBaseAvailableEmbeddingsModels() {
    return this.knowledgeBaseApi.getKnowledgeBaseAvailableEmbeddingsModels();
  }

  async getKnowledgeBaseAvailableTextModels() {
    return this.knowledgeBaseApi.getKnowledgeBaseAvailableTextModels();
  }

  async startIngestionJob() {
    const updatedKnowledgeBase = await this.knowledgeBaseApi.startIngestionJob(this.currentKnowledgeBase.id);

    runInAction(() => {
      this.currentKnowledgeBase = updatedKnowledgeBase;
    });
  }

  async getIngestionJob() {
    if (!this.currentKnowledgeBase.id) {
      return;
    }

    const updatedKnowledgeBase = await this.knowledgeBaseApi.getIngestionJob(this.currentKnowledgeBase.id);

    runInAction(() => {
      this.currentKnowledgeBase = updatedKnowledgeBase;
    });
  }

  async addFilesToKnowledgeBase(body: AddFilesToKnowledgeBaseDto) {
    const knowledgeBase = await this.knowledgeBaseApi.addFilesToKnowledgeBase(body);

    runInAction(() => {
      this.currentKnowledgeBase = knowledgeBase;
      this.knowledgeBaseFiles = knowledgeBase.files;
    });
  }

  async createKnowledgeBase(body: CreateKnowledgeBaseDto): Promise<KnowledgeBaseModel> {
    const knowledgeBase = await this.knowledgeBaseApi.createKnowledgeBase(body);

    await this.getAllKnowledgeBasesByOrganization();
    return knowledgeBase;
  }

  async update(knowledgeBaseId: string, data: Partial<CreateKnowledgeBaseDto>) {
    const updatedKnowledgeBase = await this.knowledgeBaseApi.updateKnowledgeBase(knowledgeBaseId, data);

    const indexUpdatedKnowledgeBase = this.knowledgeBases.findIndex(
      knowledgeBase => knowledgeBase.id === knowledgeBaseId
    );

    if (indexUpdatedKnowledgeBase > -1) {
      const newKnowledgeBases = [...this.knowledgeBases];
      newKnowledgeBases[indexUpdatedKnowledgeBase] = updatedKnowledgeBase;

      runInAction(() => {
        this.knowledgeBases = newKnowledgeBases;
      });
    }

    return updatedKnowledgeBase;
  }

  async deleteKnowledgeBase(knowledgeBaseId: string) {
    await this.knowledgeBaseApi.deleteKnowledgeBase(knowledgeBaseId);

    const indexOfDeletedKnowledgeBase = this.knowledgeBases.findIndex(
      knowledgeBase => knowledgeBase.id === knowledgeBaseId
    );

    if (indexOfDeletedKnowledgeBase > -1) {
      const newKnowledgeBases = [...this.knowledgeBases];
      newKnowledgeBases.splice(indexOfDeletedKnowledgeBase, 1);

      runInAction(() => {
        this.knowledgeBases = newKnowledgeBases;
      });
    }
  }

  setIsUploadInProgress(value: boolean) {
    runInAction(() => {
      this.uploadInProgress = value;
    });
  }

  setUploadProgress(value: number) {
    runInAction(() => {
      this.uploadInProgress = true;
      this.uploadProgress = value;
    });
  }

  async cancelFileUpload() {}

  async updateFileName(fileId: string, name: string) {
    if (this.selectedFile) {
      const file: File = { ...this.selectedFile, name };
      this.setSelectedFile(file);
    }

    await this.fileApi.updateFileName(fileId, name);

    const updatedFile = await this.fileApi.getById(fileId);

    const indexOfUpdatedFile = this.knowledgeBaseFiles.findIndex(file => updatedFile.id === file.id);

    runInAction(() => {
      if (indexOfUpdatedFile > -1) {
        const newFiles = [...this.knowledgeBaseFiles];
        newFiles[indexOfUpdatedFile] = updatedFile;
        this.knowledgeBaseFiles = newFiles;
      }
    });
  }

  async deleteFile(fileId: string, knowledgeBaseId: string) {
    await this.knowledgeBaseApi.deleteFile(fileId, knowledgeBaseId);

    runInAction(() => {
      const indexOfDeletedItem = this.knowledgeBaseFiles.findIndex(file => file.id === fileId);

      if (indexOfDeletedItem > -1) {
        const filesArray = [...this.knowledgeBaseFiles];
        filesArray.splice(indexOfDeletedItem, 1);
        this.knowledgeBaseFiles = filesArray;
      }
    });
  }
}
