import { ApiConfig } from './ApiConfig';
import {
  UpdateUserProfilePictureDto,
  UserModelInvite,
  UserModelUpdate,
  UserPasswordUpdate,
  UsersModelUpdate,
} from '../models/UserModel';

export class UserApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  async refreshToken() {
    return await this.apiConfig.sendRequest('POST', '/users/refresh-token');
  }

  async getUserData() {
    return await this.apiConfig.sendRequest('GET', '/users/my-profile', null);
  }

  async getUserDataById(id: string) {
    return await this.apiConfig.sendRequest('GET', `/users/${id}`, null);
  }

  async getUserSummaryById(id: string) {
    return await this.apiConfig.sendRequest('GET', `/users/summary-details/${id}`, null);
  }

  async updateUserData(data: UserModelUpdate) {
    return await this.apiConfig.sendRequest('PATCH', '/users/my-profile', data);
  }

  async logoutAmazonQ() {
    return await this.apiConfig.sendRequest('PATCH', '/users/logout-amazon-q');
  }

  async filterUserByRoleAndStatus(role: string, archived: boolean) {
    return await this.apiConfig.sendRequest('GET', `/users?role=${role}&archived=${archived}`);
  }

  async editUser(data: UsersModelUpdate, id: string) {
    return await this.apiConfig.sendRequest('PATCH', `/users/${id}`, data);
  }

  async updateUserProfilePictureData(id: string, data: UpdateUserProfilePictureDto) {
    return await this.apiConfig.sendRequest('PATCH', `/users/profile-picture/${id}`, data);
  }

  async deleteUser(id: string) {
    return await this.apiConfig.sendRequest('DELETE', `/users/${id}`);
  }

  async enableUser(id: string) {
    return await this.apiConfig.sendRequest('POST', `/users/enable-user/${id}`);
  }

  async getUsersByUserOrganization() {
    return await this.apiConfig.sendRequest('GET', '/users', null);
  }

  async getInactiveUsersByUserOrganization() {
    return await this.apiConfig.sendRequest('GET', '/users?archived=true', null);
  }

  async updateUserPassword(data: UserPasswordUpdate) {
    return await this.apiConfig.sendRequest('PATCH', '/Users/password', data);
  }

  async inviteUserToOrganization(data: UserModelInvite) {
    return await this.apiConfig.sendRequest('POST', '/organizations/invite-user', data);
  }

  async inviteUsersToOrganization({
    users,
    organizationId,
  }: {
    users: UserModelInvite[];
    organizationId: string | undefined;
  }) {
    return await this.apiConfig.sendRequest('POST', '/organizations/invite-users', { users, organizationId });
  }
}
