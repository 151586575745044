import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material';
import { i18n } from 'i18next';

import { Roles, UserRoles } from '../../constants/userRoles';
import {
  COLOR_MANDATORY,
  COLOR_MANDATORY_BACKGROUND,
  COLOR_MEMBER,
  COLOR_MEMBER_BACKGROUND,
} from '../../constants/colors';

import { useStore } from '../../hooks/useStore';

interface Props {
  type: string | number;
  label?: string;
  color?: string;
  sx?: SxProps;
  icon?: React.ReactNode;
  onClick?: (arg1?: any) => void;
  ellipsis?: boolean;
}

export const getCardInfo = (type: string | number, i18n: i18n) => {
  switch (type) {
    case Roles.admin:
    case UserRoles.admin:
      return {
        text: i18n.t('roles.admin.message'),
        backgroundColor: COLOR_MANDATORY_BACKGROUND,
        textColor: COLOR_MANDATORY,
      };
    case Roles.organizationAdmin:
    case UserRoles.organizationAdmin:
      return {
        text: i18n.t('roles.organizationAdmin.message'),
        backgroundColor: COLOR_MANDATORY_BACKGROUND,
        textColor: COLOR_MANDATORY,
      };
    case Roles.member:
    case UserRoles.member:
      return {
        text: i18n.t('roles.member.message'),
        backgroundColor: COLOR_MEMBER_BACKGROUND,
        textColor: COLOR_MEMBER,
      };
    default:
      return {
        text: '',
        backgroundColor: '',
        textColor: '',
      };
  }
};

const Role = ({ type, label, sx, icon, onClick, color, ellipsis = false }: Props) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  const { text } = getCardInfo(type, i18n);

  return (
    <Box
      onClick={onClick}
      sx={{
        ...sx,
      }}
    >
      <Typography
        variant={'subtitle2'}
        color={color || '#212121'}
        sx={ellipsis ? { whiteSpace: 'nowrap', maxWidth: '110px', overflow: 'hidden', textOverflow: 'ellipsis' } : {}}
      >
        {label || text}
      </Typography>
      {icon}
    </Box>
  );
};

export default Role;
