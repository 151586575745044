import React, { useRef } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useIdleTimer } from 'react-idle-timer';
import { observer } from 'mobx-react';

import { LocalStorageKeys } from '../constants/localStorageKeys';

import { useStore } from '../hooks/useStore';
import Flex from '../components/utils/flex/Flex';

const IDLE_TIMER = 1000 * 60 * 60 * 2;
const IDLE_LOOP_TIMER = 100 * 60 * 30;

const AuthenticationProvider = ({ children }: any) => {
  const {
    localizationStore: { i18next: i18n },
    authStore: { updateToken },
  } = useStore();

  const { addToast } = useToasts();

  const idleLoop = useRef<NodeJS.Timeout | null>(null);

  const checkWhileBeingIdle = (token: any) => {
    if (token && (!token.expires || Date.now() > token.expires)) {
      addToast(`${i18n.t('sessionExpired.message')}`);
    }
  };

  const handleExpiredToken = async () => {
    const token = localStorage.getItem(LocalStorageKeys.accessToken);
    const tokenExpirationDate = localStorage.getItem(LocalStorageKeys.expirationTime);

    if (token && (!tokenExpirationDate || Date.now() > new Date(tokenExpirationDate).getTime())) {
      await updateToken();
    } else {
      idleLoop.current = setInterval(() => {
        checkWhileBeingIdle(token);
      }, IDLE_LOOP_TIMER);
    }
  };

  const handleOnIdle = async () => {
    addToast(`${i18n.t('userActive.message')}`);

    await handleExpiredToken();
  };

  const handleOnActive = async () => {
    idleLoop.current && clearInterval(idleLoop.current);
    addToast(`${i18n.t('userActive.message')}`);

    await handleExpiredToken();
  };

  useIdleTimer({
    timeout: IDLE_TIMER,
    onIdle: () => handleOnIdle(),
    onActive: handleOnActive,
  });

  return (
    <>
      <Flex>{children}</Flex>
    </>
  );
};

export default observer(AuthenticationProvider);
