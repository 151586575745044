import React, { useState } from 'react';
import omit from 'lodash/omit';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { Typography } from '@mui/material';

import { SIZES_NUMBER_SMALL } from '../../../constants/sizes';

import CustomModal from '../CustomModal';
import { useStore } from '../../../hooks/useStore';
import { Headline3 } from '../../typography/Headlines';
import { GRAY_COLORS } from '../../../constants/colors';
import Flex from '../../utils/flex/Flex';
import Button from '../../buttons/Button';
import CompareAnswerFromModelCard from './components/CompareAnswerFromModelCard';
import { Chat } from '../../../models/Chat';
import { CreateConversationDto } from '../../../models/Conversation';
import { Paths } from '../../../constants/routes';
import { useNavigate } from 'react-router';

const CompareAnswersFromDifferentModelsModal = () => {
  const {
    conversationStore: {
      secondaryConversationSettingsState,
      conversationSettingsState,
      selectedPromptId,
      createConversation,
    },
    uiStore: { isOpenAiCompareAnswersModalOpen, toggleOpenAiCompareAnswersModalOpen },
  } = useStore();

  const isModalFullScreenWidth = useMediaQuery({ maxWidth: SIZES_NUMBER_SMALL });
  const isModalFullScreenHeight = useMediaQuery({ maxHeight: 600 });

  const [selectedModelIndex, setSelectedModelIndex] = useState(0);
  const [chats, setChats] = useState<Chat[]>();

  const navigate = useNavigate();

  const compareAnswerCardOnClick = (index: number, chats: Chat[]) => {
    if (index === selectedModelIndex) {
      setSelectedModelIndex(0);
      setChats([]);
      return;
    }

    setChats(chats);
    setSelectedModelIndex(index);
  };

  const onCreateConversationClick = async () => {
    const conversationSettings =
      selectedModelIndex === 1 ? conversationSettingsState : secondaryConversationSettingsState;
    const promptId = selectedPromptId;

    const createConversationDto: CreateConversationDto = {
      maxTokens: conversationSettings.length,
      model: conversationSettings.model,
      temperature: conversationSettings.accuracy,
      providerName: conversationSettings.providerName,
      type: 'chat',
      chats: chats?.map((chat, index) => {
        const chatDto = omit(chat, ['id', 'error', 'createdAt', 'updatedAt', 'organizationId', 'userId']);

        if (index === 0) {
          return {
            ...chatDto,
            ...(promptId && { promptId }),
          };
        }

        return chatDto;
      }),
    };

    const createdConversation = await createConversation(createConversationDto, true);

    if (createdConversation && createdConversation.id) {
      navigate(`${Paths.CHAT}/${createdConversation.id}`, { state: { dontRefresh: true } });
    }

    toggleOpenAiCompareAnswersModalOpen(false);
  };

  return (
    <CustomModal
      isOpen={isOpenAiCompareAnswersModalOpen}
      onClose={toggleOpenAiCompareAnswersModalOpen}
      sx={{
        display: 'flex',
        overflow: 'scroll',
        borderRadius: '8px',
        minWidth: isModalFullScreenWidth ? '97vw' : '800px',
        height: isModalFullScreenHeight ? '90vh' : 'unset',
        padding: '24px',
      }}
    >
      <Flex sx={{ flexDirection: 'column', flex: 1, height: '100%' }}>
        <Headline3>Compare Answers</Headline3>
        <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_500 }}>
          Select one of the options below to continue the conversation
        </Typography>

        <Flex sx={{ marginTop: '24px' }}>
          <CompareAnswerFromModelCard
            completionSettings={conversationSettingsState}
            isSelected={selectedModelIndex === 1}
            onClick={chats => compareAnswerCardOnClick(1, chats)}
          />
          <Box sx={{ width: '16px' }} />
          <CompareAnswerFromModelCard
            completionSettings={secondaryConversationSettingsState}
            isSelected={selectedModelIndex === 2}
            onClick={chats => compareAnswerCardOnClick(2, chats)}
          />
        </Flex>

        <Flex sx={{ justifyContent: 'flex-end', marginTop: '24px' }}>
          <Box>
            <Button variant={'outlined'} onClick={() => toggleOpenAiCompareAnswersModalOpen(false)}>
              Cancel
            </Button>
          </Box>
          <Box sx={{ marginLeft: '16px' }}>
            <Button disabled={!selectedModelIndex} onClick={onCreateConversationClick}>
              Continue Conversation
            </Button>
          </Box>
        </Flex>
      </Flex>
    </CustomModal>
  );
};

export default observer(CompareAnswersFromDifferentModelsModal);
