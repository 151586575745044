import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import { COLOR_BUTTON_CONTAINED_ERROR, GRAY_COLORS } from '../../../../../constants/colors';

import { useStore } from '../../../../../hooks/useStore';

interface ErrorMessageComponentProps {
  errorMessage: string;
}

const ErrorMessageComponent = ({ errorMessage }: ErrorMessageComponentProps) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  const [showDetails, setShowDetails] = useState(false);

  return (
    <Stack sx={{ gap: '8px' }}>
      <Typography variant={'body2'} style={{ color: GRAY_COLORS.GRAY_6 }}>
        {i18n.t('common.chatErrorMessage')}
      </Typography>
      <Typography
        variant={'body2'}
        sx={{
          color: GRAY_COLORS.GRAY_6,
          cursor: 'pointer',
          textDecoration: 'underline',
          '&:hover': { color: GRAY_COLORS.GRAY_9 },
        }}
        onClick={() => setShowDetails(prevState => !prevState)}
      >
        {showDetails ? 'Show less' : 'Show more'}
      </Typography>
      {showDetails && (
        <Typography variant={'body2'} style={{ color: COLOR_BUTTON_CONTAINED_ERROR }}>
          {errorMessage}
        </Typography>
      )}
    </Stack>
  );
};

export default ErrorMessageComponent;
