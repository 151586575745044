export interface SummaryItem {
  total: number;
  new: number;
}

export interface Summary {
  users: SummaryItem;
  questions: SummaryItem;
  violations: SummaryItem;
}

export const SummaryInitial = {
  users: {
    total: 0,
    new: 0,
  },
  questions: {
    total: 0,
    new: 0,
  },
  violations: {
    total: 0,
    new: 0,
  },
};
