import React from 'react';

import { GRAY_COLORS } from '../../constants/colors';

import { WrapperComponentModel } from '../../models/WrapperComponentModel';
import { Typography } from '@mui/material';

const Subtitle = ({ children, sx }: WrapperComponentModel) => {
  return (
    <Typography
      sx={{
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '22px',
        color: GRAY_COLORS.GRAY_500,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export default Subtitle;
