export const UserRoles = {
  admin: 'Admin',
  organizationAdmin: 'Organization admin',
  member: 'Member',
};

export const Roles = {
  admin: 'admin',
  organizationAdmin: 'organizationAdmin',
  member: 'member',
};
