import React from 'react';

import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import { GRAY_COLORS } from '../../constants/colors';

const CopyRightContainer = styled.div`
  text-align: center;
  margin: 32px 0;
`;
const CopyRight = () => (
  <CopyRightContainer>
    <Typography
      variant="body2"
      sx={{ color: GRAY_COLORS.GRAY_500, lineHeight: '24px' }}
    >{`© ${new Date().getFullYear()} AVM. All Rights Reserved.`}</Typography>
  </CopyRightContainer>
);

export default CopyRight;
