import React from 'react';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';
import { Autocomplete, Stack } from '@mui/material';

import { COLOR_TEXT_PRIMARY, GRAY_COLORS } from '../../../../constants/colors';

import { useStore } from '../../../../hooks/useStore';
import { CustomSlider } from '../../../cards/newConversationCard/SelectModelDetails';
import { OrganizationModel } from '../../../../models/OrganizationModel';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

interface AdvancedSettingsProps {
  setSelectedModel: React.Dispatch<React.SetStateAction<OrganizationModel>>;
  selectedModel: OrganizationModel;
  temperature: number;
  setTemperature: React.Dispatch<React.SetStateAction<number>>;
  length: number;
  setLength: React.Dispatch<React.SetStateAction<number>>;
  setIsDirtyState: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdvancedSettings = ({
  setSelectedModel,
  selectedModel,
  temperature,
  setTemperature,
  length,
  setLength,
  setIsDirtyState,
}: AdvancedSettingsProps) => {
  const {
    localizationStore: { i18next: i18n },
    modelStore: { organizationModels },
  } = useStore();

  const {
    config: { maxTokens, maxTemperature },
  } = selectedModel;

  return (
    <>
      <Stack>
        <Typography variant={'body2'} sx={{ fontWeight: 500, color: COLOR_TEXT_PRIMARY, marginBottom: '8px' }}>
          Recommended AI Model
        </Typography>

        <Autocomplete
          disableClearable={true}
          value={selectedModel}
          getOptionLabel={(option: OrganizationModel) => `${option.providerName} - ${option.displayName}`}
          renderInput={({ inputProps, ...rest }) => {
            return <AutoCompleteTextField {...rest} inputProps={{ ...inputProps }} placeholder={'Select'} />;
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={organizationModels.filter(model => !!model.isActive)}
          onChange={(event, value) => {
            setSelectedModel(value || undefined);
            setIsDirtyState(true);
          }}
        />
      </Stack>

      {!selectedModel && (
        <Typography
          variant={'body2'}
          sx={{ fontWeight: 500, color: GRAY_COLORS.GRAY_10, marginBottom: '4px', marginTop: '12px' }}
        >
          You need to select a recommended model before setting either temperature or length!
        </Typography>
      )}

      <Stack style={selectedModel ? { padding: '16px 0' } : { padding: '16px 0', opacity: 0.5, pointerEvents: 'none' }}>
        <Typography variant={'body2'} sx={{ fontWeight: 500, color: COLOR_TEXT_PRIMARY, marginBottom: '8px' }}>
          {i18n.t('home.newConversation.settings.accuracy')}
        </Typography>
        <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_500, marginTop: '4px' }}>
          {i18n.t('home.newConversation.settings.accuracyDetails')}
        </Typography>
        <CustomSlider
          min={0}
          max={maxTemperature?.value || 2}
          step={0.1}
          onChange={(event, value) => {
            setTemperature(value as number);
            setIsDirtyState(true);
          }}
          value={temperature}
          valueLabelDisplay={'on'}
        />
      </Stack>

      <Stack style={selectedModel ? { padding: '16px 0' } : { padding: '16px 0', opacity: 0.5, pointerEvents: 'none' }}>
        <Typography variant={'body2'} sx={{ fontWeight: 500, color: COLOR_TEXT_PRIMARY, marginBottom: '8px' }}>
          {i18n.t('home.newConversation.settings.length')}
        </Typography>
        <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_500, marginTop: '4px' }}>
          {i18n.t('home.newConversation.settings.lengthDetails')}
        </Typography>
        <CustomSlider
          min={0}
          max={maxTokens?.value || 2024}
          onChange={(event, value) => {
            setLength(value as number);
            setIsDirtyState(true);
          }}
          value={length}
          valueLabelDisplay={'on'}
        />
      </Stack>
    </>
  );
};

const AutoCompleteTextField = styled(TextField)`
  & .MuiInputBase-root {
    height: unset;
  }
  & .MuiInputLabel-root {
    top: 0
  },
  & .MuiInputLabel-shrink {
    top: 0
  },
`;

export default observer(AdvancedSettings);
