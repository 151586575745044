import React, { ChangeEvent, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';

import { GRAY_COLORS } from '../../constants/colors';
import { SxProps } from '@mui/material';

export type SearchBarProps = {
  onChange: (searchValue: string) => void;
  onCancelSearch?: () => void;
  placeholder?: string;
  disabled?: boolean;
  sx?: SxProps;
};

const inputStyle = {
  background: 'white',
  borderRadius: '8px',
  borderColor: GRAY_COLORS.GRAY_300,
  boxShadow: 'none',
  height: '40px',
  width: '100%',
  fontSize: '14px',
};

const iconButtonStyles = {
  marginRight: '-8px',
  borderRadius: '50%',
};

export const CustomSearchBar: React.FC<SearchBarProps> = ({
  onChange,
  onCancelSearch,
  placeholder,
  disabled,
  sx,
}: SearchBarProps) => {
  const [searchValue, setSearchValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;

    onChange(value);
    setSearchValue(value);
  };

  const handleCancel = () => {
    setSearchValue('');
    onCancelSearch?.();
  };

  return (
    <TextField
      sx={{ ...inputStyle, ...sx }}
      placeholder={placeholder}
      disabled={disabled}
      onChange={handleChange}
      value={searchValue}
      InputProps={{
        style: inputStyle,
        endAdornment: searchValue ? (
          <IconButton onClick={handleCancel} sx={iconButtonStyles}>
            <Close sx={{ color: GRAY_COLORS.GRAY_500 }} />
          </IconButton>
        ) : (
          <IconButton sx={iconButtonStyles}>
            <Search sx={{ color: GRAY_COLORS.GRAY_500 }} />
          </IconButton>
        ),
      }}
    />
  );
};
