import Typography from '@mui/material/Typography';

import CustomModal from './CustomModal';
import TextInput from '../inputs/TextInput';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useStore } from '../../hooks/useStore';
import Flex from '../utils/flex/Flex';
import { COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';
import { Box } from '@mui/material';
import Button from '../buttons/Button';
import { observer } from 'mobx-react';

interface EditConversationTitleModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const EditConversationTitleModal = ({ isOpen, onClose }: EditConversationTitleModalProps) => {
  const {
    localizationStore: { i18next: i18n },
    conversationStore: { currentConversation, updateConversation },
  } = useStore();

  const [title, setTitle] = useState(currentConversation.title);
  const [dirtyState, setDirtyState] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setTitle(event?.target?.value || '');
    setDirtyState(true);
  };

  const onKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSave();
    }
  };

  const onSave = async () => {
    setLoading(true);
    await updateConversation({ id: currentConversation.id, title }, true);
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    setTitle(currentConversation.title);
  }, [currentConversation]);

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} sx={{ padding: '24px' }}>
      <Flex
        sx={{
          flexDirection: 'column',
          width: '500px',
          backgroundColor: COLOR_WHITE,
          maxHeight: '90vh',
        }}
      >
        <Typography sx={{ marginBottom: '16px', fontWeight: '600' }}>
          {i18n.t('conversation.editTitle.modal.title')}
        </Typography>

        <Typography sx={{ marginBottom: '8px' }}> {i18n.t('conversation.editTitle.modal.label')}</Typography>

        <TextInput
          id="title"
          placeholder={i18n.t('prompts.textField.title.placeHolder')}
          name={'title'}
          value={title}
          onKeyUp={onKeyUp}
          onChange={onChange}
          variant={'outlined'}
          type={'text'}
          testId={'edit-conversation-title'}
          sx={{
            marginBottom: '16px',
            '& > .MuiInputBase-root': { height: '48px !important' },
            '& .MuiInputLabel-root.Mui-error': { top: '-4px' },
            '& .MuiInputLabel-root': { top: '-4px' },
            '& .MuiInputLabel-shrink': { top: 0 },
          }}
        />

        <Flex sx={{ marginTop: '24px', justifyContent: 'flex-end' }}>
          <Button
            onClick={onClose}
            variant={'outlined'}
            sx={{
              marginRight: '16px',
              width: 'fit-content',
              borderColor: `${GRAY_COLORS.GRAY_2} !important`,
              color: `${GRAY_COLORS.GRAY_9} !important`,
            }}
          >
            {i18n.t('common.cancel.action')}
          </Button>
          <Box>
            <Button onClick={onSave} disabled={!dirtyState} loading={loading}>
              {i18n.t('common.save.action')}
            </Button>
          </Box>
        </Flex>
      </Flex>
    </CustomModal>
  );
};

export default observer(EditConversationTitleModal);
