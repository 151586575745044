import React, { useEffect, useLayoutEffect } from 'react';
import { Provider, observer } from 'mobx-react';
import { ToastProvider } from 'react-toast-notifications';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';

import { LocalStorageKeys } from './constants/localStorageKeys';

import AppRoutes from './routes/AppRoutes';
import { useStore } from './hooks/useStore';
import { ThemeFactoryWrapper } from './theme';
import { Notification } from './components/notification/Notification';

const generateClassName = createGenerateClassName({
  seed: 'app1',
});

const App = () => {
  const {
    authStore,
    uiStore: { toastPlacement, setNewDimensions },
    localizationStore: { setLocation },
  } = useStore();

  useLayoutEffect(() => {
    window.addEventListener('resize', setNewDimensions);
    setNewDimensions();

    return () => window.removeEventListener('resize', setNewDimensions);
  }, []);

  useEffect(() => {
    const language = localStorage.getItem(LocalStorageKeys.Language);
    setLocation(language || 'en_US');
  }, []);

  return (
    <Provider store={authStore}>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeFactoryWrapper>
          <DndProvider backend={HTML5Backend}>
            <ToastProvider components={{ Toast: Notification }} placement={toastPlacement} autoDismiss={5000}>
              <AppRoutes />
            </ToastProvider>
          </DndProvider>
        </ThemeFactoryWrapper>
      </StylesProvider>
    </Provider>
  );
};

export default observer(App);
