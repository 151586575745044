import React, { BaseSyntheticEvent, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, SxProps } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import { COLOR_CHART_WARNING, COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';

import FlexColumnStart from '../utils/flex/FlexColumnStart';
import Flex from '../utils/flex/Flex';

export type OptionsRowButton = {
  optionName: string;
  optionOnClick: (item: any) => void;
  optionLogo?: React.ReactElement;
  disabled?: boolean;
};

type OptionsButtonProps = {
  item: any;
  options: OptionsRowButton[];
  disabled?: boolean;
  isHorizontal?: boolean;
  sx?: SxProps;
  isIcon?: boolean;
};

const menuStyle = {
  '&.MuiList-root-MuiMenu-list': {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLOR_WHITE,
  },
};

const menuItemStyle = {
  '&.MuiButtonBase-root': {
    padding: '12px',
    minHeight: '40px',
  },
};

export const OptionsButton: React.FC<OptionsButtonProps> = props => {
  const { options, item, disabled, isHorizontal, sx, isIcon } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleOptionClick = (event: BaseSyntheticEvent, option: OptionsRowButton) => {
    //before the popup closes, you can see the changed menu (if it changed)
    //add spinner while the call to the API finishes
    handleClose(event);
    option.optionOnClick(item);
  };

  const handleClose = (event: BaseSyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      {isIcon ? (
        <IconButton
          disabled={disabled ? disabled : false}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          sx={sx}
        >
          {isHorizontal ? <MoreHorizIcon /> : <MoreVertIcon />}
        </IconButton>
      ) : (
        <Button
          disabled={disabled ? disabled : false}
          variant={'outlined'}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          sx={{
            borderColor: `${GRAY_COLORS.GRAY_2} !important`,
            color: `${GRAY_COLORS.GRAY_9} !important`,
            ...sx,
          }}
        >
          {isHorizontal ? <MoreHorizIcon /> : <MoreVertIcon />}
        </Button>
      )}

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        elevation={1}
        sx={menuStyle}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            marginTop: 8,
            maxHeight: 216,
            width: 'fit-content',
            boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.10)',
          },
        }}
        MenuListProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: COLOR_WHITE,
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={`${option.optionName}-${index}`}
            sx={menuItemStyle}
            onClick={event => {
              if (!option.disabled) {
                handleOptionClick(event, option);
              }
            }}
            disabled={option.disabled}
          >
            {option.disabled ? (
              <FlexColumnStart>
                <Flex>
                  {option.optionLogo && <ListItemIcon key={`option_icon_${index}`}>{option.optionLogo}</ListItemIcon>}
                  <Typography>{option.optionName}</Typography>
                </Flex>
                <Flex sx={{ gap: '4px' }}>
                  <WarningIcon sx={{ fill: COLOR_CHART_WARNING, width: '12px' }} />
                  <Typography fontSize={'12px'}>Model is not enabled anymore, contact the administrator</Typography>
                </Flex>
              </FlexColumnStart>
            ) : (
              <Flex>
                {option.optionLogo && <ListItemIcon key={`option_icon_${index}`}>{option.optionLogo}</ListItemIcon>}
                <Typography>{option.optionName}</Typography>
              </Flex>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
