import React from 'react';

interface CustomReferenceComponentProps {
  referenceId: number;
  children: React.ReactNode;
  onClick?: (id: number) => void;
}

const AmazonQReferenceComponent = ({ referenceId, children, onClick }: CustomReferenceComponentProps) => {
  return (
    <span
      style={{
        color: '#1976d2',
        cursor: 'pointer',
        textDecoration: 'underline',
      }}
      onClick={() => onClick && onClick(referenceId)}
    >
      {children}
    </span>
  );
};

export default AmazonQReferenceComponent;
