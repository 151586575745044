import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { Headline3 } from '../typography/Headlines';
import { GRAY_COLORS } from '../../constants/colors';

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 124px;

  @media (max-height: 850px) {
    transform: scale(0.9);
    margin-top: 104px;
  }
`;

const TextContainer = styled(Container)<{ marginValue: number }>`
  margin-top: ${props => `${props.marginValue}px`};
  text-align: center;
`;

interface EmptyStateComponentProps {
  image: string;
  boldSubtitleText?: string;
  text: string;
}

const EmptyStateComponent = ({ image, boldSubtitleText, text }: EmptyStateComponentProps) => (
  <Container>
    <img src={image} alt={'empty-state'} />
    <TextContainer marginValue={boldSubtitleText ? 40 : 0}>
      <Headline3>{boldSubtitleText}</Headline3>
      <Typography variant={'subtitle2'} sx={{ color: GRAY_COLORS.GRAY_6 }}>
        {text}
      </Typography>
    </TextContainer>
  </Container>
);

export default EmptyStateComponent;
