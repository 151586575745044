import { observer } from 'mobx-react';
import { Box } from '@mui/material';

import Flex from '../../../components/utils/flex/Flex';
import { CustomSearchBar } from '../../../components/CustomSearchBar/CustomSearchBar';
import PromptsGalleryCard from '../../../components/cards/PromptsGalleryCard';
import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../../../hooks/useStore';
import { filterObjectsByKeys } from '../../../utils/filterObjectsByKeys';
import { Prompt } from '../../../models/Prompt';
import PromptsGalleryTeamFilterItem from '../components/PromptsGalleryTeamFilterItem';
import useSize from '../../../hooks/useSize';

interface PromptsTabProps {
  setIsAddPromptModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeletePromptModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  prompts: Prompt[];
  isDisplayedInDrawerMode?: boolean;
}

const PromptsTableTab = ({
  setIsAddPromptModalOpen,
  setIsEditMode,
  setIsDeletePromptModalOpen,
  prompts,
  isDisplayedInDrawerMode,
}: PromptsTabProps) => {
  const {
    userStore: { userData },
    localizationStore: { i18next: i18n },
  } = useStore();

  const [searchValue, setSearchValue] = useState('');
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [filteredPrompts, setFilteredPrompts] = useState<any>(prompts);

  const teamFiltersContainerRef = useRef<any>(null);
  const size = useSize(teamFiltersContainerRef);

  useEffect(() => {
    if (!searchValue) {
      setFilteredPrompts(prompts);
    }

    const newFilteredPrompts = filterObjectsByKeys(prompts, ['content', 'title'], searchValue).filter(
      (item: Prompt) => {
        if (selectedTeams.length > 0) {
          if (!selectedTeams.some(team => item.teams.findIndex(promptTeam => promptTeam.name === team) > -1))
            return false;
        }

        return true;
      }
    );

    setFilteredPrompts(newFilteredPrompts);
  }, [searchValue, selectedTeams, prompts]);

  return (
    <Box sx={{ height: '100%' }}>
      <Flex sx={{ marginBottom: '16px', flexDirection: 'column' }}>
        <CustomSearchBar
          onCancelSearch={() => setSearchValue('')}
          onChange={setSearchValue}
          sx={{ flex: 6, marginRight: '20px' }}
          placeholder={i18n.t('promptGallery.searchPlaceholder')}
        />

        <Box sx={{ display: 'flex', flexWrap: 'wrap' }} ref={teamFiltersContainerRef}>
          <PromptsGalleryTeamFilterItem
            isAllTeams
            isSelected={!selectedTeams.length}
            onClick={() => setSelectedTeams([])}
          />

          {userData?.teams?.map(team => {
            const indexOfTeam = selectedTeams.findIndex(selectedTeam => selectedTeam === team.name);

            return (
              <PromptsGalleryTeamFilterItem
                team={team}
                isSelected={indexOfTeam > -1}
                onClick={() => {
                  if (indexOfTeam < 0) {
                    const newSelectedTeams = [...selectedTeams];
                    newSelectedTeams.push(team.name);

                    setSelectedTeams(newSelectedTeams);

                    return;
                  }

                  const newSelectedTeams = [...selectedTeams];
                  newSelectedTeams.splice(indexOfTeam, 1);

                  setSelectedTeams(newSelectedTeams);
                }}
              />
            );
          })}
        </Box>
      </Flex>
      <Box
        sx={{
          height: `calc(100% - 90px - ${size?.height || 0}px)`,
          display: 'grid',
          gridTemplateColumns: isDisplayedInDrawerMode ? 'unset' : 'repeat(auto-fill, minmax(330px, 1fr))',
          gridTemplateRows: isDisplayedInDrawerMode ? 'min-content' : 'repeat(auto-fill, 190px)',
          gap: '16px',
          overflow: 'scroll',
          padding: '4px 6px',
        }}
      >
        {filteredPrompts.map((prompt: any) => {
          return (
            <PromptsGalleryCard
              isDisplayedInDrawerMode={isDisplayedInDrawerMode}
              setIsEditMode={setIsEditMode}
              setIsDeletePromptModalOpen={setIsDeletePromptModalOpen}
              setIsAddPromptModalOpen={setIsAddPromptModalOpen}
              prompt={prompt}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default observer(PromptsTableTab);
