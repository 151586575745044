import { CreateTeamDto } from '../models/Team';
import { ApiConfig } from './ApiConfig';

export class TeamApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  getTeamsByUserOrganization() {
    return this.apiConfig.sendRequest('GET', '/teams');
  }

  createTeam(teamDto: CreateTeamDto) {
    return this.apiConfig.sendRequest('POST', '/teams', teamDto);
  }

  updateTeam(id: string, updateTeamDto: Partial<CreateTeamDto>) {
    return this.apiConfig.sendRequest('PATCH', `/teams/${id}`, updateTeamDto);
  }

  getTeamById(id: string) {
    return this.apiConfig.sendRequest('GET', `/teams/${id}`);
  }

  removeTeam(id: string) {
    return this.apiConfig.sendRequest('DELETE', `/teams/${id}`);
  }
}
