import { Chat } from '../../../../models/Chat';
import {
  COLOR_MANDATORY,
  COLOR_TEXT_PRIMARY,
  COLOR_WHITE,
  GRAY_COLORS,
  TOAST_COLORS,
} from '../../../../constants/colors';

export const getBackgroundColorForCompletionChat = (chat: Chat) => {
  const { isUserChat, wasBlocked, wasWarned, wasAnonymized, files } = chat;

  if (isUserChat) {
    if (files?.length && files[0]?.id) {
      return 'rgba(166, 196, 251, 0.20)';
    }

    if (wasBlocked) {
      return '#EDF3FE';
    }

    return 'rgba(166, 196, 251, 0.20)';
  }

  if (wasBlocked) {
    return COLOR_MANDATORY;
  }

  if (wasWarned || wasAnonymized) {
    return TOAST_COLORS.WARNING;
  }

  return GRAY_COLORS.GRAY_100;
};

export const getTextColorForChat = (chat: Chat) => {
  const { wasWarned, wasBlocked, wasAnonymized } = chat;

  if (wasBlocked) {
    return COLOR_TEXT_PRIMARY;
  }

  if (!wasWarned && !wasAnonymized) {
    return COLOR_TEXT_PRIMARY;
  }

  return COLOR_WHITE;
};
