import React from 'react';
import { Container, Box, Typography, Button, Grid } from '@mui/material';
import AmazonQLogo from '../../../assets/icons/AmazonQLogo.png';
import styled from 'styled-components';
import {
  COLOR_BORDER_PRIMARY,
  COLOR_PRIMARY,
  COLOR_TEXT_SECONDARY,
  COLOR_TEXT_TERTIARY,
} from '../../../constants/colors';
import { useStore } from '../../../hooks/useStore';

type AmazonQPromptCardProps = {
  headline: string;
  text: string;
};

const AmazonQPromptCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${COLOR_BORDER_PRIMARY};
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: rgba(239, 241, 247, 0.58);
  }
`;

const AmazonQPromptCard = ({ headline, text }: AmazonQPromptCardProps) => {
  const {
    conversationStore: { setMessageInput },
  } = useStore();

  const handleClickPromptCard = () => {
    setMessageInput(`${headline} ${text}`);
  };

  return (
    <Grid item xs={12} md={6}>
      <AmazonQPromptCardContainer onClick={handleClickPromptCard}>
        <Typography sx={{ color: COLOR_PRIMARY }}>{headline}</Typography>
        <Typography sx={{ color: COLOR_TEXT_TERTIARY }}>{text}</Typography>
      </AmazonQPromptCardContainer>
    </Grid>
  );
};

const AmazonQEmptyState = () => {
  const prompts = [
    { headline: 'Draft a profession email', text: 'about my project status' },
    { headline: 'Summarize', text: 'a marketing campaign' },
    { headline: 'Brainstorm taglines', text: 'for my product' },
    { headline: 'Write a blogpost', text: 'about learning on the go' },
  ];

  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
      {/* Amazon Q Business Logo and Name */}
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <img src={AmazonQLogo} alt="Amazon Q Business Logo" style={{ width: '50px', marginRight: '10px' }} />
        <Typography
          variant="h4"
          component="h1"
          style={{
            fontWeight: 'bold',
            background: 'linear-gradient(90deg, #4a00e0, #8e2de2)', // Blue to purple gradient
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Amazon Q Business
        </Typography>
      </Box>

      {/* Subtitle */}
      <Typography variant="h6" color={COLOR_TEXT_TERTIARY} style={{ marginBottom: '10px' }}>
        Your generative AI assistant
      </Typography>

      {/* Instruction Text */}
      <AmazonQPromptCardContainer style={{ position: 'relative' }}>
        <img
          src={AmazonQLogo}
          alt="Amazon Q Business Logo"
          style={{ width: '50px', marginRight: '10px', position: 'absolute', left: -58, top: 0 }}
        />

        <Typography variant="body1" style={{ marginTop: '10px' }}>
          I’m your AI assistant. Enter a prompt to start a conversation. I’ll respond using data from within your
          organization.
        </Typography>
      </AmazonQPromptCardContainer>

      <Typography variant="body1" color={COLOR_TEXT_TERTIARY} style={{ marginTop: '20px', textAlign: 'start' }}>
        Choose a prompt to get started:
      </Typography>

      {/* Prompt Buttons */}
      <Grid container spacing={2} style={{ marginTop: '8px' }}>
        {prompts.map(prompt => (
          <AmazonQPromptCard headline={prompt.headline} text={prompt.text} />
        ))}
      </Grid>
    </Container>
  );
};

export default AmazonQEmptyState;
