import { WorkflowRun } from '../models/Workflows';

export const SortContractFormulas = {
  fromAtoZ: (contract: WorkflowRun[]) =>
    contract
      .slice()
      .sort((contract1, contract2) => contract1?.variables?.name?.localeCompare(contract2?.variables?.name)),
  fromZtoA: (contract: WorkflowRun[]) =>
    contract
      .slice()
      .sort((contract1, contract2) => contract2?.variables?.name?.localeCompare(contract1?.variables?.name)),
  oldestFirst: (contract: WorkflowRun[]) =>
    contract
      .slice()
      .sort(
        (contract1, contract2) => new Date(contract1?.startedAt).getTime() - new Date(contract2?.startedAt).getTime()
      ),
  newestFirst: (contract: WorkflowRun[]) =>
    contract
      .slice()
      .sort(
        (contract1, contract2) => new Date(contract2?.startedAt).getTime() - new Date(contract1?.startedAt).getTime()
      ),
};
