interface CodeSnippetMatch {
  text?: string | null;
  isCodeSnippet?: boolean;
  language?: string | null;
}

export const findCodeSnippetsInsideString = (str: string): CodeSnippetMatch[] => {
  if (!str) {
    return [];
  }

  const regexp = /```/g;
  // @ts-ignore
  const numberOfMatches = [...str?.matchAll(regexp)];

  if (numberOfMatches.length % 2 === 1) {
    str += '```';
  }

  const enclosedRegex = /```(\w+)?([^`]+)```/g;
  const matches = [];
  let remainingStr = str;

  let match = enclosedRegex.exec(remainingStr);

  while (match !== null) {
    enclosedRegex.exec('');
    const language = match[1] || null;
    const codeSnippet = match[2];

    if (match.index > 0) {
      const nonEnclosedSubstring = remainingStr.substring(0, match.index);
      matches.push({
        text: nonEnclosedSubstring,
        language: null,
        isCodeSnippet: false,
      });
    }

    matches.push({
      text: codeSnippet,
      language: language,
      isCodeSnippet: true,
    });

    remainingStr = remainingStr.substring(match.index + match[0].length);
    match = enclosedRegex.exec(remainingStr);
  }

  if (remainingStr.length > 0) {
    matches.push({
      text: remainingStr,
      language: null,
      isCodeSnippet: false,
    });
  }

  return matches;
};
