import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { GRAY_COLORS } from '../../constants/colors';

import { useStore } from '../../hooks/useStore';
import PromptsGalleryTable from './components/PromptsGalleryTable';
import AddPromptModal from '../../components/modal/AddPromptModal/AddPromptModal';
import FillPromptVariablesModal from '../../components/modal/FillPromptVariablesModal';

const PromptsGalleryDrawer = () => {
  const {
    promptsStore: { getPromptsByUser, getPromptsByUserTeams },
    userStore: { getUserInformation },
    uiStore: { isFillPromptVariablesModalOpen, toggleIsFillPromptVariablesModalOpen },
  } = useStore();

  const [isAddPromptModalOpen, setIsAddPromptModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    (async () => {
      await getPromptsByUserTeams();
      await getPromptsByUser();
      await getUserInformation();
    })();
  }, []);

  return (
    <>
      <PromptsGalleryTable
        isTableDisplayedInDrawerMode={true}
        containerStyles={{
          border: `1px solid ${GRAY_COLORS.GRAY_200}`,
          boxShadow: 'none',
          padding: '24px 24px 0 24px',
          borderRadius: '8px 8px 0 0',
          overflow: 'hidden',
          width: '100%',
        }}
        setIsAddPromptModalOpen={setIsAddPromptModalOpen}
        setIsEditMode={setIsEditMode}
        setIsDeletePromptModalOpen={() => {}}
      />
      {isAddPromptModalOpen && (
        <AddPromptModal
          isOpen={isAddPromptModalOpen}
          onClose={() => {
            setIsAddPromptModalOpen(false);
          }}
        />
      )}

      {isFillPromptVariablesModalOpen && (
        <FillPromptVariablesModal
          isOpen={isFillPromptVariablesModalOpen}
          onClose={() => toggleIsFillPromptVariablesModalOpen(false)}
        />
      )}
    </>
  );
};

export default observer(PromptsGalleryDrawer);
