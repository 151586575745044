import React from 'react';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { COLOR_PRIMARY, COLOR_TEXT_PRIMARY, GRAY_COLORS } from '../../constants/colors';

import Flex from '../utils/flex/Flex';
import Button from '../buttons/Button';
import CustomModal from '../modal/CustomModal';
import { StyledContainer } from '../modal/CropImageModal';
import { useStore } from '../../hooks/useStore';
import FlexRowSpaceBetween from '../utils/flex/FlexRowSpaceBetween';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  type?: string;
  title: string;
  children: React.ReactNode;
}

const GeneralModal = ({ isOpen, onClose, onSave, type, children, title }: ModalProps) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  let saveButtonText = i18n.t('common.save.action');
  let saveButtonColor = COLOR_PRIMARY;
  if (type === 'delete') {
    saveButtonText = i18n.t('common.delete.action');
    saveButtonColor = '#DC0000';
  } else if (type === 'upload') {
    saveButtonText = i18n.t('common.upload.action');
  }

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} sx={{ width: '450px' }}>
      <StyledContainer>
        <FlexRowSpaceBetween sx={{ width: '100%' }}>
          <Typography variant={'body1'}>{title}</Typography>

          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </FlexRowSpaceBetween>

        <Box sx={{ margin: '24px 0', width: '100%' }}>{children}</Box>

        <Flex sx={{ justifyContent: 'flex-end', gap: '16px', width: '100%' }}>
          <Button
            onClick={onClose}
            variant={'outlined'}
            sx={{
              border: `1px solid ${GRAY_COLORS.GRAY_300} !important`,
              color: `${COLOR_TEXT_PRIMARY} !important`,
              width: 'fit-content',
              padding: '9px 16px !important',
            }}
          >
            {i18n.t('common.cancel.action')}
          </Button>
          <Button
            onClick={onSave}
            sx={{ width: 'fit-content', background: saveButtonColor, '&:hover': { background: saveButtonColor } }}
          >
            {saveButtonText}
          </Button>
        </Flex>
      </StyledContainer>
    </CustomModal>
  );
};

export default observer(GeneralModal);
