import React from 'react';
import { Box, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';

import { GRAY_COLORS } from '../../constants/colors';

import { hashStringToColor } from '../../utils/hashStringToColor';
import { getTextOverflowEllipsisStyles } from '../typography/utils';

export const handleTeamColors = (team: string): { backgroundColor: string; textColor: string } => {
  let color = hashStringToColor(team);

  if (team.toLowerCase() === 'organization') {
    return {
      textColor: '#1F8FCD',
      backgroundColor: `#1F8FCD4D`,
    };
  }

  if (team.toLowerCase() === 'private') {
    return {
      textColor: GRAY_COLORS.GRAY_500,
      backgroundColor: GRAY_COLORS.GRAY_1,
    };
  }

  return {
    textColor: color,
    backgroundColor: `${color}4D`,
  };
};

interface TeamTagProps {
  team: string;
  withBorders?: boolean;
  sx?: SxProps;
}

const TeamTag = ({ team, withBorders, sx }: TeamTagProps) => {
  const { backgroundColor, textColor } = handleTeamColors(team);

  return (
    <Box
      key={`${team}-team-tag`}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '6px 8px',
        width: 'max-content',
        backgroundColor: backgroundColor,
        border: withBorders ? `1px solid ${textColor}` : 'unset',
        borderRadius: '4px',
        ...sx,
      }}
    >
      <Typography
        sx={{
          marginLeft: '6px',
          color: textColor,
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '16px',
          ...getTextOverflowEllipsisStyles(1),
        }}
      >
        {team}
      </Typography>
      {team.toLowerCase() === 'private' && <LockIcon sx={{ color: textColor, height: '12px' }} />}
    </Box>
  );
};

export default TeamTag;
