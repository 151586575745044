import axios from 'axios';
import { i18n } from 'i18next';

import { GoogleDriveApi } from '../api/GoogleDriveApi';
import { GoogleDriveExportBody } from '../models/GoogleDrive';
import { FileApi } from '../api/FileApi';
import { fileStatus } from '../constants/fileStatus';

export const uploadDriveFile = async ({
  googleDriveObject,
  conversationId,
  createdFiles,
  googleDriveApi,
  fileApi,
  setIsUploadInProgress,
  cancelFileUpload,
  addToast,
  i18n,
}: {
  googleDriveObject: GoogleDriveExportBody;
  conversationId: string;
  createdFiles: string[];
  googleDriveApi: GoogleDriveApi;
  fileApi: FileApi;
  setIsUploadInProgress: (a: boolean) => void;
  cancelFileUpload: () => void;
  addToast: any;
  i18n: i18n;
}) => {
  try {
    const status = await googleDriveApi.uploadGoogleDriveFile(googleDriveObject);
    if (Array.isArray(status) && status.length) {
      const resp = status.map((s, index) => {
        if (s === 200) {
          return fileApi.startProcessing(conversationId, createdFiles[index]);
        } else {
          fileApi.updateFileStatus(createdFiles[index], fileStatus.FAILED_PROCESSING);
          return addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
        }
      });
      setIsUploadInProgress(false);
      return resp;
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Upload canceled');
      await cancelFileUpload();
    } else {
      console.error('Error during upload:', error);
      await fileApi.updateFileStatus(createdFiles[0], fileStatus.FAILED_PROCESSING);
      setIsUploadInProgress(false);
    }
    addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
  }
};
