import React, { useEffect, useState } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { PickerCallback } from 'react-google-drive-picker/dist/typeDefs';

import { LocalStorageKeys } from '../../constants/localStorageKeys';
import { COLOR_DIVIDER, COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';

import { googleDriveConfig } from '../../config/addvaluemachine/config';
import { GoogleDriveApi } from '../../api/GoogleDriveApi';
import GoogleDriveIcon from '../../assets/icons/google-drive-logo.svg';

const IconContainer = styled(Box)<{ disabled?: boolean }>`
  padding: 8px;
  border-radius: 6px;
  border: 1px solid ${GRAY_COLORS.GRAY_300};
  background-color: ${props => (props.disabled ? COLOR_DIVIDER : COLOR_WHITE)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const DriveImage = styled.img`
  width: 20px;
  height: 20px;
`;

const GoogleDrivePicker = ({
  callback,
  disabled,
  googleDriveApi,
}: {
  callback: any;
  disabled?: boolean;
  googleDriveApi: GoogleDriveApi;
}) => {
  const gDriveToken = localStorage.getItem(LocalStorageKeys.googleDriveAccessToken);

  const [openPicker, authResponse] = useDrivePicker();
  const [token, setToken] = useState<null | string>(gDriveToken || null);

  useEffect(() => {
    (async () => {
      if (gDriveToken) {
        try {
          await googleDriveApi.getTokenInfo(gDriveToken);
          if (authResponse?.access_token && authResponse.access_token !== token) {
            setToken(authResponse?.access_token);
            localStorage.setItem(LocalStorageKeys.googleDriveAccessToken, authResponse?.access_token);
          }
        } catch (e) {
          localStorage.setItem(LocalStorageKeys.googleDriveAccessToken, '');
          setToken('');
        }
      } else {
        if (authResponse?.access_token && authResponse.access_token !== token) {
          setToken(authResponse?.access_token);
          localStorage.setItem(LocalStorageKeys.googleDriveAccessToken, authResponse?.access_token);
        }
      }
    })();
  }, [authResponse, authResponse?.access_token]);

  const onCallback = (data: PickerCallback, token: string) => {
    callback?.({ data, token });
  };

  const handleOpenPicker = () => {
    openPicker({
      clientId: googleDriveConfig.clientId,
      developerKey: googleDriveConfig.developerKey,
      viewId: 'DOCS',
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: true,
      multiselect: false,
      token: token || gDriveToken || undefined,
      setOrigin: window.location.origin,
      viewMimeTypes:
        'application/vnd.google-apps.spreadsheet,application/vnd.google-apps.document,application/vnd.google-apps.file,application/vnd.google-apps.form,application/vnd.google-apps.fusiontable,application/vnd.google-apps.script,application/vnd.google-apps.shortcut',
      callbackFunction: data => {
        if (data.action === 'cancel') {
          console.log('User clicked cancel/close button');
        }
        if (data?.docs?.length && token) {
          onCallback(data, token);
        }
      },
    });
  };

  return (
    <IconContainer onClick={() => (disabled ? {} : handleOpenPicker())} disabled={disabled}>
      <DriveImage src={GoogleDriveIcon} alt={'google-drive-icon'} />
    </IconContainer>
  );
};

export default GoogleDrivePicker;
