import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { observer } from 'mobx-react';
import { IconButton, TextField, Box } from '@mui/material';

import { COLOR_DARK_BLUE, COLOR_DARK_RED, COLOR_PRIMARY, COLOR_RED, COLOR_WHITE } from '../../../constants/colors';
import { DynamicTextInputField } from '../../../models/Steps/Fields';
import { useStore } from '../../../hooks/useStore';

type DynamicTextInputListProps = {
  field: DynamicTextInputField;
};

const DynamicTextInputList = ({
  field: { name: fieldName, label, value = [{ id: 1, value: '' }], reset = false },
}: DynamicTextInputListProps) => {
  const {
    createWorkflowRunStore: { setWorkflowRunDataField },
  } = useStore();

  const [inputValues, setInputValues] = useState<{ id: string; value: string }[]>(value);

  useEffect(() => {
    setInputValues(value);
    propagateChange();
  }, [reset]);

  const handleAddIconClick = () => {
    const lastTextField = inputValues[inputValues.length - 1];

    if (!lastTextField || lastTextField.value.trim() !== '') {
      const lastTextFieldId = lastTextField ? lastTextField.id : '0';

      const newTextField = { id: (parseInt(lastTextFieldId) + 1).toString(), value: '' };
      setInputValues([...inputValues, newTextField]);
    }
    propagateChange();
  };

  const handleDeleteClick = (index: number) => {
    const newTextField = [...inputValues];
    newTextField.splice(index, 1);
    setInputValues(newTextField);
    propagateChange();
  };

  const handleTextFieldChange = (index: number, value: string) => {
    const newTextField = [...inputValues];
    newTextField[index].value = value;
    setInputValues(newTextField);
    propagateChange();
  };

  const propagateChange = () => {
    setWorkflowRunDataField(fieldName, inputValues);
  };

  if (!inputValues?.length) {
    return;
  }

  return (
    <Box>
      {inputValues.map((textField, index) => (
        <div
          key={textField.id}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginBottom: '8px',
          }}
        >
          <TextField
            label={label}
            sx={{ width: '100%' }}
            placeholder={`Enter ${label} ${index}...`}
            multiline
            maxRows={4}
            disabled={index !== inputValues.length - 1}
            value={textField.value}
            onChange={e => handleTextFieldChange(index, e.target.value)}
          />

          {index !== inputValues.length - 1 && (
            <IconButton
              sx={{
                color: COLOR_WHITE,
                background: COLOR_RED,
                height: '45px',
                width: '45px',
                borderRadius: '50%',
                '&:hover': {
                  backgroundColor: COLOR_DARK_RED,
                },
              }}
              onClick={() => handleDeleteClick(index)}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          )}

          {index === inputValues.length - 1 && (
            <IconButton
              onClick={handleAddIconClick}
              sx={{
                color: COLOR_WHITE,
                background: COLOR_PRIMARY,
                height: '45px',
                width: '45px',
                borderRadius: '50%',
                '&:hover': {
                  backgroundColor: COLOR_DARK_BLUE,
                },
              }}
            >
              <AddIcon />
            </IconButton>
          )}
        </div>
      ))}
    </Box>
  );
};

export default observer(DynamicTextInputList);
