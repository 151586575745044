import { LocalStorageKeys } from '../constants/localStorageKeys';
import mcro from './mcro/config';
import addvaluemachine from './addvaluemachine/config';
import accelKkr from './accel-kkr/config';

export const config: any = {
  mcro,
  addvaluemachine,
  'accel-kkr': accelKkr,
};

export default async function configure() {
  const tenant = localStorage.getItem(LocalStorageKeys.tenant) || 'addvaluemachine';
  return (await require(`./${tenant}/config`)).default;
};
