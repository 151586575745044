import useResizeObserver from '@react-hook/resize-observer';
import { useLayoutEffect, useState } from 'react';

const useSize = (target: any) => {
  const [size, setSize] = useState<any>();

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, entry => setSize(entry.contentRect));

  return size;
};

export default useSize;
