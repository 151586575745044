import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useToasts } from 'react-toast-notifications';

import { Paths } from '../constants/routes';
import { Roles } from '../constants/userRoles';

import { useStore } from '../hooks/useStore';
import { Box } from '@mui/material';
import { LoadingSpinner } from '../components/spinner/LoadingSpinner';

export const AuthenticationWrapper = ({ children }: any) => {
  const {
    authStore: { accessToken, updateToken, tokenExpirationDate, logout },
    userStore: { getUserInformation },
  } = useStore();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        if (accessToken && (!tokenExpirationDate || Date.now() > new Date(tokenExpirationDate).getTime())) {
          await updateToken();
        }
        if (accessToken) {
          await getUserInformation();
        }
      } catch (error) {
        console.log('error ------------------->> ', error);
        await logout();
      }
    })();
  }, []);

  if (!accessToken) {
    return <Navigate to={Paths.LOGIN} state={{ from: location }} replace />;
  }

  return children;
};

export const LoginWrapper = ({ children }: any) => {
  const {
    authStore: { accessToken },
  } = useStore();
  const location = useLocation();

  if (accessToken) {
    return <Navigate to={Paths.CHAT} state={{ from: location }} replace />;
  }

  return children;
};

export const AccessWrapper = ({ children, roles }: any) => {
  const navigate = useNavigate();
  // roles need to be an array
  const {
    userStore: { userData, getUserInformation },
    localizationStore: { i18next: i18n },
  } = useStore();
  const [loading, setLoading] = useState(true);
  const { addToast } = useToasts();
  const checkRoleAndDecide = (role: string) => {
    if (role && !roles.includes(role)) {
      switch (role) {
        case Roles.member:
          addToast(`${i18n.t('restrictedAccess.member')}`, { appearance: 'error' });
          navigate(Paths.CHAT);
          break;
        default:
          navigate(Paths.CHAT);
          break;
      }
      return;
    }
    setLoading(false);
  };
  useEffect(() => {
    if (!userData.role) {
      (async () => {
        const userData = await getUserInformation();
        checkRoleAndDecide(userData.role);
      })();
    } else {
      checkRoleAndDecide(userData.role);
    }
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          minHeight: '500px',
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingSpinner />
      </Box>
    );
  }
  return roles.includes(userData.role) ? children : null;
};
