import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { useStore } from '../../../hooks/useStore';
import { camelCase } from 'lodash';

export interface QueryResponseFields {
  'ACT Score': number | string;
  'SAT Score': number | string;
  GPA: number | string;
  'Graduation Year': number | string;
  TotalExperience: number;
  priorexpIndex: number;
}

export const pdfRankingHeader = [
  'Rank',
  'ACT Score',
  'SAT Score',
  'GPA',
  'Graduation Year',
  'Total Experience',
  'Source File',
];

export interface ResumeParserFileResult {
  FileID: string;
  QueryResponses: string; // This will contain a JSON string
}

const ResumeParserResultsPage = () => {
  const [workflowResult, setWorkflowResult] = useState<ResumeParserFileResult[]>([]);

  const {
    workFlowStore: { selectedWorkflowRun },
  } = useStore();

  useEffect(() => {
    const constSortedResultsArray = [...selectedWorkflowRun.result].sort(
      (a, b) =>
        parseInt(JSON.parse(a.QueryResponses).priorexpIndex) - parseInt(JSON.parse(b.QueryResponses).priorexpIndex)
    );

    setWorkflowResult(constSortedResultsArray);
  }, [selectedWorkflowRun.id]);

  return (
    <Box sx={{ width: '100%', typography: 'body1', overflowY: 'auto' }}>
      <Box sx={{ width: '100%', typography: 'body1', overflowY: 'auto' }}>
        {/* added resultElement id to get element and download result */}
        <div id="resultElement">
          <Table>
            <TableHead>
              <TableRow>
                {pdfRankingHeader.map(header => (
                  <TableCell key={header} sx={{ fontWeight: 'bold !important' }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {workflowResult.map((resumeParserFileResult: any, index: number) => {
                if (!resumeParserFileResult.QueryResponses) {
                  return;
                }

                const queryResponse: any = normalizeFieldsToCamelCase(
                  JSON.parse(resumeParserFileResult.QueryResponses)
                );

                return (
                  <TableRow key={index}>
                    <TableCell>{queryResponse.priorexpIndex}</TableCell>
                    <TableCell>{queryResponse.actScore}</TableCell>
                    <TableCell>{queryResponse.satScore}</TableCell>
                    <TableCell>{queryResponse.gpa}</TableCell>
                    <TableCell>{queryResponse.graduationYear}</TableCell>
                    <TableCell>{queryResponse.totalExperience}</TableCell>
                    <TableCell>{resumeParserFileResult.FileID}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Box>
    </Box>
  );
};

const normalizeFieldsToCamelCase = (obj: any): { [key: string]: number | string } => {
  const normalizedObj: { [key: string]: number | string } = {};
  for (const key in obj) {
    const camelCaseKey = camelCase(key);
    normalizedObj[camelCaseKey] = obj[key];
  }
  return normalizedObj;
};

export default observer(ResumeParserResultsPage);
