import React, { useEffect, useState } from 'react';
import { Popover, List, ListItem } from '@mui/material';
import { observer } from 'mobx-react';

import { useStore } from '../../../hooks/useStore';
import { CustomSearchBar } from '../../../components/CustomSearchBar/CustomSearchBar';
import { OrganizationModel } from '../../../models/OrganizationModel';
import backArrowIcon from '../../../assets/icons/chat/back-arrow.svg';
import agent1Icon from '../../../assets/icons/chat/agent-1.svg';
import { Models } from '../../../constants/models';
import isBedrockModel from '../../../utils/isBedrockModel';
import ModelLogoPicture from '../../../components/knowledgeBase/ModelLogoPicture';

interface Props {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  setDropdownState: Function;
  isSecondary?: boolean;
}

// Popover component for selecting model
const ModelSelectionPopover: React.FC<Props> = ({ anchorEl, onClose, setDropdownState, isSecondary }) => {
  const [displayOptions, setDisplayOptions] = useState<OrganizationModel[]>();
  const [enabledOrganizationModels, setEnabledOrganizationModels] = useState<OrganizationModel[]>();
  const [searchValue, setSearchValue] = useState('');

  const {
    modelStore: { organizationModels, setSelectedModel },
    conversationStore: { setPrimaryConversationModelSettings, setSecondaryConversationModelSettings },
  } = useStore();

  // getting all active model for organziation
  useEffect(() => {
    setEnabledOrganizationModels(
      organizationModels.filter((organizationModel: OrganizationModel) => {
        return organizationModel.isActive;
      })
    );
  }, [organizationModels]);

  //Setting search value empty while model is selected
  useEffect(() => {
    setSearchValue('');
  }, [anchorEl]);

  // Use effect to set dropdown options
  // Removed extra code
  useEffect(() => {
    const filteredOptions = enabledOrganizationModels
      ?.filter(item => item.displayName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
      .sort((a, b) => a.displayName.localeCompare(b.displayName)) as OrganizationModel[]; // Sorting alphabetically
    setDisplayOptions(filteredOptions);
  }, [searchValue, anchorEl, enabledOrganizationModels]);

  const handleSelectOption = (model: OrganizationModel) => {
    setSelectedModel(model);

    // Updatin model change accordingly
    if (!isSecondary) {
      setPrimaryConversationModelSettings({
        accuracy: 0.8,
        length: 1024,
        model: model?.modelVersion as string,
        providerName: model?.providerName as string,
        modelDisplayName: model?.displayName as string,
      });
    } else {
      setSecondaryConversationModelSettings({
        accuracy: 0.8,
        length: 1024,
        model: model?.modelVersion as string,
        providerName: model?.providerName as string,
        modelDisplayName: model?.displayName as string,
      });
    }
    onClose();
  };

  return (
    <>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          <div style={{ display: 'flex' }}>
            <img
              src={backArrowIcon}
              alt="back"
              style={{ marginLeft: '12px' }}
              onClick={() => {
                setDropdownState(false);
                onClose();
              }}
            />
            <div style={{ fontWeight: 'bold', margin: '24px 4px', padding: '0px 8px' }}>{'Models'}</div>
          </div>
          <CustomSearchBar
            onChange={setSearchValue}
            onCancelSearch={() => setSearchValue('')}
            placeholder={'Search'}
            sx={{ marginBottom: '24px', padding: '0px 8px', flex: 3 }}
          />
          {displayOptions
            ?.filter(item => item.displayName !== Models.SQL_MODEL.displayName) // removing SQL model
            ?.map(model => (
              <ListItem
                key={model.displayName}
                onClick={() => {
                  handleSelectOption(model as OrganizationModel);
                }}
                sx={{ cursor: 'pointer' }}
              >
                {model.displayName.includes('Agent') ? (
                  <img src={agent1Icon} alt="back" style={{ marginRight: '8px' }} />
                ) : (
                  <ModelLogoPicture model={model} size={24} />
                )}
                {model.displayName}
                {isBedrockModel(model) && ' - (Bedrock)'}
              </ListItem>
            ))}
        </List>
      </Popover>
    </>
  );
};

export default observer(ModelSelectionPopover);
