import { WorkSpaceApi } from './../api/WorkSpaceApi';
import autoBind from 'auto-bind';
import { makeAutoObservable, runInAction } from 'mobx';

import { WorkSpaceItems } from '../models/WorkSpace';

export class WorkSpaceStore {
  workSpaceApi: WorkSpaceApi;
  workSpaceList: Array<WorkSpaceItems> = [];

  constructor(workSpaceApi: WorkSpaceApi) {
    this.workSpaceApi = workSpaceApi;
    makeAutoObservable(this);
    autoBind(this);
  }
  async getWorkSpaceSetting() {
    const workSpaceList = await this.workSpaceApi.getWorkSpaceSetting();

    runInAction(() => {
      this.workSpaceList = workSpaceList;
    });

    return workSpaceList;
  }
}
