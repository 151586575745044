export const isMobileOrTablet = () => {
  // disable type checking for navigator
  // @ts-ignore
  const isMobile = navigator?.userAgentData?.mobile;
  if (isMobile) return true;

  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
    return navigator?.userAgent?.match(toMatchItem);
  });
};
