import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

import { useStore } from '../../hooks/useStore';
import Flex from '../utils/flex/Flex';
import TextButton from '../buttons/TextButton';
import Button from '../buttons/Button';
import CustomModal from './CustomModal';
import { getCroppedImg } from '../../utils/cropImage';

export const StyledContainer = styled.form`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: column;
`;

type CropImageModalProps = {
  isOpen: boolean;
  onClose: () => void;
  image?: any;
  onSave: (file: any) => void;
  loading?: boolean;
};

const CropWrapper = styled.div`
  margin: 24px 0;
  position: relative;
  width: 100%;
  height: 450px;
`;

const CropImageModal: React.FC<CropImageModalProps> = props => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();
  const { isOpen, onClose, image, onSave, loading } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onLocalSave = async () => {
    const croppedFile = await getCroppedImg(image, croppedAreaPixels);
    onSave(croppedFile);
    onClose();
  };

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} sx={{ width: '328px' }}>
      <StyledContainer>
        <Typography variant={'body1'}>Crop photo</Typography>
        <CropWrapper>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </CropWrapper>
        <Flex sx={{ justifyContent: 'flex-end', gap: '16px', width: '100%' }}>
          <TextButton onClick={onClose}>{i18n.t('common.cancel.action')}</TextButton>
          <Button onClick={onLocalSave} loading={loading}>
            {i18n.t('common.save.action')}
          </Button>
        </Flex>
      </StyledContainer>
    </CustomModal>
  );
};

export default CropImageModal;
