import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import { ModelApi } from '../api/ModelApi';
import { EMPTY_ORGANIZATION_MODEL, OrganizationModel } from '../models/OrganizationModel';
import { Model } from '../models/Model';

export class ModelsStore {
  modelApi: ModelApi;
  currentOrganizationModel: OrganizationModel = EMPTY_ORGANIZATION_MODEL;

  organizationModels: Array<OrganizationModel> = [];
  defaultOrganizationModel: OrganizationModel = EMPTY_ORGANIZATION_MODEL;
  defaultFileOrganizationModel: OrganizationModel = EMPTY_ORGANIZATION_MODEL;
  supportedModels: Array<Model> = [];
  selectedModel: OrganizationModel | null = null;

  constructor(modelApi: ModelApi) {
    this.modelApi = modelApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  setSelectedModel(model: OrganizationModel | null) {
    runInAction(() => {
      this.selectedModel = model;
    });
  }

  async findAllOrganizationModels() {
    const organizationModels = await this.modelApi.findAll();
    let defaultModel: OrganizationModel;

    organizationModels.forEach(model => {
      if (!model.isDefault) {
        return;
      }

      defaultModel = model;
    });

    runInAction(() => {
      this.organizationModels = organizationModels;

      if (defaultModel) {
        this.defaultOrganizationModel = defaultModel;
      }
    });

    return organizationModels;
  }

  async findAllOrganizationModelsAccessibleByUser() {
    const organizationModels = await this.modelApi.findAllAccessibleByUser();
    let defaultModel: OrganizationModel;

    organizationModels.forEach(model => {
      if (!model.isDefault) {
        return;
      }

      defaultModel = model;
    });

    runInAction(() => {
      this.organizationModels = organizationModels;

      if (defaultModel) {
        this.defaultOrganizationModel = defaultModel;
      }
    });

    return organizationModels;
  }

  async updateAllOrganizationModels() {
    const mappedModels = this.organizationModels.map(model => {
      return {
        id: model.id,
        isActive: model.isActive,
        isDefault: model.isDefault,
      };
    });

    await this.modelApi.updateAll(mappedModels);
  }

  findDefaultFileModel(): Promise<OrganizationModel> {
    return this.modelApi.findDefaultFileModel();
  }

  async findOneOrganizationModel(id: string) {
    const organizationModel = await this.modelApi.findOne(id);

    runInAction(() => {
      this.currentOrganizationModel = organizationModel;
    });
  }

  updateOrganizationModel(updatedModel: OrganizationModel) {
    const indexOfModelToBeUpdated = this.organizationModels.findIndex(
      organizationModel => organizationModel.id === updatedModel.id
    );

    runInAction(() => {
      if (indexOfModelToBeUpdated < 0) {
        return;
      }

      const newOrganizationModels = [...this.organizationModels];
      newOrganizationModels[indexOfModelToBeUpdated] = updatedModel;

      if (updatedModel.isDefault) {
        const indexOfOldDefaultModel = this.organizationModels.findIndex(
          organizationModel => organizationModel.id === this.defaultOrganizationModel.id
        );

        if (indexOfOldDefaultModel > -1) {
          newOrganizationModels[indexOfOldDefaultModel].isDefault = false;
        }

        this.defaultOrganizationModel = updatedModel;
      }

      this.organizationModels = newOrganizationModels;
    });
  }
}
