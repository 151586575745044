import React, { ReactNode } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';

import { COLOR_BORDER_PRIMARY, COLOR_WHITE, COLOR_BACKGROUND, COLOR_TEXT_QUINARY } from '../../constants/colors';

import withLoading from '../../hoc/withLoading';

interface AppTableProps {
  tableHeaderColumnNames?: {
    name: string;
    width: number;
    align?: any;
  }[];
  colors?: any;
  noBorder?: boolean;
  children?: any;
  height?: string;
  tableFooter?: ReactNode;
  centerText?: boolean;
  style?: any;
}

const AppTable: React.FC<AppTableProps> = ({
  height = '',
  noBorder,
  colors,
  tableHeaderColumnNames,
  children,
  tableFooter,
  centerText,
  style,
}) => {
  const rootStyle = {
    height: height ? height : 'unset',
    width: '100%',
    borderRadius: '4px',
    border: noBorder ? 'none' : `1px solid ${colors ? colors.BorderColor : COLOR_BORDER_PRIMARY}`,

    '& .MuiTableCell-paddingNone:last-child': {
      padding: '12px',
    },
  };
  const rowStyle = {
    backgroundColor: colors ? colors.Primary : COLOR_BACKGROUND,
    color: colors ? colors.LightSubtitleText : COLOR_TEXT_QUINARY,
  };
  const cellStyle = {
    padding: '12px',
    backgroundColor: colors ? colors.Primary : COLOR_BACKGROUND,
    color: colors ? colors.LightSubtitleText : COLOR_TEXT_QUINARY,
  };
  const cellCenterStyle = {
    padding: '12px',
    backgroundColor: colors ? colors.Primary : COLOR_BACKGROUND,
    color: colors ? colors.LightSubtitleText : COLOR_TEXT_QUINARY,
    textAlign: 'center',
  };

  return (
    <TableContainer sx={{ ...rootStyle, ...style }}>
      <Table stickyHeader>
        {tableHeaderColumnNames && (
          <TableHead>
            <TableRow sx={rowStyle}>
              {tableHeaderColumnNames.map((column, index) => (
                <TableCell
                  sx={[centerText ? cellCenterStyle : cellStyle]}
                  key={`${column.name}_${index}`}
                  padding={'none'}
                  style={{ width: `${column.width}%` }}
                  align={column.align}
                >
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody style={{ position: 'relative', backgroundColor: COLOR_WHITE, overflow: 'scroll' }}>
          {children}
        </TableBody>
        {tableFooter ? tableFooter : null}
      </Table>
    </TableContainer>
  );
};

export default withLoading(AppTable);
