import React from 'react';
import Box from '@mui/material/Box';

import { WrapperComponentModel } from '../../../models/WrapperComponentModel';

const Flex = ({ children, sx, onClick }: WrapperComponentModel) => {
  return (
    <Box
      sx={{
        display: 'flex',
        ...sx,
      }}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {children}
    </Box>
  );
};

export default Flex;
