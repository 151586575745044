import { Typography } from '@mui/material';

import { COLOR_PRIMARY, GRAY_COLORS } from '../../../../../constants/colors';

import Edit from '../../../../../assets/icons/edit.svg';

import TextButton from '../../../../../components/buttons/TextButton';
import Flex from '../../../../../components/utils/flex/Flex';
import { useStore } from '../../../../../hooks/useStore';

interface BlockedMessageActionComponentProps {
  handleEditPrompt: () => void;
}

const BlockedMessageActionComponent = ({ handleEditPrompt }: BlockedMessageActionComponentProps) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  return (
    <Flex sx={{ alignItems: 'center', marginTop: '8px' }}>
      <TextButton
        sx={{
          width: 'fit-content',
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        }}
        startIcon={<img src={Edit} alt={'edit-icon'} />}
        onClick={handleEditPrompt}
      >
        <Typography variant={'body2'} sx={{ color: COLOR_PRIMARY, fontWeight: 600 }}>
          {i18n.t('conversation.blockedMessage.editPrompt')}
        </Typography>
      </TextButton>
      <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_500, fontStyle: 'italic', marginLeft: '-4px' }}>
        {i18n.t('conversation.blockedMessage.makeAnotherAttempt')}
      </Typography>
    </Flex>
  );
};

export default BlockedMessageActionComponent;
