import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import { Box, DialogContentText } from '@mui/material';
import { PrimarySwitch } from '../Switch/PrimarySwitch';
import jiraLogo from '../../config/addvaluemachine/jiraLogo.svg';
import sharePointLogo from '../../config/addvaluemachine/sharePointLogo.svg';
import oneDriveLogo from '../../config/addvaluemachine/oneDriveLogo.svg';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    marginBottom: '16px',
  },
  '& .MuiDialog-paper': {
    width: '80%',
    maxWidth: '600px',
    overflow: 'hidden',
    maxHeight: '100vh',
  },
  '& .MuiDialogTitle-root': {
    marginBottom: '16px',
  },
  '& .MuiRating-root': {
    marginBottom: '16px',
    '& .MuiRating-icon': {
      marginRight: '8px', // Adjust as per your spacing requirement
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiDialog-paper': {
      width: '100%',
      margin: 0,
    },
  },
}));

interface FeedbackProps {
  showFeedback: boolean;
  handleCloseFeedback: () => void;
  title: String;
  subtitle: String;
  typeOfModel: String;
  saveBtn: String;
}

const FeedbackForm = ({ showFeedback, handleCloseFeedback, title, subtitle, typeOfModel, saveBtn }: FeedbackProps) => {
  return (
    <React.Fragment>
      <BootstrapDialog onClose={handleCloseFeedback} aria-labelledby="customized-dialog-title" open={showFeedback}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Typography variant="h6" color="#000000" lineHeight="24px" fontSize="16px" fontWeight="700">
            {/* Feedback  // ! dyanamic value */}
            {title}
          </Typography>
          <Typography variant="subtitle2" color="#76787D" fontSize="14px" lineHeight="22px" fontWeight="400">
            {/* Your feedback helps us improve your experience. // ! dynamic value  */}
            {subtitle}
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFeedback}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* // ! dynamic value */}
        {typeOfModel === "feedback" &&
          <DialogContent>
            <Typography gutterBottom sx={{ color: '#1B1B1B', fontSize: 14, lineHeight: '20px', fontWeight: 600 }}>
              How would you rate your experience with AVM?
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Rating
                name="customized-icons"
                defaultValue={0}
                max={5}
                size="large"
                sx={{ color: '#FFD029', fontSize: '2.5rem' }}
              />
            </div>
            <Typography
              gutterBottom
              sx={{ color: '#1B1B1B !important', fontSize: 14, lineHeight: '20px', fontWeight: 600 }}
            >
              Share your feedback
            </Typography>
            <TextField fullWidth multiline rows={4} placeholder="Write your feedback here" />
          </DialogContent>
        }
        {/* // ! dynamic value */}
        {typeOfModel === "addAgent" &&
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid #E0E2E9',
                padding: '8px',
                marginBottom: '4px',
                borderRadius: '8px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#1B1B1B !important', fontSize: 16, lineHeight: '20px', fontWeight: 600 }}>
                  <img src={sharePointLogo} alt="Sharepoint" style={{ height: '16px', marginRight: '8px' }} /> Sharepoint
                </Typography>
                <PrimarySwitch />
              </div>
              <DialogContentText
                sx={{ marginTop: '12px', color: '#76787D', fontSize: '14px', lineHeight: '22px', fontWeight: 400 }}
              >
                Turpis cras tristique ac eget. Interdum in tellus cursus nisl ut massa cra mi tellus cursus nisl ut massa
                cras mi.
              </DialogContentText>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid #E0E2E9',
                padding: '8px',
                marginBottom: '4px',
                borderRadius: '8px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#1B1B1B !important', fontSize: 16, lineHeight: '20px', fontWeight: 600 }}>
                  <img src={oneDriveLogo} alt="OneDrive" style={{ height: '16px', marginRight: '8px' }} /> OneDrive
                </Typography>
                <PrimarySwitch />
              </div>
              <DialogContentText
                sx={{ marginTop: '12px', color: '#76787D', fontSize: '14px', lineHeight: '22px', fontWeight: 400 }}
              >
                Turpis cras tristique ac eget. Interdum in tellus cursus nisl ut massa cra mi tellus cursus nisl ut massa
                cras mi.
              </DialogContentText>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid #E0E2E9',
                padding: '8px',
                marginBottom: '4px',
                borderRadius: '8px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#1B1B1B !important', fontSize: 16, lineHeight: '20px', fontWeight: 600 }}>
                  <img src={jiraLogo} alt="jira" style={{ height: '16px', marginRight: '8px' }} /> Jira
                </Typography>
                <PrimarySwitch />
              </div>
              <DialogContentText
                sx={{ marginTop: '12px', color: '#76787D', fontSize: '14px', lineHeight: '22px', fontWeight: 400 }}
              >
                Turpis cras tristique ac eget. Interdum in tellus cursus nisl ut massa cra mi tellus cursus nisl ut massa
                cras mi.
              </DialogContentText>
            </Box>
          </DialogContent>
        }
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCloseFeedback}
            sx={{
              borderRadius: '6px',
              border: '1px solid #D1D5DB',
              color: '#1B1B1B',
              fontWeight: 700,
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleCloseFeedback} variant="contained" color="primary">
            {/* Send Feedback  // ! dynamic value */}
            {saveBtn}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}

export default FeedbackForm;