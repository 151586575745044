/**
 * Returns true if the tenant domain is set in the hostname.
 */
export const isTenantDomainSet = () => {
  const url = new URL(window.location.href);
  const components = url.hostname.split('.');

  // eg. chat.addvaluemachine.avm.technology
  if (['chat', 'admin'].includes(components[0])) {
    components.shift(); // remove 'chat' or 'admin'
  }
  // eg. mcro.localhost
  if (components.includes('localhost') && components.length === 2) {
    return true;
  }
  // eg. addvaluemachine.dev.avm.technology
  if (components.includes('dev') && components.length === 4) {
    return true;
  }
  // eg. addvaluemachine.avm.technology
  return components.length === 3;
};

/**
 * Returns the tenant name from the hostname.
 */
export const getTenantFromHost = () => {
  const url = new URL(window.location.href);
  const components = url.hostname.split('.');

  if (['chat', 'admin'].includes(components[0])) {
    return components[1];
  }
  return components[0];
};

/**
 * Returns the API endpoint from the hostname.
 * @param isRtUrl If true, returns the RT endpoint.
 */
export const getApiEndpointFromHost = (isRtUrl: boolean = false) => {
  const url = new URL(window.location.origin);
  const components = url.hostname.split('.');

  if (['chat', 'admin'].includes(components[0])) {
    components.shift(); // remove 'chat' or 'admin'
  }

  url.hostname = ['api', ...components].join('.');

  if (!isRtUrl) {
    url.pathname = '/v1';
  }

  return url.toString();
};
