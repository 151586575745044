import { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { COLOR_BLACK, COLOR_TEXT_PRIMARY, GRAY_COLORS } from '../../../../../constants/colors';

import Flex from '../../../../../components/utils/flex/Flex';
import Button from '../../../../../components/buttons/Button';
import { useStore } from '../../../../../hooks/useStore';

interface CancelUploadModalProps {
  closeModal: () => void;
}

const CancelUploadModal = ({ closeModal }: CancelUploadModalProps) => {
  const {
    localizationStore: { i18next: i18n },
    conversationStore: { cancelUploadToken, uploadInProgress },
  } = useStore();

  const handleCancelUpload = async () => {
    if (cancelUploadToken) {
      cancelUploadToken.cancel('Upload canceled by user.');
    }

    closeModal();
  };

  useEffect(() => {
    if (!uploadInProgress) {
      closeModal();
    }
  }, [uploadInProgress]);

  return (
    <Stack sx={{ gap: '12px', width: '100%' }}>
      <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant={'body1'} sx={{ color: COLOR_BLACK }}>
          {i18n.t('conversation.file.cancelUpload')}
        </Typography>
        <IconButton size={'small'} onClick={closeModal}>
          <CloseIcon fontSize={'small'} />
        </IconButton>
      </Flex>
      <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_9 }}>
        {i18n.t('conversation.file.cancelUploadMessage')}
      </Typography>
      <Flex sx={{ justifyContent: 'flex-end', gap: '16px', marginTop: '12px' }}>
        <Button
          variant={'outlined'}
          sx={{
            width: 'fit-content',
            borderColor: `${GRAY_COLORS.GRAY_300} !important`,
            color: `${COLOR_TEXT_PRIMARY} !important`,
          }}
          onClick={closeModal}
        >
          {i18n.t('common.cancel.action')}
        </Button>
        <Button
          sx={{
            width: 'fit-content',
          }}
          color={'error'}
          onClick={handleCancelUpload}
        >
          {i18n.t('conversation.file.cancelUpload')}
        </Button>
      </Flex>
    </Stack>
  );
};

export default CancelUploadModal;
