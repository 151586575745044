export const convertBytesToUnit = (bytes: number) => {
  if (bytes < 0) {
    return 'Invalid input';
  }

  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const unitIndex = Math.floor(Math.log2(bytes) / 10);

  const size = bytes / Math.pow(1024, unitIndex);

  return `${size.toFixed(2)} ${units[unitIndex]}`;
};
