import { ApiConfig } from './ApiConfig';
import { Chat, UpdateChatDto } from '../models/Chat';

export class ChatApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  update(id: string, updateChatDto: UpdateChatDto): Promise<Chat> {
    return this.apiConfig.sendRequest('PATCH', `/chats/${id}`, updateChatDto);
  }
}
