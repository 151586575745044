import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../hooks/useStore';
import CustomModal from '../../CustomModal';
import Flex from '../../../utils/flex/Flex';
import { useMediaQuery } from 'react-responsive';
import { SIZES_NUMBER_SMALL } from '../../../../constants/sizes';
import { Headline3 } from '../../../typography/Headlines';
import { GRAY_COLORS } from '../../../../constants/colors';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ChatMessage from '../../../../pages/ConversationPage/components/ConversationChat/Components/ChatMessage';
import Button from '../../../buttons/Button';
import { getBackgroundColorForCompletionChat, getTextColorForChat } from './utils';

const MessageWasBlockedModal = () => {
  const {
    userStore: { userData },
    conversationStore: { multipleModelsRedactedChatMessage },
    uiStore: { isMessageWasBlockedModalOpen, toggleMessageWasBlockedModalOpen },
  } = useStore();

  const isModalFullScreenWidth = useMediaQuery({ maxWidth: SIZES_NUMBER_SMALL });
  const isModalFullScreenHeight = useMediaQuery({ maxHeight: 600 });

  return (
    <CustomModal
      isOpen={isMessageWasBlockedModalOpen}
      onClose={toggleMessageWasBlockedModalOpen}
      sx={{
        display: 'flex',
        overflow: 'scroll',
        borderRadius: '8px',
        padding: '24px',
        width: isModalFullScreenWidth ? '97vw' : '600px',
        height: isModalFullScreenHeight ? '90vh' : 'unset',
      }}
    >
      <Flex sx={{ flexDirection: 'column', flex: 1, height: '100%' }}>
        <Headline3>Request Blocked</Headline3>
        <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_500 }}>
          The following request was blocked because it contained content that violated your company's PII Address data
          policy. Try editing your prompt.
        </Typography>
        <Box
          sx={{
            border: `1px solid ${GRAY_COLORS.GRAY_2}`,
            borderRadius: '8px',
            padding: '0 16px 16px 16px',
            marginTop: '16px',
          }}
        >
          <ChatMessage
            chat={multipleModelsRedactedChatMessage}
            chatUser={userData}
            messageContentStyles={{ color: getTextColorForChat(multipleModelsRedactedChatMessage) }}
            messageContainerStyles={{
              borderRadius: '16px',
              backgroundColor: getBackgroundColorForCompletionChat(multipleModelsRedactedChatMessage),
            }}
            hideActions={true}
          />
        </Box>
        <Flex sx={{ justifyContent: 'flex-end', marginTop: '24px' }}>
          <Box>
            <Button onClick={() => toggleMessageWasBlockedModalOpen(false)}>Back to Chat</Button>
          </Box>
        </Flex>
      </Flex>
    </CustomModal>
  );
};

export default observer(MessageWasBlockedModal);
