import { ReactNode, useState } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';

import { AddStepperStepInterface, StepInterfaceProps } from '../../../models/Steps/StepInterfaces';
import AddFilesStep from './AddFilesStep';
import AddInputStep from './AddInputStep';
import FullHeightScreenWrapper from '../../FullHeightComponentWrapper/FullHeightScreenWrapper';

interface AddStepperStepProps extends StepInterfaceProps {
  step: AddStepperStepInterface;
}
const stepComponents: Record<string, any> = {
  addFileStep: AddFilesStep,
  addInputStep: AddInputStep,
};

const AddStepperStep = ({ step }: AddStepperStepProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const {
    stepConfiguration: { steps },
  } = step;

  const StepComponent = stepComponents[steps[currentStep].name as string];

  return (
    <FullHeightScreenWrapper sx={{ backgroundColor: 'transparent', height: 'calc(100vh - 56px)', gap: '1rem' }}>
      <Stepper activeStep={currentStep} sx={{ marginBottom: '2rem' }}>
        {steps?.map((label: any) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: ReactNode;
          } = {};
          return (
            <Step key={label.stepConfiguration.displayName} {...stepProps}>
              <StepLabel {...labelProps}>{label.stepConfiguration.displayName}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {StepComponent && (
        <StepComponent step={steps[currentStep]} currentStepIndex={currentStep} setCurrentStepIndex={setCurrentStep} />
      )}
    </FullHeightScreenWrapper>
  );
};

export default AddStepperStep;
