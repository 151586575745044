import React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react';

import { COLOR_BLACK, COLOR_TEXT_PRIMARY, COLOR_WHITE, GRAY_COLORS } from '../../../../../constants/colors';

import PdfWandIcon from '../../../../../assets/icons/pdf-wand.svg';

import { useStore } from '../../../../../hooks/useStore';
import { File } from '../../../../../models/File';
import Flex from '../../../../../components/utils/flex/Flex';
import { fileStatus } from '../../../../../constants/fileStatus';
import BlackTooltip from '../../../../../tooltips/BlackTooltip';
import Button from '../../../../../components/buttons/Button';
import { getIconByName, TypeIcon } from '../../../../../components/knowledgeBase/utils';

const FileMessageContentContainer = styled(Stack)`
  padding: 8px;
  background-color: ${COLOR_WHITE};
  border-radius: 8px;
`;

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" sx={{ width: '65px !important', height: '65px !important' }} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body2" component="div" sx={{ color: COLOR_TEXT_PRIMARY, fontWeight: 500 }}>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

interface ChatFileMessageContentProps {
  files: File[];
}

const ChatFileMessageContent = ({ files }: ChatFileMessageContentProps) => {
  const {
    conversationStore: {
      uploadProgress,
      uploadInProgress,
      uploadingFileId,
      setPreviewFile,
      responseToObjectionSolution,
    },
    localizationStore: { i18next: i18n },
    uiStore: { setIsCancelUploadModalOpen },
  } = useStore();

  const getFileFromResponseToObjectionSolution = (file: File) => {
    return responseToObjectionSolution?.files?.find((objectionFile: any) => objectionFile.name === file.name);
  };

  const TooltipContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
  });

  return (
    <Flex sx={{ gap: '8px', alignItems: 'center', flexDirection: 'column' }}>
      {files?.map((file: File, index: number) => {
        const fileInsideResponseToObjectionSolution: any = getFileFromResponseToObjectionSolution(file);

        return (
          <FileMessageContentContainer
            key={`file-message-${index}`}
            sx={
              uploadInProgress && uploadingFileId === file.id ? { minWidth: '300px', width: '100%' } : { width: '100%' }
            }
          >
            <Flex sx={{ gap: '8px', alignItems: 'center' }}>
              <TypeIcon src={getIconByName(file.name)} alt={file.name} style={{ marginRight: 0 }} />

              {fileInsideResponseToObjectionSolution ? (
                <BlackTooltip
                  title={
                    <TooltipContent>
                      <Typography
                        variant={'caption'}
                        color={'inherit'}
                      >{`Relevance:  ${fileInsideResponseToObjectionSolution.relevance}`}</Typography>
                      <Typography
                        variant={'caption'}
                        color={'inherit'}
                      >{`Excerpt:  ${fileInsideResponseToObjectionSolution.excerpt}`}</Typography>
                    </TooltipContent>
                  }
                >
                  <Typography variant={'body2'} sx={{ color: COLOR_BLACK, fontWeight: 500 }}>
                    {file.name}
                  </Typography>
                </BlackTooltip>
              ) : (
                <Typography variant={'body2'} sx={{ color: COLOR_BLACK, fontWeight: 500 }}>
                  {file.name}
                </Typography>
              )}

              {file.status === fileStatus.DONE && (
                <BlackTooltip placement={'bottom'} title={i18n.t('conversation.previewFile.title')} arrow>
                  <IconButton onClick={() => setPreviewFile(file)}>
                    <VisibilityOutlinedIcon sx={{ fill: GRAY_COLORS.GRAY_6, fontSize: '20px !important' }} />
                  </IconButton>
                </BlackTooltip>
              )}
            </Flex>
            {uploadInProgress && uploadingFileId === file.id && (
              <Stack sx={{ padding: '26px 42px', alignItems: 'center', justifyContent: 'center', gap: '16px' }}>
                <CircularProgressWithLabel value={uploadProgress} />
                <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_7 }}>
                  {i18n.t('conversation.file.uploading')}
                </Typography>
                <Button
                  variant={'outlined'}
                  sx={{
                    width: 'fit-content',
                    borderColor: `${GRAY_COLORS.GRAY_300} !important`,
                    color: `${COLOR_TEXT_PRIMARY} !important`,
                    padding: '4px 16px !important',
                  }}
                  onClick={() => setIsCancelUploadModalOpen(true)}
                >
                  {i18n.t('common.cancel.action')}
                </Button>
              </Stack>
            )}
            {file.status === fileStatus.PROCESSING && (
              <Stack sx={{ padding: '26px 42px', alignItems: 'center', justifyContent: 'center' }}>
                <img src={PdfWandIcon} alt={'pdf-wand-icon'} />
                <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_10, marginTop: '16px' }}>
                  {i18n.t('conversation.file.analyzing')}
                </Typography>
                <Typography variant={'body2'} sx={{ fontSize: '12px', color: GRAY_COLORS.GRAY_6, marginTop: '4px' }}>
                  {i18n.t('conversation.file.sitBackAndRelax')}
                </Typography>
              </Stack>
            )}
          </FileMessageContentContainer>
        );
      })}
    </Flex>
  );
};

export default observer(ChatFileMessageContent);
