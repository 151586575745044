import { ApiConfig } from './ApiConfig';
import { TopPrompt, TopPromptByTeam, PromptByConversation } from '../models/Prompt';
import { Summary } from '../models/Insights';

export class InsightsApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  async getPromptsByConversation(selectedPeriod: string): Promise<PromptByConversation[]> {
    return await this.apiConfig.sendRequest('GET', `/admin/top-by-conversation?period=${selectedPeriod}`);
  }

  async getTopPromptsByTeam(selectedPeriod: string): Promise<TopPromptByTeam[]> {
    return await this.apiConfig.sendRequest('GET', `/admin/top-by-team?period=${selectedPeriod}`);
  }

  async getTop10Prompts(selectedPeriod: string): Promise<TopPrompt[]> {
    return await this.apiConfig.sendRequest('GET', `/admin/top-prompts?period=${selectedPeriod}`);
  }

  async getSummary(selectedPeriod: string): Promise<Summary> {
    return await this.apiConfig.sendRequest('GET', `/admin/summary?period=${selectedPeriod}`);
  }
}
