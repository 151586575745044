import { Edit } from '@mui/icons-material';
import { Box, SxProps } from '@mui/material';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { COLOR_WHITE } from '../../constants/colors';
import { LocalStorageKeys } from '../../constants/localStorageKeys';
import { useStore } from '../../hooks/useStore';
import useUploadAndCropPicture from '../../hooks/useUploadAndCropPicture';

import { User } from '../../models/User';
import { UserFromGetModel } from '../../models/UserModel';
import { getAssetsKey, uploadDocumentSecurely } from '../../utils/documentsS3';
import CropImageModal from '../modal/CropImageModal';
import { LoadingSpinner } from '../spinner/LoadingSpinner';
import UserAvatar from '../UserAvatar/UserAvatar';

const UploadPictureContainer = styled(Box)`
  position: relative;

  &:hover {
    opacity: 0.7;
  }
`;

const MyInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const EditIconContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MyLabel = styled.label`
  cursor: pointer;
`;

interface UploadProfilePictureComponentProps {
  user?: User | UserFromGetModel;
  sx?: SxProps;
  size?: number;
}

const UploadProfilePictureComponent = ({ user, sx, size }: UploadProfilePictureComponentProps) => {
  const {
    appState: { s3DocumentsApi },
    userStore: {
      userData: { id },
      updateUserInformation,
    },
  } = useStore();
  const [isHovered, setIsHovered] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const { imageToCrop, isCropImageModalOpen, uploadLoading, previewImage, onUploadImage, onCropDone, closeCropModal } =
    useUploadAndCropPicture();

  const uploadFile = async (file: any) => {
    const tenant = localStorage.getItem(LocalStorageKeys.tenant) || 'avm';
    const fileKey = getAssetsKey(tenant, 'profilePictures', id);

    const writeUrl = await s3DocumentsApi.generateAssetsWriteUrl(fileKey);

    const status = await uploadDocumentSecurely(writeUrl, file);

    if (status === 200) {
      const readUrl = await s3DocumentsApi.generateAssetsReadUrl(fileKey);

      await updateUserInformation({
        profilePicture: readUrl.signedUrl,
        profilePictureFileKey: fileKey,
        profilePictureUrlExpiration: readUrl.expirationDate,
      });
    }
  };

  const handleSave = async (file: any) => {
    onCropDone(file);

    try {
      setLoading(true);

      await uploadFile(file);

      setIsHovered(false);
    } catch (e) {
      console.log('e ------------------->> ', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <UploadPictureContainer sx={{ ...(loading && { opacity: 0.7 }), ...sx }}>
      <MyLabel
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        htmlFor={'upload-photo'}
        style={{ cursor: loading || uploadLoading ? 'default' : 'pointer', position: 'relative' }}
      >
        <UserAvatar user={user} size={size} avatar={previewImage} />
        {isHovered && (
          <EditIconContainer
            sx={{ cursor: loading || uploadLoading ? 'default' : 'pointer' }}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();

              if (inputRef.current) {
                inputRef.current.click();
              }
            }}
          >
            {!loading && <Edit sx={{ fill: COLOR_WHITE }} />}
          </EditIconContainer>
        )}
        {(loading || uploadLoading) && (
          <EditIconContainer>
            <LoadingSpinner color={COLOR_WHITE} />
          </EditIconContainer>
        )}
      </MyLabel>
      <MyInput
        type="file"
        ref={inputRef}
        id={'upload-photo'}
        name={'upload-photo'}
        onChange={onUploadImage}
        onClick={(event: any) => {
          event.target.value = null;
        }}
        accept={'image/png, image/jpg, image/jpeg, image/heic'}
      />
      <CropImageModal
        isOpen={isCropImageModalOpen}
        onClose={closeCropModal}
        image={imageToCrop}
        onSave={handleSave}
        loading={uploadLoading}
      />
    </UploadPictureContainer>
  );
};

export default UploadProfilePictureComponent;
