import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import { SIZES_NUMBER_BIG } from '../constants/sizes';

import { getDimensions } from '../utils/getDimensions';

interface DimensionsProps {
  big: boolean;
  medium: boolean;
  smallMedium: boolean;
  small: boolean;
  tinySmall: boolean;
  tiny: boolean;
}

export class UiStore {
  isNavigationDrawerExpanded: boolean = true;
  isPromptsDrawerExpanded: boolean = false;
  toastPlacement: 'bottom-left' | 'bottom-center' | 'bottom-right' | 'top-left' | 'top-center' | 'top-right' =
    'bottom-right';
  dimensions: DimensionsProps;
  selectedIndexesTable: number[] = [];
  itemToUpdateIndex: number = -1;

  isOpenAiSettingsSectionExpanded: boolean = false;
  isOpenAiCompareAnswersModalOpen: boolean = false;
  isMessageWasBlockedModalOpen: boolean = false;
  isStartNewConversationFromPromptOpen: boolean = false;
  isFillPromptVariablesModalOpen: boolean = false;
  isPromptDetailsCardDisplayed: boolean = false;
  isCancelUploadModalOpen: boolean = false;

  constructor() {
    const screenWidth = window.screen.width;
    if (screenWidth < SIZES_NUMBER_BIG) this.isNavigationDrawerExpanded = false;
    this.dimensions = getDimensions();

    makeAutoObservable(this);
    autoBind(this);
  }

  setNewDimensions() {
    this.dimensions = getDimensions();
  }

  toggleIsPromptDetailsCardDisplayed(value?: boolean) {
    runInAction(() => {
      if (value !== undefined) {
        this.isPromptDetailsCardDisplayed = value;
        return;
      }

      this.isPromptDetailsCardDisplayed = !this.isPromptDetailsCardDisplayed;
    });
  }

  toggleIsFillPromptVariablesModalOpen(value?: boolean) {
    runInAction(() => {
      if (value !== undefined) {
        this.isFillPromptVariablesModalOpen = value;
        return;
      }

      this.isFillPromptVariablesModalOpen = !this.isFillPromptVariablesModalOpen;
    });
  }

  toggleIsStartNewConversationFromPromptOpen(value?: boolean) {
    runInAction(() => {
      if (value !== undefined) {
        this.isStartNewConversationFromPromptOpen = value;
        return;
      }

      this.isStartNewConversationFromPromptOpen = !this.isStartNewConversationFromPromptOpen;
    });
  }

  toggleOpenAiCompareAnswersModalOpen(value?: boolean) {
    runInAction(() => {
      if (value !== undefined) {
        this.isOpenAiCompareAnswersModalOpen = value;
        return;
      }

      this.isOpenAiCompareAnswersModalOpen = !this.isOpenAiCompareAnswersModalOpen;
    });
  }

  toggleMessageWasBlockedModalOpen(value?: boolean) {
    runInAction(() => {
      if (value !== undefined) {
        this.isMessageWasBlockedModalOpen = value;
        return;
      }

      this.isMessageWasBlockedModalOpen = !this.isMessageWasBlockedModalOpen;
    });
  }

  toggleIsNavigationDrawerExpanded(value?: boolean) {
    runInAction(() => {
      if (value !== undefined) {
        this.isNavigationDrawerExpanded = value;
        return;
      }

      this.isNavigationDrawerExpanded = !this.isNavigationDrawerExpanded;
    });
  }

  toggleIsPromptsDrawerExpanded(value?: boolean) {
    runInAction(() => {
      if (value !== undefined) {
        this.isPromptsDrawerExpanded = value;
        return;
      }

      this.isPromptsDrawerExpanded = !this.isPromptsDrawerExpanded;
    });
  }

  toggleOpenAiSettingsSection(value?: boolean) {
    runInAction(() => {
      if (value !== undefined) {
        this.isOpenAiSettingsSectionExpanded = value;
        return;
      }

      this.isOpenAiSettingsSectionExpanded = !this.isOpenAiSettingsSectionExpanded;
    });
  }

  setIsCancelUploadModalOpen(value: boolean) {
    runInAction(() => {
      this.isCancelUploadModalOpen = value;
    });
  }
}
