import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';
import { Stack, SxProps } from '@mui/material';

import { COLOR_BORDER_PRIMARY, COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';

interface ViewModeSimpleRowProps {
  title: string;
  text?: string | number;
  sx?: SxProps;
  titleStyle?: SxProps;
  secondColumnStyle?: SxProps;
  noBorder?: boolean;
  picture?: string;
  textNode?: ReactNode;
}

const ViewModeSimpleRow = ({
  title,
  text,
  noBorder,
  sx,
  titleStyle,
  picture,
  textNode,
  secondColumnStyle,
}: ViewModeSimpleRowProps) => {
  return (
    <Stack
      flexDirection={'row'}
      alignItems={'start'}
      justifyContent={'space-between'}
      sx={{ padding: '16px 0', borderBottom: noBorder ? 'none' : `1px solid ${COLOR_BORDER_PRIMARY}`, ...sx }}
    >
      <Typography variant={'subtitle2'} color={GRAY_COLORS.GRAY_500} width={'120px'} sx={titleStyle} fontWeight={500}>
        {title}
      </Typography>
      <Typography variant={'subtitle2'} sx={secondColumnStyle} fontWeight={400}>
        {text || (!textNode && !picture && '-')}
      </Typography>
      {textNode}
      {picture && (
        <Stack bgcolor={COLOR_WHITE} width={'100%'} height={150} justifyContent={'left'} alignItems={'center'} p={3}>
          <img src={picture} alt={'asset_Image'} style={{ maxHeight: '100%', maxWidth: '100%' }} />
        </Stack>
      )}
    </Stack>
  );
};

export default observer(ViewModeSimpleRow);
