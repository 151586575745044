import React from 'react';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material';

interface TabPanelProps {
  value: number;
  index: number;
  children: React.ReactNode;
  sx?: SxProps;
}

const TabPanel = ({ value, index, children, sx }: TabPanelProps) => {
  return (
    <Box
      // eslint-disable-next-line
      role="instruments-tab-panel"
      hidden={value !== index}
      id={`instruments-tab-panel-${index}`}
      aria-labelledby={`instruments-tab-${index}`}
      sx={sx}
    >
      {children}
    </Box>
  );
};

export default TabPanel;
