import { ApiConfig } from './ApiConfig';

export class OrganizationApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  async getSignInProvider(data: { email: string }) {
    return await this.apiConfig.sendRequest('POST', '/organizations/sign-in-provider', data);
  }

  async getProviderByDomain(data: { domain: string }) {
    return await this.apiConfig.sendRequest('POST', '/organizations/provider-by-domain', data);
  }
}
