import React from 'react';
import { useNavigate } from 'react-router';

import NumberOfChatsIcon from '../../assets/icons/numberOfChats.svg';
import { Paths } from '../../constants/routes';

import GenericCard from './GenericCard';
import Flex from '../utils/flex/Flex';
import ContentTypeTag from '../contentTypeTag/ContentTypeTag';
import { getTimeAgo } from '../../utils/getTimeAgo';
import { Typography } from '@mui/material';
import Subtitle from '../typography/Subtitle';
import { getTextOverflowEllipsisStyles } from '../typography/utils';
import { Conversation } from '../../models/Conversation';

interface RecentHistoryCardProps {
  conversation: Conversation;
}

const HistoryCard = ({ conversation }: RecentHistoryCardProps) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`${Paths.CONVERSATION}/${conversation.id}`);
  };

  return (
    <GenericCard height={'140px'}>
      <Flex sx={{ flexDirection: 'column' }} onClick={handleOnClick}>
        <Flex sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <ContentTypeTag contentType={conversation.type} />
          <Subtitle sx={{ fontSize: '12px', lineHeight: '22px', fontWeight: '400' }}>
            {getTimeAgo(conversation.createdAt)}
          </Subtitle>
        </Flex>
        <Typography
          sx={{
            ...getTextOverflowEllipsisStyles(2),
            marginTop: '10px',
            fontWeight: '400',
            fontSize: '14px',
          }}
        >
          {conversation.content}
        </Typography>
        <Flex sx={{ marginTop: '10px' }}>
          <img src={NumberOfChatsIcon} alt={'numberOfChatsIcon'} />
          <Subtitle sx={{ marginLeft: '6px' }}>{conversation.numberOfChats}</Subtitle>
        </Flex>
      </Flex>
    </GenericCard>
  );
};

export default HistoryCard;
