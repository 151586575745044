import { Model } from '../models/Model';
import { SupportedAIProvidersEnum } from '../constants/supportedAIProvidersEnum';

const BedrockModelVersions = [
  'anthropic.claude-v2',
  'anthropic.claude-v1',
  'anthropic.claude-3-sonnet-20240229-v1:0',
  'anthropic.claude-3-haiku-20240307-v1:0',
  'stability.stable-diffusion-xl-v0',
  'stability.stable-diffusion-xl-v1',
  'meta.llama2-70b-chat-v1',
  'meta.llama3-70b-instruct-v1:0',
  'us.meta.llama3-2-90b-instruct-v1:0',
  'amazon.titan-text-express-v1',
  'amazon.titan-tg1-large',
  'ai21.j2-ultra',
  'ai21.j2-mid',
];

const isBedrockModel = (model: Partial<Model>) => {
  if (model.providerName === SupportedAIProvidersEnum.bedrock) {
    return true;
  }

  return !!(model?.modelVersion && BedrockModelVersions.some(type => model.modelVersion?.includes(type)));
};

export default isBedrockModel;
