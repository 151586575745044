import WorkflowStepFileInput from '../Inputs/WorkflowStepFileInput';
import DynamicTextInputList from '../Inputs/DynamicTextInputList';
import WorkflowStepTextInputField from '../Inputs/WorkflowStepTextInput';
import WorkflowStepSelectInput from '../Inputs/WorkflowStepSelectInput';
import WorkflowStepNumberInput from '../Inputs/WorkflowStepNumberInput';
import DynamicFormInputsList from '../Inputs/DynamicFormInputsList';

export const renderField = (field: any) => {
  switch (field.type) {
    case 'fileInput':
      return <WorkflowStepFileInput key={field.name} field={field} />;
    case 'dynamicTextInputList':
      return <DynamicTextInputList key={field.name} field={field} />;
    case 'textInput':
      return <WorkflowStepTextInputField key={field.name} field={field} />;
    case 'selectInput':
      return <WorkflowStepSelectInput key={field.name} field={field} />;
    case 'numberInput':
      return <WorkflowStepNumberInput key={field.name} field={field} />;
    case 'dynamicFormInputs':
      return (
        <DynamicFormInputsList
          key={field.name}
          name={field.name}
          field={field.fields}
          arrName={field.arrName}
          addButton={field.addButton}
        />
      );
    default:
      return null;
  }
};
