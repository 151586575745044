import React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { observer } from 'mobx-react';
import { useToasts } from 'react-toast-notifications';

import { GRAY_COLORS } from '../../../../constants/colors';

import { PromptsGalleryContentContainer } from '../../styles';
import { useStore } from '../../../../hooks/useStore';
import Flex from '../../../../components/utils/flex/Flex';
import TeamTag from '../../../../components/teamTag/TeamTag';
import UserAvatar from '../../../../components/UserAvatar/UserAvatar';
import Button from '../../../../components/buttons/Button';
import PromptContentContainer from '../PromptContentContainer';
import { OptionsButton, OptionsRowButton } from '../../../../components/buttons/OptionsButton';

interface PromptsDetailsCardProps {
  setIsAddPromptModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeletePromptModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PromptsDetailsCard = ({
  setIsEditMode,
  setIsAddPromptModalOpen,
  setIsDeletePromptModalOpen,
}: PromptsDetailsCardProps) => {
  const {
    promptsStore: { currentPrompt, setEmptyPrompt },
    localizationStore: { i18next: i18n },
    uiStore: { toggleIsPromptDetailsCardDisplayed, toggleIsStartNewConversationFromPromptOpen },
  } = useStore();

  const { createdAt, teams, title, user } = currentPrompt;

  const { addToast } = useToasts();

  const options: OptionsRowButton[] = [
    {
      optionName: 'Edit',
      optionLogo: <EditOutlinedIcon />,
      optionOnClick: (item: any) => {
        setIsEditMode(true);
        setIsAddPromptModalOpen(true);
      },
    },
    {
      optionName: 'Delete',
      optionLogo: <DeleteOutlinedIcon />,
      optionOnClick: (item: any) => {
        setIsDeletePromptModalOpen(true);
      },
    },
  ];

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(currentPrompt.content);
      addToast(i18n.t('conversation.copyMessage.successful'), { appearance: 'success' });
    } catch (e) {
      addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
    }
  };

  return (
    <PromptsGalleryContentContainer sx={{ width: '40%', position: 'relative', height: '100%' }}>
      <IconButton
        onClick={() => {
          toggleIsPromptDetailsCardDisplayed(false);
          setEmptyPrompt();
        }}
        sx={{ position: 'absolute', top: '24px', right: '24px' }}
      >
        <CloseIcon sx={{ color: GRAY_COLORS.GRAY_6 }} />
      </IconButton>

      <Flex sx={{ width: '100%' }}>
        {teams?.length ? (
          teams.map(team => <TeamTag team={team.name} sx={{ marginRight: '6px' }} />)
        ) : (
          <TeamTag team={'Private'} />
        )}
      </Flex>
      <Flex>
        <Typography variant={'h5'} sx={{ marginTop: '24px' }}>
          {title}
        </Typography>
      </Flex>

      <Flex sx={{ alignItems: 'center', marginTop: '8px' }}>
        <UserAvatar user={user} />
        <Typography variant={'subtitle2'} sx={{ color: GRAY_COLORS.GRAY_7, marginLeft: '8px' }}>
          {user?.fullName + ' • ' + new Date(createdAt).toDateString()}
        </Typography>
      </Flex>

      <Flex sx={{ marginTop: '16px', marginBottom: '48px', height: 'fit-content' }}>
        <Button
          variant={'outlined'}
          startIcon={<ForumOutlinedIcon />}
          sx={{
            flex: 5,
            height: '40px',
            marginRight: '8px',
            borderColor: `${GRAY_COLORS.GRAY_2} !important`,
            color: `${GRAY_COLORS.GRAY_9} !important`,
          }}
          onClick={() => toggleIsStartNewConversationFromPromptOpen(true)}
        >
          Start new chat
        </Button>

        <Button
          variant={'outlined'}
          startIcon={<ContentCopyIcon />}
          sx={{
            flex: 2,
            height: '40px',
            marginRight: '8px',
            width: 'fit-content',
            borderColor: `${GRAY_COLORS.GRAY_2} !important`,
            color: `${GRAY_COLORS.GRAY_9} !important`,
          }}
          onClick={handleCopyToClipboard}
        >
          Copy
        </Button>

        <OptionsButton item={currentPrompt} options={options} />
      </Flex>

      <Typography variant={'subtitle2'} sx={{ color: GRAY_COLORS.GRAY_7, marginBottom: '16px' }}>
        PROMPT DESCRIPTION
      </Typography>

      <PromptContentContainer prompt={currentPrompt} />
    </PromptsGalleryContentContainer>
  );
};

export default observer(PromptsDetailsCard);
