import React from 'react';
import { Menu, MenuItem, Typography, SxProps } from '@mui/material';

import { GRAY_COLORS } from '../../constants/colors';

interface MenuProps {
  anchorEl: null | any;
  open: boolean;
  handleClose: () => void;
  options: Array<{ name: string; icon: React.ReactNode; onClick: () => void; itemSx?: SxProps }>;
}

const FileMenu = ({ anchorEl, open, handleClose, options }: MenuProps) => {
  return (
    <Menu id="file-menu" anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ ul: { padding: '4px' } }}>
      {options?.map((item, index) => (
        <MenuItem
          key={`file-menu-item-${index}`}
          onClick={e => {
            e.stopPropagation();
            item.onClick();
          }}
          sx={{
            color: GRAY_COLORS.GRAY_10,
            display: 'flex',
            flexAlign: 'center',
            gap: '8px',
            height: '48px',
            padding: '8px',
            ...item.itemSx,
          }}
        >
          {item.icon}
          <Typography variant="body2">{item.name}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default FileMenu;
