import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormHelperText, SxProps } from '@mui/material';

import { DropdownOption } from '../../models/DropdownOption';
import { COLOR_BUTTON_CONTAINED_ERROR, COLOR_TEXT_PRIMARY, GRAY_COLORS } from '../../constants/colors';

interface SelectProps {
  label?: string;
  value: any;
  onChange: (event: SelectChangeEvent) => void;
  options: DropdownOption[];
  sx?: SxProps;
  multiple?: boolean;
  placeholder?: string;
  error?: string;
  renderValue?: any;
}

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  className: 'disable-click-away',
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const SelectWithCheckmarks = ({ value, placeholder, onChange, label, options, multiple, error, sx }: SelectProps) => {
  const labelId = `${label}-label`;

  // @ts-ignore
  return (
    <FormControl sx={sx}>
      {label && (
        <InputLabel id={labelId} shrink={true} sx={{ backgroundColor: 'transparent', '&': { top: '0px' } }}>
          {label}
        </InputLabel>
      )}

      <Select
        SelectDisplayProps={{ className: 'disable-click-away' }}
        notched={true}
        label={label}
        labelId={labelId}
        onChange={onChange}
        multiple={multiple}
        displayEmpty={true}
        value={value}
        renderValue={() => {
          if (multiple && Array.isArray(value)) {
            if (value.length === 0) {
              return placeholder || '';
            }

            return value.join(', ');
          }

          if (!value) {
            return placeholder || '';
          }

          return value;
        }}
        MenuProps={MenuProps}
        sx={{
          borderRadius: '8px',
          borderColor: GRAY_COLORS.GRAY_300,
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '22px',
          color: value ? GRAY_COLORS.GRAY_500 : COLOR_TEXT_PRIMARY,
        }}
      >
        {options.map(option => {
          return (
            <MenuItem value={option.value} sx={{ height: '40px', paddingLeft: '8px' }} className={'disable-click-away'}>
              <Checkbox checked={(value.indexOf(option.name) || 0) > -1} />
              <ListItemText primary={option.name} />
            </MenuItem>
          );
        })}
      </Select>
      {error && <FormHelperText sx={{ marginLeft: 0, color: COLOR_BUTTON_CONTAINED_ERROR }}>{error}</FormHelperText>}
    </FormControl>
  );
};

export default SelectWithCheckmarks;
