import { User } from './User';
import { Model } from './Model';

export interface Organization {
  id: string;
  name: string;
  website: string;
  industry: string;
  logo: string;
  subscriptionPlan: string;
  paymentPeriod: string;
  openApiKey: string;
  createdAt: Date;
  updatedAt: Date;
  users: User[];
  models: Model[];
}

export const EMPTY_ORGANIZATION: Organization = {
  createdAt: new Date(),
  id: '',
  industry: '',
  logo: '',
  models: [],
  name: '',
  openApiKey: '',
  paymentPeriod: '',
  subscriptionPlan: '',
  updatedAt: new Date(),
  users: [],
  website: '',
};
