import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { useStore } from '../../hooks/useStore';
import ConversationFiles from '../../pages/ConversationPage/ConversationFiles';
import QuestionAndAnswer from './QuestionAndAnswer';
import { Paths } from '../../constants/routes';
import { Headline2 } from '../typography/Headlines';

const ResultPage = () => {
  const {
    conversationStore: {
      showFilePreview,
      fortressSolution,
      fortressFiles,
      setFortressSolutionFiles,
      setFortressSolutionId,
      setFortressSolutionValue,
      setIsBackBtnUsed,
    },
  } = useStore();

  const navigate = useNavigate();

  const ScreenContainer = styled(Box)`
    width: 100%;
    height: calc(90vh - 56px - 120px);
    display: flex;
    gap: 16px;
    padding: 24px;
  `;

  return (
    <>
      <Button
        onClick={() => {
          setFortressSolutionFiles([]);
          setFortressSolutionId('');
          setFortressSolutionValue({});
          navigate(Paths.CHAT);
          setIsBackBtnUsed(true);
        }}
      >
        <ArrowBackIosNewIcon sx={{ fontSize: '14px', fontWeight: 700 }} />
        <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: 700 }}>
          BACK
        </Typography>
      </Button>

      <Headline2 style={{ marginLeft: '24px' }}>F1.0 RAG - AGENT</Headline2>

      <ScreenContainer>
        <Box sx={{ width: showFilePreview ? '60%' : '100%' }}>
          {Object.keys(fortressSolution).map((key: string, index) => {
            const solution = fortressSolution[key];

            return (
              <QuestionAndAnswer
                questionId={key}
                question={solution.question}
                answer={solution.final_answer}
                meta={solution.meta}
              />
            );
          })}
        </Box>
        <Box
          sx={{
            maxWidth: 'calc(100vw * 0.4)',
            width: showFilePreview ? '40%' : 0,
            transition: theme =>
              theme.transitions.create(['width'], {
                easing: showFilePreview ? theme.transitions.easing.sharp : theme.transitions.easing.easeOut,
                duration: showFilePreview
                  ? theme.transitions.duration.leavingScreen
                  : theme.transitions.duration.enteringScreen,
              }),
          }}
        >
          <ConversationFiles docFiles={fortressFiles} />
        </Box>
      </ScreenContainer>
    </>
  );
};

export default observer(ResultPage);
