import { TimePeriods } from '../constants/timePeriods';

export const groupObjectsByCreationDate = (objects: any) => {
  if (!objects || !Array.isArray(objects)) {
    return [];
  }

  const today = new Date();
  const groupedObjects: any = {
    today: [],
    last7Days: [],
    last30Days: [],
  };

  try {
    objects.forEach((obj: any) => {
      const createdAt = new Date(obj.createdAt);
      const createdDate = new Date(createdAt.getFullYear(), createdAt.getMonth(), createdAt.getDate());

      if (createdDate.toDateString() === today.toDateString()) {
        groupedObjects.today.push(obj);
        return;
      }

      if (createdDate >= new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7) && createdDate <= today) {
        groupedObjects[TimePeriods.last7Days].push(obj);
        return;
      }

      if (
        createdDate >= new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30) &&
        createdDate <= today
      ) {
        groupedObjects[TimePeriods.last30Days].push(obj);
        return;
      }

      const dateString =
        createdAt.getFullYear() === today.getFullYear()
          ? createdAt.toLocaleString('default', { month: 'long' })
          : `${createdAt.toLocaleString('default', { month: 'long' })} ${createdAt.getFullYear()}`;

      if (!groupedObjects[dateString]) {
        groupedObjects[dateString] = [];
      }

      groupedObjects[dateString].push(obj);
    });
  } catch (e) {
    console.log('e ------------------->> ', e);
  }

  return groupedObjects;
};
