import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { Box, Typography, Link, Stack, Button, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface Source {
  id: number;
  title?: string;
  url?: string;
}

interface SourcesComponentProps {
  sources: Source[];
  collapsed: boolean;
  toggleCollapse: () => void;
}

const AmazonQSourcesComponent = forwardRef(({ sources, collapsed, toggleCollapse }: SourcesComponentProps, ref) => {
  const sourceRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

  // Expose scrollToSource method
  useImperativeHandle(ref, () => ({
    scrollToSource: (id: number) => {
      const sourceElement = sourceRefs.current[id];

      if (sourceElement) {
        sourceElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
  }));

  return (
    <Box sx={{ marginTop: '16px', padding: '16px' }}>
      <Button
        variant="outlined"
        onClick={toggleCollapse}
        endIcon={!collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        sx={{ textTransform: 'none' }}
      >
        Sources
      </Button>

      <Collapse in={collapsed} timeout="auto" unmountOnExit>
        <Stack spacing={2} sx={{ marginTop: '16px' }}>
          {sources.map(source => (
            <Box key={source.id} ref={(el: any) => (sourceRefs.current[source.id] = el)} sx={{ paddingBottom: '8px' }}>
              <Box display="flex" alignItems="center">
                <Typography variant="body2" sx={{ marginRight: '8px' }}>
                  {source.id}.
                </Typography>
                {source.title ? (
                  source.url ? (
                    <Link href={source.url} target="_blank" rel="noopener noreferrer">
                      {source.title}
                    </Link>
                  ) : (
                    <Typography variant="body2">{source.title}</Typography>
                  )
                ) : (
                  <Typography variant="body2">No Title Available</Typography>
                )}
              </Box>
              {source.url && (
                <Typography variant="caption" color="textSecondary" style={{ wordBreak: 'break-all' }}>
                  {'🌐  ' + source.url}
                </Typography>
              )}
            </Box>
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
});

export default AmazonQSourcesComponent;
