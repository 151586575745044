import { configure } from 'mobx';

import { AppState } from './AppState';
import { AuthStore } from './AuthStore';
import { UserStore } from './UserStore';
import { LocalizationStore } from './LocalizationStore';
import { UiStore } from './UiStore';
import { PromptsStore } from './PromptsStore';
import { ConversationStore } from './ConversationStore';
import { AuditLogsStore } from './AuditLogsStore';
import { TeamStore } from './TeamStore';
import { FilterStore } from './FilterStore';
import { InsightsStore } from './InsightsStore';
import { SocketStore } from './SocketStore';
import { ComplianceStore } from './ComplianceStore';
import { FileStore } from './FileStore';
import { ModelsStore } from './ModelsStore';
import { IntegrationsStore } from './IntegrationsStore';
import { KnowledgeBaseStore } from './KnowledgeBaseStore';
import { S3FileSystemStore } from './S3FileSystemStore';
import { WorkflowStore } from './WorkflowStore';
import { CreateWorkflowRunStore } from './CreateWorkflowRunStore';
import { WorkSpaceStore } from './WorkSpaceStore';

configure({ enforceActions: 'observed' });

export default class RootStore {
  appState: AppState;
  authStore: AuthStore;
  userStore: UserStore;
  localizationStore: LocalizationStore;
  uiStore: UiStore;
  promptsStore: PromptsStore;
  conversationStore: ConversationStore;
  auditLogsStore: AuditLogsStore;
  teamStore: TeamStore;
  filterStore: FilterStore;
  fileStore: FileStore;
  insightsStore: InsightsStore;
  complianceStore: ComplianceStore;
  socketStore: SocketStore;
  modelStore: ModelsStore;
  integrationsStore: IntegrationsStore;
  knowledgeBaseStore: KnowledgeBaseStore;
  s3FileSystemStore: S3FileSystemStore;
  workFlowStore: WorkflowStore;
  createWorkflowRunStore: CreateWorkflowRunStore;
  WorkSpaceStore: WorkSpaceStore;

  constructor() {
    this.appState = new AppState();
    this.socketStore = new SocketStore();
    this.authStore = new AuthStore(
      this.appState.userApi,
      this.appState.organizationApi,
      this.appState.auditLogsApi,
      this.socketStore
    );
    this.localizationStore = new LocalizationStore();
    this.userStore = new UserStore(this.appState.userApi);
    this.uiStore = new UiStore();
    this.promptsStore = new PromptsStore(this.appState.promptsApi);
    this.modelStore = new ModelsStore(this.appState.modelApi);
    this.conversationStore = new ConversationStore(
      this,
      this.appState.conversationApi,
      this.appState.chatApi,
      this.appState.userApi,
      this.appState.fileApi
    );
    this.auditLogsStore = new AuditLogsStore(
      this.appState.auditLogsApi,
      this.appState.userApi,
      this.appState.conversationApi
    );
    this.teamStore = new TeamStore(this.appState.teamApi);
    this.filterStore = new FilterStore();
    this.fileStore = new FileStore(this.appState.fileApi);
    this.insightsStore = new InsightsStore(this.appState.insightsApi);
    this.complianceStore = new ComplianceStore(this.appState.complianceApi);
    this.modelStore = new ModelsStore(this.appState.modelApi);
    this.integrationsStore = new IntegrationsStore(this.appState.integrationsApi);
    this.knowledgeBaseStore = new KnowledgeBaseStore(this.appState.knowledgeBaseApi, this.appState.fileApi);
    this.s3FileSystemStore = new S3FileSystemStore(this.appState.s3DocumentsApi);
    this.workFlowStore = new WorkflowStore(this.appState.workflowsApi);
    this.createWorkflowRunStore = new CreateWorkflowRunStore(this.workFlowStore);
    this.WorkSpaceStore = new WorkSpaceStore(this.appState.WorkSpaceApi);
  }
}
