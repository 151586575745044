import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { ExpandLess, ExpandMore, Settings } from '@mui/icons-material';
import { observer } from 'mobx-react';
import Stack from '@mui/material/Stack';

import CubeIcon from '../../../assets/icons/openAiDetails/cube-white.svg';
import CubeGrayIcon from '../../../assets/icons/openAiDetails/cube-gray.svg';
import AccuracyIcon from '../../../assets/icons/openAiDetails/accuracy-white.svg';
import AccuracyGrayIcon from '../../../assets/icons/openAiDetails/accuracy-gray.svg';
import LengthIcon from '../../../assets/icons/openAiDetails/length-white.svg';
import LengthGrayIcon from '../../../assets/icons/openAiDetails/length-gray.svg';
import DataAnalysisIcon from '../../../assets/icons/openAiDetails/data-analysis-white.svg';
import DataAnalysisGreyIcon from '../../../assets/icons/openAiDetails/data-analysis-grey.svg';
import { COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';

import {
  Divider,
  SettingsItem,
  ToggleSettingsSection,
} from '../../../components/cards/newConversationCard/styledComponents';
import { useStore } from '../../../hooks/useStore';
import Flex from '../../../components/utils/flex/Flex';
import TextButton from '../../../components/buttons/TextButton';
import { SupportedExtensions } from '../../../models/Conversation';
import ModelTypeSelection from './ModelTypeSelection';
import { Models } from '../../../constants/models';

interface OpenAIDetailsSectionProps {
  model: string;
  accuracy: number;
  length: number;
  extension?: SupportedExtensions;
  renderButton?: boolean;
  lightBackground?: boolean;
  integration?: string;
  providerName?: string;
  isSecondary?: boolean;
}

const OpenAIDetailsSection = ({
  model,
  accuracy,
  length,
  extension,
  renderButton,
  lightBackground,
  integration,
  providerName,
  isSecondary,
}: OpenAIDetailsSectionProps) => {
  const {
    localizationStore: { i18next: i18n },
    uiStore: { isOpenAiSettingsSectionExpanded, toggleOpenAiSettingsSection },
    conversationStore: {
      setPrimaryConversationModelSettings,
      currentConversation: { chats },
    },
  } = useStore();

  const hasUserStartedAskingQuestions =
    chats?.filter(chat => {
      const isFileChat = Array.isArray(chat?.files) && chat?.files?.length > 0;

      if (isFileChat) {
        return true;
      }

      return chat.message !== i18n.t('conversation.dataAnalysis.notEnabled');
    }).length > 0;

  // Running this use effect to set recommended model as primary conversation model for prompt gallery
  useEffect(() => {
    if (model && providerName) {
      setPrimaryConversationModelSettings({ model, accuracy, length, providerName, modelDisplayName: model });
    }
  }, []);

  return (
    <Stack>
      <Flex sx={{ alignItems: 'center' }}>
        <Flex sx={{ gap: '1px' }}>
          <SettingsItem
            style={{
              borderRadius: '4px',
              color: lightBackground ? GRAY_COLORS.GRAY_500 : COLOR_WHITE,
              background: hasUserStartedAskingQuestions ? GRAY_COLORS.GRAY_500 : 'transparent',
            }}
          >
            {extension === SupportedExtensions.DataAnalysis ? (
              <>
                <img src={lightBackground ? DataAnalysisGreyIcon : DataAnalysisIcon} alt={'data-analysis-icon'} />
                <Typography
                  variant={'body2'}
                  sx={{
                    fontSize: '12px',
                    color: lightBackground ? GRAY_COLORS.GRAY_500 : COLOR_WHITE,
                  }}
                >
                  data-analysis
                </Typography>
              </>
            ) : hasUserStartedAskingQuestions ? (
              <>
                <img src={lightBackground ? CubeGrayIcon : CubeIcon} alt={'cube-icon'} />
                <Typography
                  variant={'body2'}
                  sx={{
                    fontSize: '12px',
                    color: lightBackground ? GRAY_COLORS.GRAY_500 : COLOR_WHITE,
                  }}
                >
                  {model}
                  {model === Models.SQL_MODEL.displayName ? ` - ${integration}` : ''}
                </Typography>
              </>
            ) : (
              <ModelTypeSelection selectedItem={model} isSecondary={isSecondary} />
            )}
          </SettingsItem>
          {lightBackground && <Divider />}
          <SettingsItem
            style={{
              borderRadius: 4,
              color: lightBackground ? GRAY_COLORS.GRAY_500 : COLOR_WHITE,
              background: lightBackground ? 'transparent' : GRAY_COLORS.GRAY_500,
            }}
          >
            <img src={lightBackground ? AccuracyGrayIcon : AccuracyIcon} alt={'accuracy-icon'} />
            <Typography
              variant={'body2'}
              sx={{ fontSize: '12px', color: lightBackground ? GRAY_COLORS.GRAY_500 : COLOR_WHITE }}
            >
              {accuracy}
            </Typography>
            <img src={lightBackground ? LengthGrayIcon : LengthIcon} alt={'length-icon'} />
            <Typography
              variant={'body2'}
              sx={{ fontSize: '12px', color: lightBackground ? GRAY_COLORS.GRAY_500 : COLOR_WHITE }}
            >
              {length}
            </Typography>
          </SettingsItem>
        </Flex>
        {renderButton && (
          <ToggleSettingsSection>
            <TextButton
              onClick={() => toggleOpenAiSettingsSection()}
              startIcon={<Settings sx={{ fill: GRAY_COLORS.GRAY_500 }} />}
              endIcon={
                isOpenAiSettingsSectionExpanded ? (
                  <ExpandLess sx={{ fill: GRAY_COLORS.GRAY_500 }} fontSize={'small'} />
                ) : (
                  <ExpandMore sx={{ fill: GRAY_COLORS.GRAY_500 }} fontSize={'small'} />
                )
              }
            >
              <Typography variant={'body2'} sx={{ fontSize: '12px', color: GRAY_COLORS.GRAY_500 }}>
                {i18n.t('common.settings')}
              </Typography>
            </TextButton>
          </ToggleSettingsSection>
        )}
      </Flex>
    </Stack>
  );
};

export default observer(OpenAIDetailsSection);
